import { useEffect, useRef, useState } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { selectStatementData } from '../../services/functions/server_functions/functions';
import './RegisterAdmin.css';

const RegisterAdmin = () => {
  const [oj, setOj] = useState([]);
  const [pravo, setPravo] = useState([]);
  const emailRef = useRef();
  const prezimeIImeRef = useRef();
  const password1Ref = useRef();
  const password2Ref = useRef();
  const ojRef = useRef();
  const pravoRef = useRef();
  // const oj = useSelector((state) => state.oj);
  // const pravo = useSelector((state) => state.pravo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const ojData = await selectStatementData(`SELECT oj.id, oj.naziv FROM organizaciona_jedinica oj`);
    setOj(ojData.data);
    const pravoData = await selectStatementData(`SELECT p.id, p.naziv FROM pravo p`);
    setPravo(pravoData.data);
  }


  const handleUserRegister = async (e) => {
    e.preventDefault();

    if (!emailRef.current.value || !prezimeIImeRef.current.value || !password1Ref.current.value || !password2Ref.current.value) {
      dispatch(setMessage({ msgText: 'Nisu popunjena sva obavezna polja.', icon: 'warning', showHide: true })); return;
    }
    if (emailRef.current.value.slice(-18) !== '@uljaricebacka.com') {

      dispatch(setMessage({ msgText: 'Email mora zavrsiti sa @uljaricebacka.com', icon: 'warning', showHide: true })); return;
    }
    if (password1Ref.current.value != password2Ref.current.value) {
      dispatch(setMessage({ msgText: 'Password i Ponovi password nisu isti.', icon: 'warning', showHide: true })); return;
    }

    try {
      const res = await fetch('https://rnalogapi.cs-dev.rs/addUser',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({ email: emailRef.current.value, password: password1Ref.current.value, prezimeIIme: prezimeIImeRef.current.value, oj: ojRef.current.value, pravo: pravoRef.current.value })
        });
      // const data = await res.json();
      dispatch(setMessage({ msgText: 'Novi korisnik je kreiran.', icon: 'success', showHide: true }));
      navigate('/main/landingpage');
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className='register-page-admin'>
      <SlClose className='register-page-admin-close-button' onClick={() => navigate('/main/landingpage')} />
      <div className="register-page-top-admin">
        <p>
          <span>Kreiranje novog korisnika</span>
        </p>
      </div>
      <div className="register-page-bottom-admin">
        <div className="input-group">
          <div className='input-field'>
            <label htmlFor='email'><span className='asterisk'>*</span> Email</label>
            <input
              type="email"
              name='email'
              placeholder='email@uljaricebacka.com'
              autoComplete='off'
              ref={emailRef}
            />
          </div>
          <div className='input-field'>
            <label htmlFor='name'><span className='asterisk'>*</span> Prezime i ime</label>
            <input
              type="text"
              name='name'
              autoComplete='off'
              ref={prezimeIImeRef}
            />
          </div>
        </div>
        <div className="input-group">
          <div className='input-field'>
            <label htmlFor='password'><span className='asterisk'>*</span> Password</label>
            <input
              type="text"
              name='password'
              autoComplete='off'
              ref={password1Ref}
            />
          </div>
          <div className='input-field'>
            <label htmlFor='repeat-password'><span className='asterisk'>*</span> Ponovi password</label>
            <input
              type="text"
              name='repeat-password'
              autoComplete='off'
              ref={password2Ref}
            />
          </div>
        </div>
        <div className="input-group">
          <div className='input-field'>
            <label htmlFor='organizaciona-jedinica'><span className='asterisk'>*</span> Organizaciona jedinica</label>
            <select ref={ojRef}>
              {oj.map((o) => {
                return (
                  <option key={o.id} value={o.id}>{o.naziv}</option>
                )
              })}
            </select>
          </div>
          <div className='input-field'>
            <label htmlFor='repeat-password'><span className='asterisk'>*</span> Pravo</label>
            <select ref={pravoRef}>
              {pravo.map((p) => {
                return (
                  <option key={p.id} value={p.id}>{p.naziv}</option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="register-group">
        <button onClick={handleUserRegister}>Primeni</button>
      </div>
    </div>
  )
}

export default RegisterAdmin;