import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SlClose } from 'react-icons/sl';
import { updateValues } from '../../redux/reducers/addEditRadniNalog';
import { setRadniNalogFilter } from '../../redux/reducers/filterRadniNalogCriteriaSlice';
import { setUserOj } from '../../redux/reducers/userSlice';
import './PromeniOj.css';

const PromeniOj = () => {
  const oj = [{ ojId: 852, ojNaziv: 'Sonta' }, { ojId: 854, ojNaziv: 'Ruma' },];
  const [newOj, setNewOj] = useState();
  const [selectedOj, setSelectedOj] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    const tmpOj = oj.filter((o) => (o.ojId !== ojUser.authUser.oj_id));
    setNewOj(tmpOj);
    setSelectedOj(tmpOj[0].ojId)
  }, [])

  const handlePromeniOj = () => {
    dispatch(updateValues({ name: 'oj', value: selectedOj }));
    dispatch(setRadniNalogFilter({ name: 'oj', value: selectedOj }));
    dispatch(setUserOj({ oj_id: selectedOj, naziv_oj: (newOj.filter((o) => (o.ojId == selectedOj)))[0].ojNaziv }))
    navigate('/main');
  }

  return (
    <div className='promeni-oj'>
      <SlClose className='promeni-oj-close-button' onClick={() => navigate('/main/landingpage')} />
      <div className="promeni-oj-top">
        <p>
          <span>Promeni organizacionu jedinicu</span>
        </p>
      </div>
      <div className="promeni-oj-bottom">
        <div className="input-group">
          <div className='input-field'>
            <label htmlFor=''>Organizaciona jedinica</label>
            <select value={selectedOj}
              onChange={(e) => { setSelectedOj(e.target.value) }}
            >
              {newOj?.map((o) => {
                return (
                  <option value={o.ojId}>{o.ojNaziv}</option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="register-group">
        <button onClick={handlePromeniOj}>Primeni</button>
      </div>
    </div>
  )
}

export default PromeniOj;