import { useEffect, useState } from 'react';
import { SlClose } from 'react-icons/sl';
import Select from 'react-select';
import { customStyles } from '../../assets/selectCustomStyles';
import './UnosRepromaterijala.css';

const UnosRepromaterijala = ({ unosRepromaterijalaShow, setUnosRepromaterijalaShow, repromaterijal, uradjeno, addRepMaterijal, setAddRepMaterijal }) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    const tmpOptions = repromaterijal?.map(({
      id: value,
      naziv: label
    }) => ({
      value,
      label
    }));
    setOptions(tmpOptions);
  }, [repromaterijal])

  const handleRepromaterijalChange = (vrsta, e, index) => {
    const newRepMaterijal = [...addRepMaterijal];
    if (e === null) {
      newRepMaterijal[index].repromaterijal_id = '';
      newRepMaterijal[index].repromaterijal_naziv = '-----';
      newRepMaterijal[index].repromaterijal_kolicina = '';
    }
    else {
      if (vrsta === 'id') {
        newRepMaterijal[index].repromaterijal_id = e.value;
        newRepMaterijal[index].repromaterijal_naziv = e.label;
      }
      else
        newRepMaterijal[index].repromaterijal_kolicina = e.target.value;
    }

    setAddRepMaterijal(newRepMaterijal);
  }

  return (
    <div className='unos-repromaterijala-container' style={{ display: unosRepromaterijalaShow ? 'flex' : 'none' }}>
      <div className="unos-repromaterijala-header">
        <p>Repromaterijal</p>
        <SlClose className='repromaterijal-close-button' onClick={() => setUnosRepromaterijalaShow(false)} />
      </div>
      <div className="input-field">
        <label htmlFor="">Urađeno ha</label>
        <div className='uradjeno'>{uradjeno}</div>
      </div>
      <div className="unos-repromaterijala-input">
        <div className="unos-repromaterijala-wrapper"><span>1</span>
          <Select className='select'
            value={{ label: addRepMaterijal[0]?.repromaterijal_naziv, value: addRepMaterijal[0]?.repromaterijal_id }}
            onChange={(e) => handleRepromaterijalChange('id', e, 0)}
            options={options}
            placeholder={''}
            isClearable
            isSearchable
            isDisabled={repromaterijal?.length === 0 ? true : false}
            styles={customStyles}
          />
          <input type="number" min={0} step={0.001} value={addRepMaterijal[0]?.repromaterijal_kolicina} onChange={(e) => handleRepromaterijalChange('kolicina', e, 0)} />
          <input type="number" disabled value={(addRepMaterijal[0]?.repromaterijal_kolicina / uradjeno).toFixed(2)} />
        </div>
        <div className="unos-repromaterijala-wrapper"><span>2</span>
          <Select className='select'
            value={{ label: addRepMaterijal[1]?.repromaterijal_naziv, value: addRepMaterijal[1]?.repromaterijal_id }}
            onChange={(e) => handleRepromaterijalChange('id', e, 1)}
            options={options}
            placeholder={''}
            isClearable
            isSearchable
            isDisabled={repromaterijal?.length === 0 ? true : false}
            styles={customStyles}
          />
          <input type="number" min={0} step={0.001} value={addRepMaterijal[1]?.repromaterijal_kolicina} onChange={(e) => handleRepromaterijalChange('kolicina', e, 1)} />
          <input type="number" disabled value={(addRepMaterijal[1]?.repromaterijal_kolicina / uradjeno).toFixed(2)} />
        </div>
        <div className="unos-repromaterijala-wrapper"><span>3</span>
          <Select className='select'
            value={{ label: addRepMaterijal[2]?.repromaterijal_naziv, value: addRepMaterijal[2]?.repromaterijal_id }}
            onChange={(e) => handleRepromaterijalChange('id', e, 2)}
            options={options}
            placeholder={''}
            isClearable
            isSearchable
            isDisabled={repromaterijal?.length === 0 ? true : false}
            styles={customStyles}
          />
          <input type="number" min={0} step={0.001} value={addRepMaterijal[2]?.repromaterijal_kolicina} onChange={(e) => handleRepromaterijalChange('kolicina', e, 2)} />
          <input type="number" disabled value={(addRepMaterijal[2]?.repromaterijal_kolicina / uradjeno).toFixed(2)} />
        </div>
        <div className="unos-repromaterijala-wrapper"><span>4</span>
          <Select className='select'
            value={{ label: addRepMaterijal[3]?.repromaterijal_naziv, value: addRepMaterijal[3]?.repromaterijal_id }}
            onChange={(e) => handleRepromaterijalChange('id', e, 3)}
            options={options}
            placeholder={''}
            isClearable
            isSearchable
            isDisabled={repromaterijal?.length === 0 ? true : false}
            styles={customStyles}
          />
          <input type="number" min={0} step={0.001} value={addRepMaterijal[3]?.repromaterijal_kolicina} onChange={(e) => handleRepromaterijalChange('kolicina', e, 3)} />
          <input type="number" disabled value={(addRepMaterijal[3]?.repromaterijal_kolicina / uradjeno).toFixed(2)} />
        </div>
        <div className="unos-repromaterijala-wrapper"><span>5</span>
          <Select className='select'
            value={{ label: addRepMaterijal[4]?.repromaterijal_naziv, value: addRepMaterijal[4]?.repromaterijal_id }}
            onChange={(e) => handleRepromaterijalChange('id', e, 4)}
            options={options}
            placeholder={''}
            isClearable
            isSearchable
            isDisabled={repromaterijal?.length === 0 ? true : false}
            styles={customStyles}
          />
          <input type="number" min={0} step={0.001} value={addRepMaterijal[4]?.repromaterijal_kolicina} onChange={(e) => handleRepromaterijalChange('kolicina', e, 4)} />
          <input type="number" disabled value={(addRepMaterijal[4]?.repromaterijal_kolicina / uradjeno).toFixed(2)} />
        </div>
        <div className="unos-repromaterijala-wrapper"><span>6</span>
          <Select className='select'
            value={{ label: addRepMaterijal[5]?.repromaterijal_naziv, value: addRepMaterijal[5]?.repromaterijal_id }}
            onChange={(e) => handleRepromaterijalChange('id', e, 5)}
            options={options}
            placeholder={''}
            isClearable
            isSearchable
            isDisabled={repromaterijal?.length === 0 ? true : false}
            styles={customStyles}
          />
          <input type="number" min={0} step={0.001} value={addRepMaterijal[5]?.repromaterijal_kolicina} onChange={(e) => handleRepromaterijalChange('kolicina', e, 5)} />
          <input type="number" disabled value={(addRepMaterijal[5]?.repromaterijal_kolicina / uradjeno).toFixed(2)} />
        </div>
        <div className="unos-repromaterijala-wrapper"><span>7</span>
          <Select className='select'
            value={{ label: addRepMaterijal[6]?.repromaterijal_naziv, value: addRepMaterijal[6]?.repromaterijal_id }}
            onChange={(e) => handleRepromaterijalChange('id', e, 6)}
            options={options}
            placeholder={''}
            isClearable
            isSearchable
            isDisabled={repromaterijal?.length === 0 ? true : false}
            styles={customStyles}
          />
          <input type="number" min={0} step={0.001} value={addRepMaterijal[6]?.repromaterijal_kolicina} onChange={(e) => handleRepromaterijalChange('kolicina', e, 6)} />
          <input type="number" disabled value={(addRepMaterijal[6]?.repromaterijal_kolicina / uradjeno).toFixed(2)} />
        </div>
      </div>
    </div>
  )
}

export default UnosRepromaterijala;