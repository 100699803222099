import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SlClose } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { deleteData, getData, selectStatementData } from '../../services/functions/server_functions/functions';
import useHandleOnChangeCheckBox from '../../hooks/useHandleOnChangeCheckBox';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import MasinaIF from './MasinaIF';
import './Masina.css';

const Masina = () => {
  const [masina, setMasina] = useState([]);
  const [selectedRows, setSelectedRows, handleOnChangeCheckBox] = useHandleOnChangeCheckBox();
  const [editData, setEditData] = useState({ id: '', naziv: '', registracija: '' });
  const [addEditData, setAddEditData] = useState('');
  const [showInputForm, setShowInputForm] = useState(false);
  const [oj, setOj] = useState([]);
  const [selectedOj, setSelectedOj] = useState({ oj: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    const fetchOJ = async () => {
      const ojData = await selectStatementData(`SELECT oj.id, oj.naziv FROM organizaciona_jedinica oj`);
      setOj(ojData.data);
      setSelectedOj({ oj: ojUser.authUser.oj_id });
    }

    fetchOJ();
    setSelectedRows([]);
  }, [])

  useEffect(() => {
    fetchMasina();
  }, [selectedOj])

  const fetchMasina = async () => {
    try {
      dispatch(setWorking(true));
      setSelectedRows([]);
      const masinaData = await selectStatementData(`SELECT m.id, m.oj_id, m.naziv, m.registracija, oj.id as oj_id, oj.naziv as naziv_oj FROM masina m JOIN organizaciona_jedinica oj ON m.oj_id = oj.id AND m.oj_id = COALESCE(${selectedOj.oj}, m.oj_id)`);
      setMasina(masinaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleAdd = () => {
    setAddEditData('add');
    setEditData({ id: '', naziv: '', registracija: '', oj: '' });
    setShowInputForm(true);
  }

  const handleEdit = () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati mašinu za izmenu.', icon: 'warning', showHide: true })); return; }
    setAddEditData('edit');
    const id = parseInt(selectedRows[selectedRows.length - 1]);
    const naziv = masina.filter((op) => (op.id == id))
    setEditData({ id: `${id}`, naziv: `${naziv[0].naziv}`, registracija: `${naziv[0].registracija}`, oj: `${naziv[0].oj_id}` })
    setShowInputForm(true);
  }

  const handleDelete = async () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati mašinu za brisanje.', icon: 'warning', showHide: true })); return; }
    try {
      dispatch(setWorking(true));
      const returnedData = await deleteData({ tableName: 'masina', whereCondition: `id in (${selectedRows.toString()})` })
      dispatch(setMessage({ msgText: 'Selektovana mašina je obrisana.', icon: 'success', showHide: true }));
      fetchMasina();
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  // const organizacionaJedinicaChange = async (e) => {
  //   console.log(e)
  //   try {
  //     dispatch(setWorking(true));
  //     const masinaData = await selectStatementData(`SELECT m.id, m.naziv, m.registracija, oj.naziv as naziv_oj FROM masina m JOIN organizaciona_jedinica oj on m.oj_id = oj.id WHERE m.oj_id = COALESCE(${e}, m.oj_id)`);
  //     setMasina(masinaData.data);
  //   } catch (error) {
  //     dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
  //   } finally {
  //     dispatch(setWorking(false));
  //   }
  // }

  if (showInputForm) return <MasinaIF showForm={setShowInputForm} fetchMasina={fetchMasina} editData={editData} addEditData={addEditData} />;

  return (
    <div className='masina'>
      <div className='table-form'>
        <div className="table-form-header">Pregled mašina<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="table-form-main">
          <div className="input-field">
            <label htmlFor="">Organizaciona jedinica</label>
            <select value={selectedOj.oj} onChange={(e) => setSelectedOj({ oj: e.target.value })}>
              <option value="null">-----</option>
              {oj && oj.map((o) => {
                return (
                  <option key={o.id} value={o.id}>{o.naziv}</option>
                )
              })}
            </select>
          </div>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Naziv</th>
                  <th>Registracija</th>
                  <th>OJ</th>
                </tr>
              </thead>
              <tbody>
                {masina.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td><input type="checkbox" name={data.id} onChange={handleOnChangeCheckBox} /></td>
                      <td>{data.id}</td>
                      <td>{data.naziv}</td>
                      <td>{data.registracija}</td>
                      <td>{data.naziv_oj}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          </div>
          <div className='number-of-rows'>Broj redova: {masina.length}</div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleAdd}>Dodaj</button>
          <button onClick={handleEdit}>Izmeni</button>
          <button onClick={handleDelete}>Obriši</button>
        </div>
      </div >
    </div >
  )
}

export default Masina;