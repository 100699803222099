import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SlClose } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import useHandleOnChangeCheckBox from '../../hooks/useHandleOnChangeCheckBox';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { deleteData, getData, selectStatementData } from '../../services/functions/server_functions/functions';
import RadnikIF from './RadnikIF';
import './Radnik.css';

const Radnik = () => {
  const [radnik, setRadnik] = useState([]);
  const [selectedRows, setSelectedRows, handleOnChangeCheckBox] = useHandleOnChangeCheckBox();
  const [editData, setEditData] = useState({ id: '', prezime: '', ime: '', oj: '' });
  const [addEditData, setAddEditData] = useState('');
  const [showInputForm, setShowInputForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    fetchRadnik();
  }, [])

  const fetchRadnik = async () => {
    try {
      dispatch(setWorking(true));
      setSelectedRows([]);
      const radnikData = await selectStatementData(`SELECT r.id, r.prezime, r.ime, concat(r.prezime, ' ', r.ime) as prezime_i_ime, r.oj_id, oj.naziv as naziv_oj FROM radnik r JOIN organizaciona_jedinica oj ON r.oj_id = oj.id WHERE r.oj_id = ${ojUser.authUser.oj_id}`);
      setRadnik(radnikData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleAdd = () => {
    setAddEditData('add');
    setEditData({ id: '', naziv: '', oj: 0 });
    setShowInputForm(true);
  }

  const handleEdit = () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati radnika za izmenu.', icon: 'warning', showHide: true })); return; }
    setAddEditData('edit');
    const id = parseInt(selectedRows[selectedRows.length - 1]);
    const naziv = radnik.filter((op) => (op.id == id))
    setEditData({ id: `${id}`, prezime: `${naziv[0].prezime}`, ime: `${naziv[0].ime}`, oj: `${naziv[0].oj_id}` })
    setShowInputForm(true);
  }

  const handleDelete = async () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati radnika za brisanje.', icon: 'warning', showHide: true })); return; }
    try {
      const result = await deleteData({ tableName: 'radnik', whereCondition: `id in (${selectedRows.toString()})` });
      const res = await result.json();
      if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); }
      dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
      fetchRadnik();
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  if (showInputForm) return <RadnikIF showForm={setShowInputForm} fetchRadnik={fetchRadnik} editData={editData} addEditData={addEditData} />;

  return (
    <div className='radnik'>
      <div className='table-form'>
        <div className="table-form-header">Pregled radnika<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="table-form-main">
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Prezime</th>
                  <th>Ime</th>
                  <th>OJ</th>
                </tr>
              </thead>
              <tbody>
                {radnik.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td><input type="checkbox" name={data.id} onChange={handleOnChangeCheckBox} /></td>
                      <td>{data.id}</td>
                      <td>{data.prezime}</td>
                      <td>{data.ime}</td>
                      <td>{data.naziv_oj}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          </div>
          <div className='number-of-rows'>Broj redova: {radnik.length}</div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleAdd}>Dodaj</button>
          <button onClick={handleEdit}>Izmeni</button>
          <button onClick={handleDelete}>Obriši</button>
        </div>
      </div >
    </div >
  )
}

export default Radnik;