import { createSlice } from "@reduxjs/toolkit";

const initState = { working: false };

const workingSlice = createSlice({
  name: 'workingSliceReducer',
  initialState: initState,
  reducers: {
    setWorking: (state, action) => {
      state.working = action.payload;
      return state;
    },
    getWorking: (state, action) => {
      return state;
    },
  }
});

export const { setWorking, getWorking } = workingSlice.actions;

export default workingSlice.reducer;
