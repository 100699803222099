import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';
import radniNalogSlice from './reducers/radniNalogSlice';
import addEditRadniNalogSlice from './reducers/addEditRadniNalog';
import operacijaSlice from './reducers/operacijaSlice';
import parcelaSlice from './reducers/parcelaSlice';
import masinaSlice from './reducers/masinaSlice';
import prikljucnaMasinaSlice from './reducers/prikljucnaMasinaSlice';
import radnikSlice from './reducers/radnikSlice';
import repromaterijalSlice from './reducers/repromaterijalSlice';
import operacijaMasinaSlice from './reducers/operacijaMasinaSlice';
import filterRadniNalogCriteriaSlice from './reducers/filterRadniNalogCriteriaSlice';
import radniNalogRepromaterijalSlice from './reducers/radniNalogRepromaterijalSlice';
import showMessageWindowSlice from './reducers/showMessageWindowSlice';
import ojSlice from './reducers/ojSlice';
import pravoSlice from './reducers/pravoSlice';
import workingSlice from './reducers/workingSlice';
import kulturaSlice from './reducers/kulturaSlice';
import vrstaRepromaterijalaSlice from './reducers/vrstaRepromaterijala';
import podvrstaRepromaterijalaSlice from './reducers/podvrstaRepromaterijala';

export default configureStore({
  reducer: {
    user: userReducer,
    nalog: radniNalogSlice,
    addEditRadniNalog: addEditRadniNalogSlice,
    operacija: operacijaSlice,
    parcela: parcelaSlice,
    masina: masinaSlice,
    prikljucnaMasina: prikljucnaMasinaSlice,
    radnik: radnikSlice,
    repromaterijal: repromaterijalSlice,
    operacijaMasina: operacijaMasinaSlice,
    filterRadniNalogCriteria: filterRadniNalogCriteriaSlice,
    radniNalogRepromaterijal: radniNalogRepromaterijalSlice,
    showMessageWindowSlice: showMessageWindowSlice,
    oj: ojSlice,
    pravo: pravoSlice,
    working: workingSlice,
    kultura: kulturaSlice,
    vrstaRepromaterijala: vrstaRepromaterijalaSlice,
    podvrstaRepromaterijala: podvrstaRepromaterijalaSlice
  }
})
