import { useEffect, useState } from 'react';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectStatementData } from '../../services/functions/server_functions/functions';
import { useRef } from 'react';
import MajkaCerka from '../majka_cerka/MajkaCerka';
import { BsThreeDots } from 'react-icons/bs';
import '../../assets/inputForm.css'
import './ParcelaIf.css';

const ParcelaIF = ({ showForm, fetchParcela, editData, addEditData }) => {
  const [parcela, setParcela] = useState({ id: editData.id, naziv: editData.naziv, povrsina: editData.povrsina, kultura: editData.kultura_id, oj: editData.oj_id, ne_prikazivati_u_rn: editData.ne_prikazivati_u_rn, parcela_majka: editData.parcela_majka, procenat_nasledjenih_troskova: editData.procenat_nasledjenih_troskova });
  const [prenetiTroskovi, setPrenetiTroskovi] = useState([]);
  const [oj, setOj] = useState([]);
  const [kultura, setKultura] = useState([]);
  const [oldId] = useState(editData.id);
  const idRef = useRef();
  const nazivRef = useRef();
  const dispatch = useDispatch();

  const [majkaCerkaShow, setMajkaCerkaShow] = useState(false);

  useEffect(() => {
    if (addEditData === 'add')
      idRef.current.focus();
    else
      nazivRef.current.focus();
    const fetchData = async () => {
      try {
        const ojData = await selectStatementData(`SELECT oj.id, oj.naziv from organizaciona_jedinica oj`);
        setOj(ojData.data);
        const kulturaData = await selectStatementData(`SELECT k.id, k.naziv from kultura k`);
        setKultura(kulturaData.data);
        if (addEditData === 'edit') {
          const prenetiTroskoviData = await selectStatementData(`SELECT stara_parcela_id, preneti_procenat FROM prenos_troskova WHERE oj_id = ${parcela.oj} AND nova_parcela_id = ${editData.id} ORDER BY nova_parcela_id ASC`);
          setPrenetiTroskovi(prenetiTroskoviData.data);
        }
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    fetchData();
  }, [])


  const handleSave = async () => {
    console.log(JSON.stringify({
      novaParcela: parcela.id,
      oj: parcela.oj,
      prenetiTroskovi
    }));
    // return; // Podela parcela - Majka / Ddete. Ovo nece trebati. Pokrenuti parcelu koja ce sve stare parcele zameniti sa novim ID
    if (parcela.id === '' || parcela.naziv === '' || parcela.oj === 0) { dispatch(setMessage({ msgText: 'Nisu popunjena sva obavezna polja.', icon: 'warning', showHide: true })); return };
    if (addEditData === 'edit') {
      if (parcela.povrsina === '') setParcela({ ...parcela, povrsina: 0 });
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({
              sentence: `update parcela set id = ${parcela.id}, naziv = '${parcela.naziv}', povrsina = ${parcela.povrsina}, kultura_id = ${parcela.kultura}, oj_id = ${parcela.oj}, ne_prikazivati_u_rn = ${parcela.ne_prikazivati_u_rn}, parcela_majka = ${parcela.parcela_majka}, procenat_nasledjenih_troskova = ${parcela.procenat_nasledjenih_troskova} where id = ${oldId}`
            })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchParcela();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    else {
      try {
        if (parcela.povrsina === '') setParcela({ ...parcela, povrsina: 0 });
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `insert into parcela values(${parcela.id}, ${parcela.oj}, '${parcela.naziv}', ${parcela.povrsina}, ${parcela.kultura}, ${parcela.ne_prikazivati_u_rn}, ${parcela.parcela_majka}, ${parcela.procenat_nasledjenih_troskova})` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchParcela();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
  }

  const handleCancelSave = () => {
    fetchParcela();
    showForm(false);
  }

  return (
    <div className='parcela-if'>
      <div className='input-form'>
        <div className="title-bar">Parcela / Mesto troška</div>
        <div className="input-field">
          <label htmlFor="">ID</label>
          <input type="number" name="" ref={idRef} value={parcela.id}
            onChange={(e) => { if (e.code < 48 || e.code > 57 || e.code === 101) return; else setParcela({ ...parcela, id: e.target.value }) }} />
        </div>
        <div className="input-field">
          <label htmlFor="">Naziv parcele / mesta troška</label>
          <input type="text" name="" ref={nazivRef} value={parcela.naziv} onChange={(e) => setParcela({ ...parcela, naziv: e.target.value })} />
        </div>
        <div className="parcela-group">
          <div className="input-field">
            <label htmlFor="">Površina</label>
            <input type="number" name="" value={parcela.povrsina} onChange={(e) => setParcela({ ...parcela, povrsina: e.target.value || null })} />
          </div>
          <div className="input-field">
            <label htmlFor="">Kultura</label>
            <select name="" value={parcela.kultura} onChange={(e) => setParcela({ ...parcela, kultura: e.target.value })}>
              <option value="0">-----</option>
              {kultura.map((k) => {
                return (
                  <option key={k.id} value={k.id}>{k.naziv}</option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="input-field">
          <label htmlFor="">Organizaciona jedinica</label>
          <select name="" value={parcela.oj} onChange={(e) => setParcela({ ...parcela, oj: e.target.value })}>
            <option value="0">-----</option>
            {oj.map((oj) => {
              return (
                <option key={oj.id} value={oj.id}>{oj.naziv}</option>
              )
            })}
          </select>
        </div>
        <div className="input-field">
          <label htmlFor="">Ne prikazivati u radnom nalogu <input type='checkbox'
            checked={parcela.ne_prikazivati_u_rn == 0 ? false : true}
            onChange={(e) => {
              setParcela({ ...parcela, ne_prikazivati_u_rn: e.target.checked });
              // setPrenetiTroskovi([]);
              setMajkaCerkaShow(false);
            }}></input></label>
        </div>
        <div className="parcela-group">
          {/* <div className="input-field">
            <label htmlFor="">Parcela ''majka'' (ID)</label>
            <input type='number' min={0} value={parcela.parcela_majka}
              onChange={(e) => setParcela({ ...parcela, parcela_majka: e.target.value })}>
            </input>
          </div>
          <div className="input-field">
            <label htmlFor="">Procenat troskova</label>
            <input type='number' min={0} value={parcela.procenat_nasledjenih_troskova}
              onChange={(e) => setParcela({ ...parcela, procenat_nasledjenih_troskova: e.target.value })}>
            </input>
          </div>
          <div className="input-field">
            <label htmlFor="">Procenat troskova</label>
            <input type='date' min={0} value={parcela.procenat_nasledjenih_troskova}
              onChange={(e) => setParcela({ ...parcela, procenat_nasledjenih_troskova: e.target.value })}>
            </input>
          </div> */}

          {/* <button className='majka-cerka-button' onClick={() => {
            console.log(parcela.ne_prikazivati_u_rn);
            if (parcela.ne_prikazivati_u_rn === false || parcela.ne_prikazivati_u_rn === 0) {
              setMajkaCerkaShow(true);
            }
            else
              setMajkaCerkaShow(false);
          }}>Prenos troškova <BsThreeDots style={{ color: parcela.ne_prikazivati_u_rn === false || parcela.ne_prikazivati_u_rn === 0 ? '#16aaff' : '#495057' }} /> </button> */}
        </div>
        {/* {majkaCerkaShow && <MajkaCerka setMajkaCerkaShow={setMajkaCerkaShow} prenetiTroskovi={prenetiTroskovi} setPrenetiTroskovi={setPrenetiTroskovi} />} */}
        <footer>
          <button onClick={handleSave}>Snimi</button>
          <button onClick={handleCancelSave}>Odustani</button>
        </footer>
      </div>
    </div>
  )
}

export default ParcelaIF;