import { useDispatch, useSelector } from "react-redux";
import { addRadniNalog } from "../redux/reducers/radniNalogSlice";
import { setMessage } from "../redux/reducers/showMessageWindowSlice";
import { setWorking } from "../redux/reducers/workingSlice";
import { execProcedure } from "../services/functions/server_functions/functions";

const useRadniNalogRefresh = () => {
  const filterCriteria = useSelector((state) => state.filterRadniNalogCriteria);
  const dispatch = useDispatch();

  const refresh = async () => {
    try {
      dispatch(setWorking(true));
      const radniNalogData = await execProcedure('filterRadniNalog', 'filterRadniNalog', filterCriteria);
      dispatch(addRadniNalog(radniNalogData.data));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }
  return [refresh];
}

export default useRadniNalogRefresh;