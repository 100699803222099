import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { selectStatementData } from '../../services/functions/server_functions/functions';
import './RepromaterijalIf.css';

const RepromaterijalIF = ({ showForm, fetchRepromaterijal, editData, addEditData }) => {
  const [repromaterijal, setRepromaterijal] = useState({ id: editData.id, naziv: editData.naziv, vrsta: editData.vrsta, podvrsta: editData.podvrsta, jm: editData.jm });
  const [vrsta, setVrsta] = useState([]);
  const [podvrsta, setPodvrsta] = useState([]);
  const [oldId] = useState(editData.id);
  const idRef = useRef();
  const nazivRef = useRef();
  const dispatch = useDispatch();
  // const vrsta = useSelector((state) => state.vrstaRepromaterijala);
  // const podvrsta = useSelector((state) => state.podvrstaRepromaterijala);

  useEffect(() => {
    if (addEditData === 'add')
      idRef.current.focus();
    else
      nazivRef.current.focus();

    const fetchData = async () => {
      try {
        const vrstaData = await selectStatementData(`SELECT vr.id, vr.naziv FROM vrsta_repromaterijala vr`);
        setVrsta(vrstaData.data);
        const podvrstaData = await selectStatementData(`SELECT pv.id, pv.naziv FROM podvrsta_repromaterijala pv`);
        setPodvrsta(podvrstaData.data);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    fetchData();
  }, [])

  const handleSave = async () => {
    if (repromaterijal.id === '' || repromaterijal.naziv === '' || repromaterijal.vrsta === 0 || repromaterijal.podvrsta === 0 || repromaterijal.jm === '') { dispatch(setMessage({ msgText: 'Nisu popunjena sva obavezna polja.', icon: 'warning', showHide: true })); return };
    if (addEditData === 'edit') {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `update repromaterijal set id = '${repromaterijal.id}', naziv = '${repromaterijal.naziv}', vrsta_id = ${repromaterijal.vrsta}, podvrsta_id = ${repromaterijal.podvrsta}, jm = '${repromaterijal.jm}', oj_id = 0 where id = ${oldId}` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchRepromaterijal();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    else {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `insert into repromaterijal values(${repromaterijal.id}, '${repromaterijal.naziv}', ${repromaterijal.vrsta}, ${repromaterijal.podvrsta}, '${repromaterijal.jm}', 0 )` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchRepromaterijal();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
  }

  const handleCancelSave = () => {
    fetchRepromaterijal();
    showForm(false)
  }

  return (
    <div className='repromaterijal-if'>
      <div className='input-form'>
        <div className="title-bar">Repromaterijal</div>
        <div className="input-field">
          <label htmlFor="">ID</label>
          <input type="number" name="" ref={idRef} value={repromaterijal.id} onChange={(e) => setRepromaterijal({ ...repromaterijal, id: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Naziv</label>
          <input type="text" name="" ref={nazivRef} value={repromaterijal.naziv} onChange={(e) => setRepromaterijal({ ...repromaterijal, naziv: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Vrsta</label>
          <select name="" value={repromaterijal.vrsta} onChange={(e) => setRepromaterijal({ ...repromaterijal, vrsta: e.target.value })}>
            <option value="0">-----</option>
            {vrsta.map((v) => {
              return (
                <option key={v.id} value={v.id}>{v.naziv}</option>
              )
            })}
          </select>
        </div>
        <div className="input-field">
          <label htmlFor="">Podvrsta</label>
          <select name="" value={repromaterijal.podvrsta} onChange={(e) => setRepromaterijal({ ...repromaterijal, podvrsta: e.target.value })}>
            <option value="0">-----</option>
            {podvrsta.map((pv) => {
              return (
                <option key={pv.id} value={pv.id}>{pv.naziv}</option>
              )
            })}
          </select>
        </div>
        <div className="input-field">
          <label htmlFor="">JM</label>
          <input type="text" name="" value={repromaterijal.jm} onChange={(e) => setRepromaterijal({ ...repromaterijal, jm: e.target.value })} />
        </div>
        <footer>
          <button onClick={handleSave}>Snimi</button>
          <button onClick={handleCancelSave}>Odustani</button>
        </footer>
      </div>
    </div>
  )
}

export default RepromaterijalIF;