import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import useHandleOnChangeCheckBox from '../../hooks/useHandleOnChangeCheckBox';
import { deleteData, selectStatementData } from '../../services/functions/server_functions/functions';
import { SlClose } from 'react-icons/sl';
import { BsCheck2Square } from 'react-icons/bs';
import ParcelaIF from './ParcelaIF';

import './Parcela.css';

const Parcela = () => {
  const [parcela, setParcela] = useState([]);
  const [selectedRows, setSelectedRows, handleOnChangeCheckBox] = useHandleOnChangeCheckBox();
  const [editData, setEditData] = useState({ id: '', oj_id: null, naziv: '', povrsina: '', kultura_id: null, ne_prikazivati_u_rn: 0, parcela_majka: 0, procenat_nasledjenih_troskova: 0 });
  const [addEditData, setAddEditData] = useState('');
  const [showInputForm, setShowInputForm] = useState(false);
  const [oj, setOj] = useState([]);
  const [selectedOj, setSelectedOj] = useState({ oj: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    const fetchOJ = async () => {
      const ojData = await selectStatementData(`SELECT oj.id, oj.naziv FROM organizaciona_jedinica oj`);
      setOj(ojData.data);
      setSelectedOj({ oj: ojUser.authUser.oj_id });
    }

    fetchOJ();
    setSelectedRows([]);
  }, [])

  useEffect(() => {
    fetchParcela();
  }, [selectedOj])

  const fetchParcela = async () => {
    try {
      dispatch(setWorking(true));
      setSelectedRows([]);
      const parcelaData = await selectStatementData(`SELECT p.id, p.oj_id, p.naziv, p.povrsina, p.kultura_id, p.ne_prikazivati_u_rn, p.parcela_majka, p.procenat_nasledjenih_troskova, oj.naziv as oj_naziv, k.naziv as kultura_naziv FROM parcela p JOIN organizaciona_jedinica oj on p.oj_id = oj.id LEFT JOIN kultura k ON p.kultura_id = k.id WHERE p.oj_id = COALESCE(${selectedOj.oj}, p.oj_id)`);
      setParcela(parcelaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleAdd = () => {
    setAddEditData('add');
    setEditData({ id: '', naziv: '', povrsina: '', kultura_id: 0, oj_id: 0, ne_prikazivati_u_rn: 0, parcela_majka: 0, procenat_nasledjenih_troskova: 0 });
    setShowInputForm(true);
  }

  const handleEdit = () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati parcelu za izmenu.', icon: 'warning', showHide: true })); return; }
    setAddEditData('edit');
    const id = parseInt(selectedRows[selectedRows.length - 1]);
    const parcelaData = parcela.filter((op) => (op.id == id))
    setEditData({ id: `${id}`, naziv: `${parcelaData[0].naziv}`, povrsina: `${parcelaData[0].povrsina}`, kultura_id: `${parcelaData[0].kultura_id}`, oj_id: `${parcelaData[0].oj_id}`, ne_prikazivati_u_rn: `${parcelaData[0].ne_prikazivati_u_rn}`, parcela_majka: `${parcelaData[0].parcela_majka}`, procenat_nasledjenih_troskova: `${parcelaData[0].procenat_nasledjenih_troskova}` });
    setShowInputForm(true);
  }

  const handleDelete = async () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati parcelu za brisanje.', icon: 'warning', showHide: true })); return; }
    try {
      dispatch(setWorking(true));
      const returnedData = await deleteData({ tableName: 'parcela', whereCondition: `id in (${selectedRows.toString()})` })
      await deleteData({ tableName: 'prenos_troskova', whereCondition: `nova_parcela_id IN (${selectedRows.toString()}) AND oj_id = ${selectedOj.oj}` })
      dispatch(setMessage({ msgText: 'Selektovana parcela je obrisana.', icon: 'success', showHide: true }));
      fetchParcela();
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  if (showInputForm) return <ParcelaIF showForm={setShowInputForm} fetchParcela={fetchParcela} editData={editData} addEditData={addEditData} />;

  return (
    <div className='parcela'>
      <div className='table-form'>
        <div className="table-form-header">Pregled parcela / mesta troškova<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="table-form-main">
          <div className="input-field">
            <label htmlFor="">Organizaciona jedinica</label>
            <select value={selectedOj.oj} onChange={(e) => { setSelectedOj({ oj: e.target.value }); }}>
              <option value={0}>-----</option>
              {oj && oj.map((o) => {
                return (
                  <option key={o.id} value={o.id}>{o.naziv}</option>
                )
              })}
            </select>
          </div>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Naziv parcele / mesta troška</th>
                  <th>Površina</th>
                  <th>Kultura</th>
                  <th>OJ</th>
                  <th>Ne prikazuj u RN</th>
                </tr>
              </thead>
              <tbody>
                {parcela && parcela.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td><input type="checkbox" name={data.id} onChange={handleOnChangeCheckBox} /></td>
                      <td>{data.id}</td>
                      <td>{data.naziv}</td>
                      <td>{data.povrsina}</td>
                      <td>{data.kultura_naziv}</td>
                      <td>{data.oj_naziv}</td>
                      <td style={{ textAlign: 'center' }}>{data.ne_prikazivati_u_rn === 1 ? <BsCheck2Square /> : null}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          </div>
          <div className='number-of-rows'>Broj redova: {parcela.length}</div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleAdd}>Dodaj</button>
          <button onClick={handleEdit}>Izmeni</button>
          <button onClick={handleDelete}>Obriši</button>
        </div>
      </div >
    </div >
  )
}

export default Parcela;