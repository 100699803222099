import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const operacijaSlice = createSlice({
  name: 'operacijaSliceReducer',
  initialState: initState,
  reducers: {
    addOperacija: (state, action) => {
      state = [...action.payload];
      return state;
    },
    getOperacija: (state, action) => {
      return state;
    },
    getOperacijaKoristiRepromaterijal: (state, action) => {
      //
    }
  }
});

export const { addOperacija, getOperacija, getOperacijaKoristiRepromaterijal } = operacijaSlice.actions;

export default operacijaSlice.reducer;