import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDots } from 'react-icons/bs';
import { GiFertilizerBag } from 'react-icons/gi';
import { useEffect, useRef, useState } from 'react';
import DropDownList from '../../components/DropDownList/DropDownList';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { SlClose } from 'react-icons/sl';
import './RadniNalogPreview.css';
import UnosRepromaterijala from '../../components/UnosRepromaterijala/UnosRepromaterijala';
import UnosRepromaterijalaPreview from '../../components/UnosRepromaterijala/UnosRepromaterijalaPreview';

const RadniNalogPreview = ({ setShowPreviewForm }) => {
  const [unosRepromaterijalaPreviewShow, setUnosRepromaterijalaPreviewShow] = useState(false);
  const dispatch = useDispatch();
  const dataForSave = useSelector((state) => state.addEditRadniNalog);
  const operacija = useSelector((state) => state.operacija);
  const parcela = useSelector((state) => state.parcela);
  const masina = useSelector((state) => state.masina);
  const prikljucnaMasina = useSelector((state) => state.prikljucnaMasina);
  const radnik = useSelector((state) => state.radnik);
  console.log(dataForSave);
  return (
    <div className='radni-nalog-preview-input-form'>
      {unosRepromaterijalaPreviewShow && <UnosRepromaterijalaPreview setUnosRepromaterijalaPreviewShow={setUnosRepromaterijalaPreviewShow} uradjeno={dataForSave.uradjeno} />}
      <div className="radni-nalog-preview-title-bar">Radni nalog <span> - pregled</span><SlClose className='close-button' onClick={() => setShowPreviewForm(false)} /></div>
      <div className="radni-nalog-preview-broj-i-datum">
        <div className="input-field">
          <label htmlFor="">Broj naloga</label>
          <span>{dataForSave.id}</span>
        </div>
      </div>
      <div className='radni-nalog-preview'>
        <div className="radni-nalog-preview-left">
          <div className="radni-nalog-preview-wizard">
            <div className="radni-nalog-preview-wizard-left">1</div>
            <div className="radni-nalog-preview-wizard-right">
              <div className="input-field">
                <label htmlFor="">Operacija</label>
                <div className="select-wrapper">
                  <div className='item-border'>{(operacija?.filter((o) => o.id === dataForSave.operacija))[0]?.naziv}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="radni-nalog-preview-wizard">
            <div className="radni-nalog-preview-wizard-left">2</div>
            <div className="radni-nalog-preview-wizard-right">
              <div className="input-field-content">
                <div className="input-field">
                  <label htmlFor="">Parcela / mesto troška</label>
                  <div className='item-border'>{(parcela?.filter((o) => o.id === dataForSave.parcela))[0]?.naziv}</div>
                </div>
                <div className="input-field rn-kultura">
                  <label htmlFor="">Kultura</label>
                  <div className='item-border'>{(parcela?.filter((o) => o.id === dataForSave.parcela))[0]?.naziv_kulture}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="radni-nalog-preview-wizard">
            <div className="radni-nalog-preview-wizard-left">3</div>
            <div className="radni-nalog-preview-wizard-right">
              <div className="input-field-content">
                <div className="input-field rn-registracija">
                  <label htmlFor="">Registracija</label>
                  <div className='item-border'>{(masina?.filter((o) => o.id === dataForSave.masina))[0]?.registracija}</div>
                </div>
                <div className="input-field">
                  <label htmlFor="">Mašina</label>
                  <div className='item-border'>{(masina?.filter((o) => o.id === dataForSave.masina))[0]?.naziv}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="radni-nalog-preview-wizard">
            <div className="radni-nalog-preview-wizard-left">4</div>
            <div className="radni-nalog-preview-wizard-right">
              <div className="input-field">
                <label htmlFor="">Priključna mašina</label>
                <div className="select-wrapper">
                  <div className='item-border'>{(prikljucnaMasina?.filter((o) => o.id === dataForSave.prikljucnaMasina))[0]?.naziv}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="radni-nalog-preview-wizard">
            <div className="radni-nalog-preview-wizard-left">5</div>
            <div className="radni-nalog-preview-wizard-right">
              <div className="input-field">
                <label htmlFor="">Radnik</label>
                <div className="select-wrapper">
                  <div className='item-border'>{(radnik?.filter((o) => o.id == dataForSave.radnik))[0]?.prezime_ime}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="razdelna-linija"></div>
        <div className="radni-nalog-preview-right">
          <div className="radni-nalog-preview-wizard">
            <div className="radni-nalog-preview-wizard-left">6</div>
            <div className="radni-nalog-preview-wizard-right">
              <div className='input-field-content'>
                <div className="input-field hektari" style={{ visibility: dataForSave.nema_obradivu_povrsinu ? 'hidden' : 'visible' }}>
                  <label htmlFor="">Ukupno ha</label>
                  <input type="number" value={dataForSave.ukupnaPovrsina} disabled />
                </div>
                <div className="input-field hektari" style={{ visibility: dataForSave.nema_obradivu_povrsinu ? 'hidden' : 'visible' }}>
                  <label htmlFor="">Preostalo ha</label>
                  <input type="number" value={dataForSave.preostalo} disabled />
                </div>
                <div className="input-field hektari" style={{ visibility: dataForSave.nema_obradivu_povrsinu ? 'hidden' : 'visible' }}>
                  <label htmlFor="">Urađeno ha</label>
                  <input type="number" name="uradjeno" disabled min={0} step={0.001} value={dataForSave.uradjeno} />
                </div>
                <div className="input-field hektari">
                  <label htmlFor="">Natočeno l</label>
                  <input type="number" name="natoceno" disabled min={0} step={0.001} value={dataForSave.natoceno} />
                </div>
                <div className="input-field hektari" style={{ visibility: dataForSave.nema_obradivu_povrsinu ? 'hidden' : 'visible' }}>
                  <label htmlFor="">l/ha</label>
                  <input type="number" disabled name="prosek" value={(dataForSave.natoceno / dataForSave.uradjeno)?.toFixed(2)} />
                </div>
              </div>
            </div>
          </div>
          <div className="radni-nalog-preview-wizard">
            <div className="radni-nalog-preview-wizard-left">7</div>
            <div className="radni-nalog-preview-wizard-right">
              <div className="input-field">
                {/* <label htmlFor="">Repromaterijal</label>
                <div className='open-repromaterijal-btn' onClick={() => {
                  if (dataForSave.repromaterijal_stavke?.length > 0) setUnosRepromaterijalaPreviewShow(true)
                }}><GiFertilizerBag /><BsThreeDots style={{ color: dataForSave.repromaterijal_stavke?.length > 0 ? '#16aaff' : '#495057' }} /></div> */}


                {
                  (() => {
                    if (dataForSave.gotova_roba == 1) return (
                      <div className='gotova-roba'>
                        <div className="gotova-roba-right">
                          <div className="input-field">
                            <label htmlFor="">Merkantilna (Kg)</label>
                            <input type="number" name="gotova_roba_merkantilna" disabled value={dataForSave.gotova_roba_merkantilna} />
                          </div>
                          <div className="input-field">
                            <label htmlFor="">Semenska (Kg)</label>
                            <input type="number" name="gotova_roba_semenska" disabled value={dataForSave.gotova_roba_semenska} />
                          </div>
                          <div className="input-field">
                            <label htmlFor="">Ukupno (Kg)</label>
                            <input type="number" name="gotova_roba_ukupno" disabled value={parseFloat(dataForSave.gotova_roba_merkantilna) + parseFloat(dataForSave.gotova_roba_semenska)} />
                          </div>
                        </div>
                      </div>
                    )
                    else return (
                      <>
                        <label htmlFor="">Repromaterijal</label>
                        <div className='open-repromaterijal-btn' onClick={() => {
                          if (dataForSave.repromaterijal_stavke?.length > 0) setUnosRepromaterijalaPreviewShow(true)
                        }}><GiFertilizerBag /><BsThreeDots style={{ color: dataForSave.repromaterijal_stavke?.length > 0 ? '#16aaff' : '#495057' }} /></div>
                      </>
                    )
                  }
                  )()
                }

              </div>
            </div>
          </div>
          <div className="radni-nalog-preview-wizard">
            <div className="radni-nalog-preview-wizard-left">8</div>
            <div className="radni-nalog-preview-wizard-right">
              <div className="input-field">
                <label htmlFor="">Komentar</label>
                <textarea name="komentar" value={dataForSave.komentar} disabled></textarea>
              </div>
            </div>
          </div>
          <span className='zatvori-nalog'>Zatvori nalog za izabranu operaciju i parcelu <input type='checkbox' disabled checked={dataForSave.status === 2 ? true : false} /></span>
        </div>
      </div >
    </div >
  )
}

export default RadniNalogPreview;