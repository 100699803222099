import { useDispatch, useSelector } from 'react-redux';
import { setMessage, toggleShowHide } from '../../redux/reducers/showMessageWindowSlice';
import success from '../../images/success.png';
import warning from '../../images/warning.png';
import info from '../../images/info2.png';
import './OkMessage.css';

const OkMessage = () => {
  const dispatch = useDispatch();
  const msg = useSelector((state) => state.showMessageWindowSlice)

  let icon;
  switch (msg.icon) {
    case 'success': icon = success; break;
    case 'warning': icon = warning; break;
    case 'info': icon = info; break;
    default: icon = null; break;
  }

  return (
    <div className='ok-message'>
      <div className="ok-message-tekst">
        <img src={icon} />
        <p>{msg.msgText}</p>
      </div>
      <div className="ok-message-buttons">
        <button onClick={() => {
          dispatch(setMessage('', null, false));
        }}>OK</button>
      </div>
    </div>
  )
}

export default OkMessage;