import { Outlet } from 'react-router-dom';
import './MainAppArea.css';

const MainAppArea = () => {
  return (
    <div className='main-app-area' style={{ maxWidth: (window.innerWidth - 270) + 'px', maxHeight: (window.innerHeight - 55) + 'px' }}>
      <Outlet />
    </div>
  )
}

export default MainAppArea;