import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/inputForm.css';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { selectStatementData } from '../../services/functions/server_functions/functions';
import './MasinaIf.css';

const MasinaIF = ({ showForm, fetchMasina, editData, addEditData }) => {
  const [masina, setMasina] = useState({ id: editData.id, naziv: editData.naziv, registracija: editData.registracija, oj: editData.oj });
  const [oj, setOj] = useState([]);
  const [oldId] = useState(editData.id);
  const idRef = useRef();
  const nazivRef = useRef();
  const dispatch = useDispatch();
  // const oj = useSelector((state) => state.oj);

  useEffect(() => {
    if (addEditData === 'add')
      idRef.current.focus();
    else
      nazivRef.current.focus();

    const fetchData = async () => {
      try {
        const ojData = await selectStatementData(`SELECT oj.id, oj.naziv from organizaciona_jedinica oj`);
        setOj(ojData.data);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    fetchData();
  }, [])


  const handleSave = async () => {
    if (masina.id === '' || masina.naziv === '') { dispatch(setMessage({ msgText: 'Nisu popunjena sva obavezna polja.', icon: 'warning', showHide: true })); return };
    if (addEditData === 'edit') {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `update masina set id = '${masina.id}', naziv = '${masina.naziv}', registracija = '${masina.registracija}', oj_id=${masina.oj} where id = ${oldId}` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchMasina();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    else {
      try {

        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `insert into masina values(${masina.id}, '${masina.naziv}', '${masina.registracija}', ${masina.oj})` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchMasina();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
  }

  const handleCancelSave = () => {
    fetchMasina();
    showForm(false);
  }

  return (
    <div className='masina-if'>
      <div className='input-form'>
        <div className="title-bar">Mašina</div>
        <div className="input-field">
          <label htmlFor="">ID</label>
          <input type="number" name="" ref={idRef} value={masina.id} onChange={(e) => setMasina({ ...masina, id: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Naziv</label>
          <input type="text" name="" ref={nazivRef} value={masina.naziv} onChange={(e) => setMasina({ ...masina, naziv: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Registraciona oznaka</label>
          <input type="text" name="" value={masina.registracija} onChange={(e) => setMasina({ ...masina, registracija: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Organizaciona jedinica</label>
          <select name="" value={masina.oj} onChange={(e) => setMasina({ ...masina, oj: e.target.value })}>
            <option value="0">-----</option>
            {oj.map((oj) => {
              return (
                <option key={oj.id} value={oj.id}>{oj.naziv}</option>
              )
            })}
          </select>
        </div>
        <footer>
          <button onClick={handleSave}>Snimi</button>
          <button onClick={handleCancelSave}>Odustani</button>
        </footer>
      </div>
    </div>
  )
}

export default MasinaIF;