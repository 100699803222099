import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const radnikSlice = createSlice({
  name: 'radnikSliceReducer',
  initialState: initState,
  reducers: {
    addRadnik: (state, action) => {
      state = action.payload;
      return state;
    },
  }
});

export const { addRadnik } = radnikSlice.actions;

export default radnikSlice.reducer;