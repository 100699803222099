import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { SlClose } from 'react-icons/sl';
import filterIcon from '../../../images/filter.ico';
import { setWorking } from '../../../redux/reducers/workingSlice';
import { setMessage } from '../../../redux/reducers/showMessageWindowSlice';
import { execProcedure } from '../../../services/functions/server_functions/functions';
import './ExportToSap.css';

const ExportToSap = () => {
  const [showFilter, setShowFilter] = useState(true);
  const [exportToSap, setExportToSap] = useState([]);
  const [reportFilterCriteria, setReportFilterCriteria] = useState({ datumOd: '', datumDo: '', oj: 0 });
  const [ukupno, setUkupno] = useState({ gorivo_ukupno: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFilterDatumChange = (datum, datumName) => {
    if (datum !== null || datum !== '') {
      setReportFilterCriteria({ ...reportFilterCriteria, [datumName]: datum })
    }
    else {
      setReportFilterCriteria({ ...reportFilterCriteria, [datumName]: '' })
    }
  }

  const handleFilter = async () => {
    try {
      dispatch(setWorking(true));
      const exportToSapData = await execProcedure('allGet', 'reportExportToSap', reportFilterCriteria);
      setExportToSap(exportToSapData.data);
      let gorivo_u = 0;

      exportToSapData.data?.forEach((r) => {
        gorivo_u += r.ukupna_kolicina;
      });
      setUkupno({ gorivo_ukupno: gorivo_u });
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleExportToSap = () => {
    const worksheet = XLSX.utils.json_to_sheet(exportToSap, { skipHeader: true });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Radni nalog => SAP");
    let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Export u SAP.xlsx");
  }

  return (
    <div className='radni-nalog-container'>
      <div className='radni-nalog-table-form'>
        <div className="radni-nalog-table-form-header">Export podataka u SAP<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className='export-to-sap-filter' style={{ display: showFilter ? 'flex' : 'none' }}>
          {/* <div className='box-1'> */}
          <div className="input-field">
            <label htmlFor="">Od datuma</label>
            <input type="date"
              value={reportFilterCriteria.datumOd}
              onChange={(e) => {
                handleFilterDatumChange(e.target.value, 'datumOd');
              }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="">Do datuma</label>
            <input type="date"
              value={reportFilterCriteria.datumDo}
              onChange={(e) => {
                handleFilterDatumChange(e.target.value, 'datumDo');
              }}
            />
          </div>
          {/* </div> */}
          {/* <div className='box-5'> */}
          <div className="input-field">
            <label htmlFor="">Organizaciona jedinica</label>
            <select value={reportFilterCriteria.oj} onChange={(e) => {
              setReportFilterCriteria({ ...reportFilterCriteria, oj: e.target.value });
            }}>
              <option key={0} value={0}>-----</option>
              <option key={1} value={852}>Sonta</option>
              <option key={2} value={854}>Ruma</option>
            </select>
          </div>
          <button onClick={() => {
            handleFilter();
          }
          }>Filtriraj</button>
          {/* </div> */}
        </div>
        <div className="radni-nalog-table-form-main">
          <img className='filter-icon' src={filterIcon} onClick={() => { setShowFilter(!showFilter) }} />
          <div className="radni-nalog-table-form-main-table-wrapper">
            <table className='tabela'>
              <thead>
                <tr>
                  <th>Br. st.</th>
                  <th>Mesto troška</th>
                  <th>Tak pos</th>
                  <th>Nalog primaoca</th>
                  <th>Ukupna količina</th>
                  <th>JM</th>
                  <th>Tekst - operacija</th>
                  <th>Registracija</th>
                  <th>Broj naloga</th>
                </tr>
              </thead>
              <tbody>
                {exportToSap?.map((data, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.mesto_troska}</td>
                        <td>{data.tak_pos}</td>
                        <td>{data.nalog_primaoca}</td>
                        <td style={{ textAlign: 'right' }}>{(data.ukupna_kolicina)?.toFixed(2)}</td>
                        <td>{data.jm}</td>
                        <td>{data.tekst_operacija}</td>
                        <td>{data.registracija}</td>
                        <td>{data.id}</td>
                      </tr>
                    </>
                  )
                })
                }
              </tbody>
              <tfoot style={{ backgroundColor: 'crimson', color: 'white', fontWeight: '500' }}>
                <tr>
                  <td style={{ textAlign: 'right' }} colSpan={'4'}>{'UKUPNO:'}</td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.gorivo_ukupno).toFixed(2)}</td>
                  <td style={{ textAlign: 'right' }} colSpan={'4'}></td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className='number-of-rows'>Broj redova: {exportToSap.length}</div>
        </div>
        <div className="radni-nalog-table-form-footer">
          <div className="radni-nalog-table-form-footer-right">
            <button onClick={handleExportToSap}>Exportuj podatke</button>
          </div>
        </div>
      </div >
    </div>
  )
}

export default ExportToSap;