import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const vrstaRepromaterijalaSlice = createSlice({
  name: 'vrstaRepromaterijalaSliceReducer',
  initialState: initState,
  reducers: {
    addVrstaRepromaterijala: (state, action) => {
      state = action.payload;
      return state;
    }
  }
});

export const { addVrstaRepromaterijala } = vrstaRepromaterijalaSlice.actions;

export default vrstaRepromaterijalaSlice.reducer;