import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const parcelaSlice = createSlice({
  name: 'parcelaSliceReducer',
  initialState: initState,
  reducers: {
    addParcela: (state, action) => {
      state = action.payload;
      return state;
    },
    getParcela: (state, action) => {
      return state;
    },
  }
});

export const { addParcela, getParcela } = parcelaSlice.actions;

export default parcelaSlice.reducer;