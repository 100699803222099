import { createSlice } from '@reduxjs/toolkit';

const initState = [{
  // id: '',
  // operacija: 0,
  // parcela: 0,
  // masina: 0,
  // prikljucna_masina: 0,
  // repromaterijal: 0,
  // radnik: 0,
  // povrsina_parcele:0,
  // uradjeno: 0,
  // preostalo: 0,
  // komentar: 0,
  // status: 0
}];

const radniNalogSlice = createSlice({
  name: 'radniNalogSliceReducer',
  initialState: initState,
  reducers: {
    addRadniNalog: (state, action) => {
      state = action.payload;
      return state;
    },

  }
})

export const { addRadniNalog, selektovaniRed } = radniNalogSlice.actions;

export default radniNalogSlice.reducer;