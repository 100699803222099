import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SlClose } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import useHandleOnChangeCheckBox from '../../hooks/useHandleOnChangeCheckBox';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { deleteData, getData, selectStatementData } from '../../services/functions/server_functions/functions';
import { setWorking } from '../../redux/reducers/workingSlice';
import RepromaterijalIF from './RepromaterijalF';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import './Repromaterijal.css';

const Repromaterijal = () => {
  const [repromaterijal, setRepromaterijal] = useState([]);
  const [selectedRows, setSelectedRows, handleOnChangeCheckBox] = useHandleOnChangeCheckBox();
  const [editData, setEditData] = useState({ id: '', naziv: '', vrsta: '', podvrsta: '' });
  const [addEditData, setAddEditData] = useState('');
  const [showInputForm, setShowInputForm] = useState(false);
  const [podvrstaRepromaterijala, setPodvrstaRepromaterijala] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchRepromaterijal();
  }, [])

  const fetchRepromaterijal = async () => {
    try {
      dispatch(setWorking(true));
      setSelectedRows([]);
      const repromaterijalData = await selectStatementData(`SELECT r.id, r.naziv, r. vrsta_id, vr.naziv as vrsta_repromaterijala_naziv, r.podvrsta_id, pr.naziv as podvrsta_repromaterijala_naziv, r.jm FROM repromaterijal r JOIN vrsta_repromaterijala vr ON r.vrsta_id = vr.id JOIN podvrsta_repromaterijala pr on r.podvrsta_id = pr.id`);
      setRepromaterijal(repromaterijalData.data);
      const podvrstaRepromaterijalaData = await getData({ tableName: 'podvrsta_repromaterijala', whereCondition: '1 = 1' });
      setPodvrstaRepromaterijala(podvrstaRepromaterijalaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handlePodvrstaRepromaterijalaChange = async (e) => {
    const repromaterijalData = await selectStatementData(`SELECT r.id, r.naziv, r. vrsta_id, vr.naziv as vrsta_repromaterijala_naziv, r.podvrsta_id, pr.naziv as podvrsta_repromaterijala_naziv, r.jm FROM repromaterijal r JOIN vrsta_repromaterijala vr ON r.vrsta_id = vr.id JOIN podvrsta_repromaterijala pr on r.podvrsta_id = pr.id WHERE pr.id = COALESCE(${e.target.value}, pr.id)`);
    setRepromaterijal(repromaterijalData.data);
    setSearch('');
  }

  const handleAdd = () => {
    setAddEditData('add');
    setEditData({ id: '', naziv: '', vrsta: 0, podvrsta: 0, jm: '', oj: 0 });
    setShowInputForm(true);
  }

  const handleEdit = () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati repromaterijal za izmenu.', icon: 'warning', showHide: true })); return; }
    setAddEditData('edit');
    const id = parseInt(selectedRows[selectedRows.length - 1]);
    const naziv = repromaterijal.filter((op) => (op.id == id));
    setEditData({ id: `${id}`, naziv: `${naziv[0].naziv}`, vrsta: `${naziv[0].vrsta_id}`, podvrsta: `${naziv[0].podvrsta_id}`, jm: `${naziv[0].jm}`, oj: 0 });
    setShowInputForm(true);
  }

  const handleDelete = async () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati repromaterijal za brisanje.', icon: 'warning', showHide: true })); return; }
    try {
      dispatch(setWorking(true));
      const returnedData = await deleteData({ tableName: 'repromaterijal', whereCondition: `id in (${selectedRows.toString()})` })
      dispatch(setMessage({ msgText: 'Selektovani Repromaterijal je obrisan.', icon: 'success', showHide: true }));
      fetchRepromaterijal();
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
    finally {
      dispatch(setWorking(false));
    }
  }

  if (showInputForm) return <RepromaterijalIF showForm={setShowInputForm} fetchRepromaterijal={fetchRepromaterijal} editData={editData} addEditData={addEditData} />;

  return (
    <div className='repromaterijal'>
      <div className='table-form'>
        <div className="table-form-header">Pregled repromaterijala<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="table-form-main">
          <div className="repromaterijal-search-container">
            <div className="input-field">
              <label htmlFor="">Podvrsta repromaterijala</label>
              <select name="podvrstaRepromaterijala" value={podvrstaRepromaterijala.id} onChange={(e) => handlePodvrstaRepromaterijalaChange(e)}>
                <option key={0} value='null'>-----</option>
                {podvrstaRepromaterijala.map((vr) => {
                  return (
                    <option key={vr.id} value={vr.id}>{vr.naziv}</option>
                  )
                })}
              </select>
            </div>
            <div className="input-field">
              <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
            <IoIosCloseCircleOutline className='clear-search' onClick={() => setSearch('')} />
          </div>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Naziv</th>
                  <th>Vrsta</th>
                  <th>Podvrsta</th>
                  <th>JM</th>
                </tr>
              </thead>
              <tbody>
                {repromaterijal.filter((r) => {
                  if (!search) return true;
                  if (r.naziv.toLowerCase().includes(search.toLowerCase())) return true;
                  return false;
                }).map((data, index) => {
                  return (
                    <tr key={index}>
                      <td><input type="checkbox" name={data.id} onChange={handleOnChangeCheckBox} /></td>
                      <td>{data.id}</td>
                      <td>{data.naziv}</td>
                      <td>{data.vrsta_repromaterijala_naziv}</td>
                      <td>{data.podvrsta_repromaterijala_naziv}</td>
                      <td>{data.jm}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          </div>
          <div className='number-of-rows'>Broj redova: {repromaterijal.length}</div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleAdd}>Dodaj</button>
          <button onClick={handleEdit}>Izmeni</button>
          <button onClick={handleDelete}>Obriši</button>
        </div>
      </div >
    </div >
  )
}

export default Repromaterijal;