export const execProcedure = async (url, procedureName, procedureParams) => {
  try {
    const result = await fetch(`https://rnalogapi.cs-dev.rs/${url}`,
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ procedureName: procedureName, procedureParams: procedureParams })
      });
    const data = await result.json();
    return data;
  } catch (error) {
    return error;
  }
}

export const execInsertProcedureWithoutUrl = async (procedureName, procedureParams) => {
  try {
    const result = await fetch('https://rnalogapi.cs-dev.rs/execInsertProcedureWithoutUrl',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ procedureName: procedureName, procedureParams: procedureParams })
      });
    const data = await result.json();
    return data;
  } catch (error) {
    return error;
  }
}

export const getData = async ({ tableName, whereCondition }) => {
  try {
    const result = await fetch('https://rnalogapi.cs-dev.rs/retrieveAnyDataFromDBTable',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ tableName: `${tableName}`, whereCondition: `${whereCondition}` })
      });
    const data = await result.json();
    return data;
  } catch (error) {
    return error;
  }
}

export const editData = async ({ tableName, setFields, whereCondition }) => {
  try {
    const result = await fetch('https://rnalogapi.cs-dev.rs/updateDataFromDBTable',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ tableName: `${tableName}`, setFields: `${setFields}`, whereCondition: `${whereCondition}` })
      });
    return await result.json();
  } catch (error) {
    return error;
  }
}

export const deleteData = async ({ tableName, whereCondition }) => {
  try {
    const result = await fetch('https://rnalogapi.cs-dev.rs/deleteDataFromDBTable',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ tableName: `${tableName}`, whereCondition: `${whereCondition}` })
      });
    return await result.json();
  } catch (error) {
    return error;
  }
}

export const selectStatementData = async (selectStatement) => {
  try {
    const result = await fetch('https://rnalogapi.cs-dev.rs/selectOnTextBase',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ selectSentence: selectStatement })
      });
    const data = await result.json();
    return data;
  } catch (error) {
    return error;
  }
}
