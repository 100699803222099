import { useEffect, useState } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { execProcedure, selectStatementData } from '../../services/functions/server_functions/functions';
import './OperacijaMasina.css';

const OperacijaMasina = () => {
  const [operacija, setOperacija] = useState([]);
  const [resultDataMasine, setResultDataMasine] = useState([]);
  const [selectedOperacija, setSelectedOperacija] = useState({ operacija: 0, oj: 0 });
  const [oj, setOj] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    const fetchOperacija = async () => {
      try {
        dispatch(setWorking(true));
        const ojData = await selectStatementData(`SELECT oj.id, oj.naziv FROM organizaciona_jedinica oj`);
        setOj(ojData.data);
        const operacijeData = await selectStatementData(`SELECT o.id, o.naziv FROM operacija o`);
        setOperacija(operacijeData.data);
        setSelectedOperacija({ operacija: operacijeData.data ? operacijeData.data[0].id : 0, oj: ojUser.authUser.oj_id });
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      } finally {
        dispatch(setWorking(false));
      }
    }
    fetchOperacija();
  }, [])

  useEffect(() => {
    handleOperacijaChange();
  }, [selectedOperacija])

  const handleOperacijaChange = async () => {
    try {
      dispatch(setWorking(true));
      const masineData = await execProcedure('getOperacijaMasina', 'getOperacijaMasina', { operacija_id: selectedOperacija.operacija, oj: selectedOperacija.oj, koristi_masinu_oj_id: ojUser.authUser.oj_id });
      setResultDataMasine(masineData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleCheckedChange = (e) => {
    const editDataMasine = resultDataMasine.map((masina) => {
      if (masina.id === Number.parseInt(e.target.name))
        return { ...masina, checked: !masina.checked }
      else
        return masina;
    })
    setResultDataMasine(editDataMasine);
  }

  const handleSaveChanges = async () => {
    try {
      let parametar = selectedOperacija.operacija;
      let ostatak = '';
      resultDataMasine.forEach((masina) => {
        if (masina.checked) { ostatak += ','; ostatak += masina.id; }
      })
      parametar += ostatak;

      await execProcedure('updateOperacijaMasina', 'updateOperacijaMasina', { array_of_id: parametar, oj_id: selectedOperacija.oj, koristi_masinu_oj_id: ojUser.authUser.oj_id });
      dispatch(setMessage({ msgText: 'Podaci operacija / mašina su snimljeni.', icon: 'success', showHide: true }));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className="operacija-masina">
      <div className='table-form'>
        <div className="table-form-header">Operacija / Mašina<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="operacija-masina-wrapper">
          <div className="operacija-masina-left">
            <div className="table-form">
              <div className="table-form-main">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv operacije</th>
                      </tr>
                    </thead>
                    <tbody>
                      {operacija.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="radio" name='operacija' checked={data.id === selectedOperacija.operacija} onChange={() => { setSelectedOperacija({ ...selectedOperacija, operacija: data.id }); }} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {operacija.length}</div>
              </div>
            </div>
          </div>
          <div className="operacija-masina-right">
            <div className="table-form">
              <div className="table-form-main">
                <div className="input-field">
                  <label htmlFor="">Organizaciona jedinica</label>
                  <select value={selectedOperacija.oj} onChange={(e) => { setSelectedOperacija({ ...selectedOperacija, oj: e.target.value }); }}>
                    <option value="null">-----</option>
                    {oj && oj.map((o) => {
                      return (
                        <option key={o.id} value={o.id}>{o.naziv}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv</th>
                        <th>Registracija</th>
                        <th>OJ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultDataMasine.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="checkbox" name={data.id} checked={data.checked} onChange={handleCheckedChange} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                            <td>{data.registracija}</td>
                            <td>{data.naziv_oj}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {resultDataMasine.length}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleSaveChanges}>Snimi</button>
        </div>
      </div>
    </div>
  )
}

export default OperacijaMasina;