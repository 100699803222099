import { useEffect, useState } from 'react';
import './DropDaownList.css';

const DropDownList = ({ labelCaption, name, value, onChange, dataList, fieldToShow, disabledSelect }) => {
  const [showSelect, setShowSelect] = useState(true);

  useEffect(() => {
    if (disabledSelect === 'preview') { setShowSelect(false); }
    if (disabledSelect === 'edit') {
      if (name === 'operacija' || name === 'parcela')
        setShowSelect(false);
    }
  }, [])



  // console.log(showSelect);
  return (
    <div className="input-field">
      <label htmlFor="">{labelCaption}</label>
      <div className="select-wrapper">
        <select disabled={!showSelect} name={name} value={value} onChange={onChange}>
          <option key={0} value={0}>-----</option>
          {dataList.map((data) => {
            return (
              <option key={data.id} value={data.id}>{data[fieldToShow]}</option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export default DropDownList;