import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const prikljucnaMasinaSlice = createSlice({
  name: 'prikljucnaMasinaSliceReducer',
  initialState: initState,
  reducers: {
    addPrikljucnaMasina: (state, action) => {
      state = action.payload;
      return state;
    },
  }
});

export const { addPrikljucnaMasina } = prikljucnaMasinaSlice.actions;

export default prikljucnaMasinaSlice.reducer;