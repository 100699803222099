import { useState } from "react";
import { useSelector } from "react-redux";
import { execProcedure } from "../services/functions/server_functions/functions";

export const usePromeniDatum = () => {
  const [brojNaloga, setBrojNaloga] = useState();
  const oj_id = useSelector((state) => state.user);

  const promeniDatum = async (datum) => {
    if (datum.length < 10) {
      setBrojNaloga(null);
    } else {
      const formatedDate = `${datum.slice(8)}-${datum.slice(5, 7)}-${datum.slice(0, 4)}`;
      const resBrojNaloga = await execProcedure('getIdNaloga', 'getIdNaloga', { formatedDate: formatedDate, oj_id: oj_id.authUser.oj_id });
      setBrojNaloga(resBrojNaloga.data[0].rn_id);
    }
  }

  return [brojNaloga, promeniDatum];
}