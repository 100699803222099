import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import './RadnikIf.css';
import '../../assets/inputForm.css';
import { selectStatementData } from '../../services/functions/server_functions/functions';

const RadnikIF = ({ showForm, fetchRadnik, editData, addEditData }) => {
  const [radnik, setRadnik] = useState({ id: editData.id, prezime: editData.prezime, ime: editData.ime, oj: editData.oj });
  const [oj, setOj] = useState([]);
  const [oldId] = useState(editData.id);
  const dispatch = useDispatch();
  const idRef = useRef();
  const nazivRef = useRef();
  // const oj = useSelector((state) => state.oj);

  useEffect(() => {
    if (addEditData === 'add')
      idRef.current.focus();
    else
      nazivRef.current.focus();

    const fetchData = async () => {
      try {
        const ojData = await selectStatementData(`SELECT oj.id, oj.naziv from organizaciona_jedinica oj`);
        setOj(ojData.data);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    fetchData();
  }, [])

  const handleSave = async () => {
    if (radnik.id === '' || radnik.naziv === '' || radnik.oj === 0) { dispatch(setMessage({ msgText: 'Nisu popunjena sva obavezna polja.', icon: 'warning', showHide: true })); return };
    if (addEditData === 'edit') {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `update radnik set id = '${radnik.id}', prezime = '${radnik.prezime}', ime = '${radnik.ime}', oj_id = ${radnik.oj}  WHERE id = ${oldId}` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchRadnik();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    else {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `insert into radnik values(${radnik.id}, '${radnik.prezime}', '${radnik.ime}', ${radnik.oj} )` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchRadnik();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
  }

  const handleCancelSave = () => {
    fetchRadnik();
    showForm(false)
  }

  return (
    <div className='radnik-if'>
      <div className='input-form'>
        <div className="title-bar">Radnik</div>
        <div className="input-field">
          <label htmlFor="">ID</label>
          <input className='radnik-id' type="number" name="" ref={idRef} value={radnik.id} onChange={(e) => setRadnik({ ...radnik, id: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Prezime</label>
          <input type="text" name="" ref={nazivRef} value={radnik.prezime} onChange={(e) => setRadnik({ ...radnik, prezime: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Ime</label>
          <input type="text" name="" value={radnik.ime} onChange={(e) => setRadnik({ ...radnik, ime: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">OJ</label>
          <select name="" value={radnik.oj} onChange={(e) => setRadnik({ ...radnik, oj: e.target.value })}>
            <option value="0">-----</option>
            {oj.map((oj) => {
              return (
                <option key={oj.id} value={oj.id}>{oj.naziv}</option>
              )
            })}
          </select>
        </div>
        <footer>
          <button onClick={handleSave}>Snimi</button>
          <button onClick={handleCancelSave}>Odustani</button>
        </footer>
      </div>
    </div>
  )
}

export default RadnikIF;