import { useDispatch } from 'react-redux';
import './MessageWindow.css';

const MessageWindow = ({ children }) => {

  return (
    <>
      <div className="overlay">
        <div className='message-window'>
          {children}
        </div>
      </div>
    </>
  )
}

export default MessageWindow;