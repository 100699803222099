import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import ForgotPassword from './pages/forgot_password/ForgotPassword';
import MainAppPage from './pages/main_app_page/MainAppPage';
import MainAppArea from './components/MainAppArea';
import RadniNalog from './pages/radni_nalog/RadniNalog';
import VrstaMasine from './pages/vrsta_masine/VrstaMasine';
import Operacija from './pages/operacija/Operacija';
import Parcela from './pages/parcela/Parcela';
import Masina from './pages/masina/Masina';
import PrikljucnaMasina from './pages/prikljucna_masina/PrikljucnaMasina';
import Repromaterijal from './pages/repromaterijal/Repromaterijal';
import KulturaHemija from './pages/kultura_hemija/KulturaHemija';
import Kultura from './pages/kultura/Kultura';
import Radnik from './pages/radnik/Radnik';
import RadnikMasina from './pages/radnik_masina/RadnikMasina';
import MasinaPrikljucnaMasina from './pages/masina_prikljucna_masina/MasinaPrikljucnaMasina';
import OperacijaMasina from './pages/operacija_masina/OperacijaMasina';
import MessageWindow from './components/MessageWindow/MessageWindow';
import OkMessage from './components/OkMessage/OkMessage';
import PromeniSifru from './pages/promeni_sifru/PromeniSifru';
import IzaberiOj from './pages/izaberi_oj/IzaberiOj';
// import useMessageDispatch from './radni_nalog_hooks/useMessageDispatch';
import RegisterAdmin from './pages/register_admin/RegisterAdmin';
import LandingPage from './pages/landing_page/LandingPage';
// import { setMessage } from './redux/reducers/showMessageWindowSlice';

import './assets/global.css';
import './assets/tableForm.css';
import './App.css';
import Working from './components/working/Working';
import OperacijaRepromaterijal from './pages/operacija_repromaterijal/OperacijaRepromaterijal';
import { useEffect, useRef, useState } from 'react';
import { useInitialLoad } from './radni_nalog_hooks/useInitialLoad';
import OperacijaPrikljucnaMasina from './pages/operacija_prikljucna_masina/OperacijaPrikljucnaMasina';
import { setWorking } from './redux/reducers/workingSlice';
import { setMessage } from './redux/reducers/showMessageWindowSlice';
import NoInternetConnection from './pages/no_internet_connection/NoInternetConnection';
import PromeniStatusNaloga from './pages/promeni_status_naloga/PromeniStatusNaloga';
import PromeniOj from './pages/promeni_oj/PromeniOj';
import ExportToSap from './pages/izvestaji/export_to_sap/ExportToSap';
import RasknjizavanjeRepromaterijala from './pages/izvestaji/rasknjizavanje_repromaterijala/RasknjizavanjeRepromaterijala';
import RasknjizavanjeGoriva from './pages/izvestaji/rasknjizavanje_goriva/RasknjizavanjeGoriva';
import UtrosakHemije from './pages/izvestaji/utrosak_hemije/UtrosakHemije';
import OperacijaMRT from './pages/operacija/OperacijaMRT';

const App = () => {
  const messageWindow = useSelector((state) => state.showMessageWindowSlice);
  const working = useSelector((state) => state.working);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const noConnectionRef = useRef(false)
  const dispatch = useDispatch();

  useEffect(() => {
    const handleInternetConnectionChange = () => {
      setIsOnline(navigator.onLine);
    }
    window.addEventListener('offline', handleInternetConnectionChange);
    window.addEventListener('online', handleInternetConnectionChange);

    return () => {
      window.removeEventListener('offline', handleInternetConnectionChange);
      window.removeEventListener('online', handleInternetConnectionChange);
    }
  }, [navigator.onLine])

  return (
    <div className='app' data-theme='theme1' style={{ width: window.innerWidth, height: window.innerHeight }}>
      <BrowserRouter>
        <Routes>
          {/* <Route exact path="/" element={<Login setIsLogged={setIsLogged} />} /> */}
          <Route exact path="/" element={<Login />} />
          {/* <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} /> */}
          {1 === 1 ? <Route path='/main' element={<MainAppPage />}>
            <Route element={<MainAppArea />} >
              <Route path='landingpage' element={<LandingPage />} />
              <Route path='radninalog' element={<RadniNalog />} />
              <Route path='operacija' element={<Operacija />} />
              <Route path='parcela' element={<Parcela />} />
              <Route path='vrstamasine' element={<VrstaMasine />} />
              <Route path='masina' element={<Masina />} />
              <Route path='prikljucnamasina' element={<PrikljucnaMasina />} />
              <Route path='repromaterijal' element={<Repromaterijal />} />
              <Route path='kultura' element={<Kultura />} />
              <Route path='radnik' element={<Radnik />} />
              <Route path='operacijamasina' element={<OperacijaMasina />} />
              <Route path='masinaprikljucnamasina' element={<MasinaPrikljucnaMasina />} />
              <Route path='operacijaprikljucnamasina' element={<OperacijaPrikljucnaMasina />} />
              <Route path='radnikmasina' element={<RadnikMasina />} />
              <Route path='operacijarepromaterijal' element={<OperacijaRepromaterijal />} />
              <Route path='kulturahemija' element={<KulturaHemija />} />
              <Route path='exportusap' element={<ExportToSap />} />
              <Route path='rasknjizavanjerepromaterijala' element={<RasknjizavanjeRepromaterijala />} />
              <Route path='rasknjizavanjegoriva' element={<RasknjizavanjeGoriva />} />
              <Route path='utrosakhemije' element={<UtrosakHemije />} />
              {/* <Route path='register' element={<Register />} /> */}
              <Route path='promenisifru' element={<PromeniSifru />} />
              <Route path='kreirajkorisnika' element={<RegisterAdmin />} />
              <Route path='promenistatusnaloga' element={<PromeniStatusNaloga />} />
              <Route path='promenioj' element={<PromeniOj />} />
              <Route path='landingpage' element={<LandingPage />} />
            </Route>
          </Route> : null}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      {messageWindow.showHide && <MessageWindow ><OkMessage /></MessageWindow>}
      {working.working && <Working />}
      {/* {noConnectionRef.current && <NoInternetConnection />} */}
      {/* {!isOnline && <NoInternetConnection />} */}
    </div>
  );
}

export default App;
