import { useEffect, useState } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { execProcedure, selectStatementData } from '../../services/functions/server_functions/functions';
import './RadnikMasina.css';

const RadnikMasina = () => {
  const [radnik, setRadnik] = useState([]);
  const [resultDataMasine, setResultDataMasine] = useState([]);
  const [selectedRadnik, setSelectedRadnik] = useState({ radnik: 0, oj: 0 });
  const [oj, setOj] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    const fetchRadnik = async () => {
      try {
        dispatch(setWorking(true));
        const ojData = await selectStatementData(`SELECT oj.id, oj.naziv FROM organizaciona_jedinica oj`);
        setOj(ojData.data);
        const radnikData = await selectStatementData(`SELECT r.id, r.oj_id, r.prezime, r.ime, concat(r.prezime, ' ', r.ime) as prezime_ime, oj.naziv as naziv_oj FROM radnik r JOIN organizaciona_jedinica oj ON r.oj_id = oj.id WHERE r.oj_id = ${ojUser.authUser.oj_id}`);
        setRadnik(radnikData.data);
        setSelectedRadnik({ radnik: radnikData.data ? radnikData.data[0].id : 0, oj: ojUser.authUser.oj_id });
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      } finally {
        dispatch(setWorking(false));
      }
    }
    fetchRadnik();
  }, [])

  useEffect(() => {
    handleRadnikChange();
  }, [selectedRadnik])

  const handleRadnikChange = async () => {
    try {
      dispatch(setWorking(true));
      const masineData = await execProcedure('getRadnikMasina', 'getRadnikMasina', { radnik_id: selectedRadnik.radnik, oj: selectedRadnik.oj });
      setResultDataMasine(masineData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }

  }

  const handleCheckedChange = (e) => {
    const editDataMasine = resultDataMasine.map((masina) => {
      if (masina.id === Number.parseInt(e.target.name))
        return { ...masina, checked: !masina.checked }
      else
        return masina;
    })
    setResultDataMasine(editDataMasine);
  }

  const handleSaveChanges = async () => {
    try {
      let parametar = selectedRadnik.radnik;
      let ostatak = '';
      resultDataMasine.forEach((masina) => {
        if (masina.checked) { ostatak += ','; ostatak += masina.id; }
      })
      parametar += ostatak;

      await execProcedure('updateRadnikMasina', 'updateRadnikMasina', { array_of_id: parametar, oj_id: selectedRadnik.oj, koristi_masinu_oj_id: ojUser.authUser.oj_id });
      dispatch(setMessage({ msgText: 'Podaci radnik / mašina su snimljeni.', icon: 'success', showHide: true }));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className="radnik-masina">
      <div className="table-form">
        <div className="table-form-header">Radnik / Mašina<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="radnik-masina-wrapper">
          <div className="radnik-masina-left">
            <div className="table-form">
              <div className="table-form-main">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Prezime</th>
                        <th>Ime</th>
                        <th>OJ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {radnik.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="radio" name='radnik' checked={data.id === selectedRadnik.radnik} onChange={() => { setSelectedRadnik({ ...selectedRadnik, radnik: data.id }); }} /></td>
                            <td>{data.id}</td>
                            <td>{data.prezime}</td>
                            <td>{data.ime}</td>
                            <td>{data.naziv_oj}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {radnik.length}</div>
              </div>
            </div>
          </div>
          <div className="radnik-masina-right">
            <div className="table-form">
              <div className="table-form-main">
                <div className="input-field">
                  <label htmlFor="">Organizaciona jedinica</label>
                  <select value={selectedRadnik.oj} onChange={(e) => { setSelectedRadnik({ ...selectedRadnik, oj: e.target.value }); }}>
                    <option value="null">-----</option>
                    {oj && oj.map((o) => {
                      return (
                        <option key={o.id} value={o.id}>{o.naziv}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv</th>
                        <th>Registracija</th>
                        <th>OJ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultDataMasine.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="checkbox" name={data.id} checked={data.checked} onChange={handleCheckedChange} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                            <td>{data.registracija}</td>
                            <td>{data.naziv_oj}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {resultDataMasine.length}</div>
              </div >
            </div>
          </div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleSaveChanges}>Snimi</button>
        </div>
      </div>
    </div >
  )
}

export default RadnikMasina;