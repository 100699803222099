import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as XLSX from 'xlsx';
import { customStyles } from '../../assets/selectCustomStyles';
import { SlClose } from 'react-icons/sl';
import { ImSigma } from 'react-icons/im';
import excel from '../../images/excel.jpg';
import search from '../../images/search.png';
import { deleteData, execProcedure, selectStatementData } from '../../services/functions/server_functions/functions';

// import { addRadniNalog } from '../../redux/reducers/radniNalogSlice';
import { setAddValues, setEditValues, updateValues } from '../../redux/reducers/addEditRadniNalog';
import { useInitialLoad } from '../../radni_nalog_hooks/useInitialLoad';
import { setRadniNalogFilter } from '../../redux/reducers/filterRadniNalogCriteriaSlice';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';

import RadniNalogIF from './RadniNalogIF';
import filterIcon from '../../images/filter.ico';
import details from '../../images/details3.png';
import useHandleOnChangeCheckBox from '../../hooks/useHandleOnChangeCheckBox';
import useRadniNalogRefresh from '../../radni_nalog_hooks/useRadniNalogRefresh';
import RepromaterijalDetails from '../../components/repromaterijal_details/RepromaterijalDetails';
import UtrosakGorivaIRepromaterijala from '../utrosak_goriva_i_repromaterijala/UtrosakGorivaIRepromaterijala';
import RadniNalogPreview from './RadniNalogPreview';
import './RadniNalog.css';

const RadniNalog = () => {
  const [selectedRows, setSelectedRows, handleOnChangeCheckBox] = useHandleOnChangeCheckBox();
  const [addEditData, setAddEditData] = useState('');
  const [showInputForm, setShowInputForm] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showPreviewForm, setShowPreviewForm] = useState(false);
  const [selectedRepromaterijalForFilter, setSelectedRepromaterijalForFilter] = useState({ value: null, label: '-----' });
  const [datumKopiranja, setDatumKopiranja] = useState((new Date().toISOString().substring(0, 10)));
  // const [repromaterijalStavke, setRepromaterijalStavke] = useState([]);
  const [showRepromaterijalDetail, setShowRepromaterijalDetail] = useState(false);
  const [showUtrosakGorivaIRepromaterijala, setShowUtrosakGorivaIRepromaterijala] = useState(false);
  const [ukupno, setUkupno] = useState({ povrsina_ukupno: 0, gorivo_ukupno: 0, gorivo_prosek: 0, gotova_roba_merkantilna_ukupno: 0, gotova_roba_semenska_ukupno: 0, gotova_roba_prosek: 0 });
  const [options, setOptions] = useState();
  const [refresh] = useRadniNalogRefresh();
  const showDetailRef = useRef('');

  const [initLoad] = useInitialLoad();
  const dispatch = useDispatch();
  const radniNalog = useSelector((state) => state.nalog);
  const operacija = useSelector((state) => state.operacija);
  const parcela = useSelector((state) => state.parcela);
  const masina = useSelector((state) => state.masina);
  const prikljucnaMasina = useSelector((state) => state.prikljucnaMasina);
  const repromaterijal = useSelector((state) => state.repromaterijal);
  const radnik = useSelector((state) => state.radnik);
  const addEditRadniNalog = useSelector((state) => state.addEditRadniNalog);
  const oj = useSelector((state) => state.user);
  const filterCriteria = useSelector((state) => state.filterRadniNalogCriteria);
  const navigate = useNavigate();

  useEffect(() => {
    initLoad();
    const tmpOptions = repromaterijal?.map(({
      id: value,
      naziv: label
    }) => ({
      value,
      label
    }));
    setOptions(tmpOptions);
  }, []);

  useEffect(() => {
    let povrsina_u = 0, gorivo_u = 0, merkantilna_roba_u = 0, semenska_roba_u = 0;

    radniNalog?.forEach((r) => {
      povrsina_u += r.povrsina;
      gorivo_u += r.natoceno;
      merkantilna_roba_u += r.gotova_roba_merkantilna;
      semenska_roba_u += r.gotova_roba_semenska;
    });

    setUkupno({
      povrsina_ukupno: povrsina_u,
      gorivo_ukupno: gorivo_u,
      gorivo_prosek: povrsina_u === 0 ? 0 : gorivo_u / povrsina_u,
      gotova_roba_merkantilna_ukupno: merkantilna_roba_u,
      gotova_roba_semenska_ukupno: semenska_roba_u,
      gotova_roba_prosek: (merkantilna_roba_u + semenska_roba_u) === 0 ? 0 : gorivo_u / (merkantilna_roba_u + semenska_roba_u)
    });
  }, [radniNalog])


  const viewRadniNalog = (e) => {
    setShowPreviewForm(true);
  }

  const handleCopy = async () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati radni nalog za kopiranje.', icon: 'warning', showHide: true })); return; }
    if (datumKopiranja.length < 10) { dispatch(setMessage({ msgText: 'Datum na koji kopirate radni nalog nije ispravan.', icon: 'warning', showHide: true })); return; }
    try {
      const radniNalogData = await execProcedure('copyRadniNalog', 'copyRadniNalog', {
        in_id: selectedRows[0],
        datum: `${datumKopiranja.slice(8)}-${datumKopiranja.slice(5, 7)}-${datumKopiranja.slice(0, 4)}`,
        oj: addEditRadniNalog.oj,
        operacija: addEditRadniNalog.operacija,
        parcela: addEditRadniNalog.parcela,
        status: addEditRadniNalog.status,
        korisnik: oj.authUser.email
      });
      // const new_rn = await handleCopyAndEdit(radniNalogData.message[0][0][0].rn_id);
      // await console.log(new_rn)
      // await dispatch(setEditValues(new_rn));
      // await setSelectedRows([radniNalogData.message[0][0][0].rn_id]);
      // const rn_id = [radniNalogData.message[0][0][0].rn_id];
      // const new_rn = radniNalog.filter((rn) => rn.id === rn_id)
      // console.log(radniNalog, new_rn)
      // console.log([radniNalogData.message[0][0][0].rn_id], radniNalog, addEditRadniNalog)
      // dispatch(setEditValues(new_rn))
      // dispatch(updateValues({ name: 'id', value: radniNalogData.message[0][0][0].rn_id }));
      dispatch(setMessage({ msgText: 'Radni nalog je kopiran.', icon: 'success', showHide: true }));
      // await handleEdit();
      await refresh();
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  const handleCopyAndEdit = async (rn_id) => {
    // await refresh();
    const new_rn = await radniNalog.filter((rn) => rn.id === rn_id);
    console.log(new_rn);
    return new_rn;
    // await dispatch(setEditValues(new_rn))
  }

  const handleExportToExcel = () => {
    const tmpRadniNalog = radniNalog.map(({
      id: id,
      operacija: operacija,
      parcela: parcela,
      povrsina: povrsina,
      uradjeno: uradjeno,
      preostalo: preotalo,
      natoceno: natoceno,
      prosek: prosek,
      masina: masina,
      prikljucna_masina: prikljucna_masina,
      prezime: prezime,
      ime: ime,
      registracija: registracija,
      datum: datum
    }) => ({
      id: id,
      operacija,
      parcela,
      povrsina,
      uradjeno,
      preotalo,
      natoceno,
      prosek,
      masina,
      prikljucna_masina,
      prezime,
      ime,
      registracija,
      datum
    }))
    const worksheet = XLSX.utils.json_to_sheet(tmpRadniNalog, { skipHeader: false });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Radni nalog => Excel");
    let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Export u Excel.xlsx");
  }

  const handleAdd = () => {
    setAddEditData('add');
    dispatch(setAddValues());
    dispatch(updateValues({ name: 'oj', value: oj.authUser.oj_id }));
    setShowInputForm(true);
  }

  const handleEdit = async () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati radni nalog za izmenu.', icon: 'warning', showHide: true })); return; }
    const res = radniNalog.filter((r) => r.id === selectedRows[0])[0].status;
    if (res != 0) { dispatch(setMessage({ msgText: 'Ne mogu se menjati zatvoreni nalozi.', icon: 'warning', showHide: true })); return; }
    setAddEditData('edit');
    setShowInputForm(true);
  }

  const handleDelete = async () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati radni nalog za brisanje.', icon: 'warning', showHide: true })); return; }
    const res = radniNalog.filter((r) => r.id === selectedRows[0])[0].status;
    if (res != 0) {
      dispatch(setMessage({ msgText: 'Ne mogu se brisati zatvoreni nalozi.', icon: 'warning', showHide: true })); return;
    }
    try {
      await deleteData({ tableName: 'radni_nalog', whereCondition: `id in ('${selectedRows.toString()}') and oj_id = ${oj.authUser.oj_id}` });
      await deleteData({ tableName: 'radni_nalog_repromaterijal', whereCondition: `radni_nalog_id in ('${selectedRows.toString()}') and oj_id = ${oj.authUser.oj_id}` });
      setSelectedRows([]);
      refresh();
      dispatch(setMessage({ msgText: 'Radni nalog je obrisan.', icon: 'success', showHide: true }));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  const handleFilterDatumChange = async (datum, datumName) => {
    if (datum !== null || datum !== '') {
      dispatch(setRadniNalogFilter({ name: datumName, value: datum }));
    }
    else {
      dispatch(setRadniNalogFilter({ name: datumName, value: '' }));
    }
  }

  const handleFilterChange = (e) => {
    dispatch(setRadniNalogFilter({ name: e.target.name, value: e.target.value === '0' ? null : e.target.value }));
  }

  const showDetail = (id) => {
    setShowRepromaterijalDetail((prevValue) => !prevValue);
    showDetailRef.current = id;
  }

  if (showInputForm) return <RadniNalogIF showForm={setShowInputForm} addEditData={addEditData} />;
  if (showPreviewForm) return <RadniNalogPreview setShowPreviewForm={setShowPreviewForm} />;

  return (
    <div className='radni-nalog-container'>
      <div className='radni-nalog-table-form'>
        <div className="radni-nalog-table-form-header">Pregled radnih naloga<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className='filter' style={{ display: showFilter ? 'flex' : 'none' }}>
          <div className='box-1'>
            <div className="input-field">
              <label htmlFor="">Od datuma</label>
              <input type="date"
                value={filterCriteria.datumOd}
                onChange={(e) => {
                  handleFilterDatumChange(e.target.value, 'datumOd');
                }}
              />
            </div>
            <div className="input-field">
              <label htmlFor="">Do datuma</label>
              <input type="date"
                value={filterCriteria.datumDo}
                onChange={(e) => {
                  handleFilterDatumChange(e.target.value, 'datumDo');
                }}
              />
            </div>
          </div>
          <div className='box-2'>
            <div className="input-field">
              <label htmlFor="">Operacija</label>
              <select name="operacija" value={filterCriteria.operacija} onChange={(e) => handleFilterChange(e)} >
                <option key={0} value={0}>-----</option>
                {operacija.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>{data.naziv}</option>
                  )
                })}
              </select>
            </div>
            <div className="input-field">
              <label htmlFor="">Parcela</label>
              <select name="parcela" value={filterCriteria.parcela} onChange={(e) => handleFilterChange(e)}>
                <option key={0} value={0}>-----</option>
                {parcela?.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>{data.naziv}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='box-3'>
            <div className="input-field">
              <label htmlFor="">Mašina</label>
              <select name="masina" value={filterCriteria.masina} onChange={(e) => handleFilterChange(e)}>
                <option key={0} value={0}>-----</option>
                {masina.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>{data.registracija} ({data.naziv})</option>
                  )
                })}
              </select>
            </div>
            <div className="input-field">
              <label htmlFor="">Priključna mašina</label>
              <select name="prikljucnaMasina" value={filterCriteria.prikljucnaMasina} onChange={(e) => handleFilterChange(e)}>
                <option key={0} value={0}>-----</option>
                {prikljucnaMasina.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>{data.naziv}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='box-4'>
            <div className="input-field">
              <label htmlFor="">Repromaterijal</label>
              <Select className='select'
                value={selectedRepromaterijalForFilter}
                options={options}
                placeholder={''}
                noOptionsMessage={() => 'Nema podataka.'}
                isClearable
                isSearchable
                styles={customStyles}
                onChange={(e) => {
                  dispatch(setRadniNalogFilter({ name: 'repromaterijal', value: e === null ? null : e.value }));
                  setSelectedRepromaterijalForFilter({ value: e === null ? null : e.value, label: e === null ? '-----' : e.label });
                }}
              />
            </div>
            <div className="input-field">
              <label htmlFor="">Radnik</label>
              <select name="radnik" value={filterCriteria.radnik} onChange={(e) => handleFilterChange(e)}>
                <option key={0} value={0}>-----</option>
                {radnik.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>{data.prezime} {data.ime}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='box-5'>
            <div className="input-field">
              <label htmlFor="">Status</label>
              <select name="status" value={filterCriteria.status} onChange={(e) => {
                dispatch(setRadniNalogFilter({ name: 'status', value: e.target.value }));
              }}>
                <option key={0} value={0}>-----</option>
                <option key={1} value={1}>Otvoreni</option>
                <option key={2} value={2}>Redovno zatvoreni</option>
                <option key={3} value={3}>Prevremeno zatvoreni</option>
              </select>
            </div>
            <button onClick={() => {
              refresh()
            }
            }>Filtriraj</button>
          </div>
        </div>
        <div className="radni-nalog-table-form-main">
          <div className='filter-i-utrosci'>
            <img className='filter-icon' src={filterIcon} onClick={() => { setShowFilter(!showFilter) }} />
            <button onClick={() => setShowUtrosakGorivaIRepromaterijala(true)}><ImSigma />  Utrošci</button>
          </div>
          <div className="radni-nalog-table-form-main-table-wrapper">
            {showUtrosakGorivaIRepromaterijala && <UtrosakGorivaIRepromaterijala setShowUtrosakGorivaIRepromaterijala={setShowUtrosakGorivaIRepromaterijala} />}
            <table className='tabela'>
              <thead>
                <tr>
                  <th>#</th>
                  <th></th>
                  <th></th>
                  <th>Broj rn</th>
                  <th>Operacija</th>
                  <th>Parcela / mesto troška</th>
                  <th>Površina</th>
                  <th>Urađeno</th>
                  <th>Preostalo</th>
                  <th>Natočeno g.</th>
                  <th>Prosek g.</th>
                  <th>Merk. roba</th>
                  <th>Sem. roba</th>
                  <th>Prosek g. gr</th>
                  <th>Registracija</th>
                  <th>Mašina</th>
                  <th>Priključna mašina</th>
                  <th>Radnik</th>
                </tr>
              </thead>
              <tbody>
                {radniNalog?.map((data, index) => {
                  return (
                    <>
                      <tr key={index} onClick={() => dispatch(setEditValues(data))}>
                        <td><input type="checkbox" name={data.id} onChange={(e) => handleOnChangeCheckBox(e)} /></td>
                        <td><img className='preview' src={search} alt="" onClick={() => (viewRadniNalog(index))} /></td>
                        {data.repromaterijal_stavke?.length >= 1 ? <td><img src={details} ref={showDetailRef} style={{ color: '#16AAFF' }} alt="" onClick={() => showDetail(data.id)} /></td> : <td></td>}
                        <td style={{ backgroundColor: data.status === 1 ? 'lightgreen' : data.status === 2 ? 'green' : null }}>{data.id}</td>
                        <td>{data.operacija}</td>
                        <td>{data.parcela}</td>
                        <td style={{ textAlign: 'right', backgroundColor: '#caf0f8' }}>{(data.povrsina)?.toFixed(2)}</td>
                        <td style={{ textAlign: 'right', backgroundColor: '#caf0f8' }}>{(data.uradjeno)?.toFixed(2)}</td>
                        <td style={{ textAlign: 'right', backgroundColor: '#caf0f8' }}>{(data.preostalo)?.toFixed(2)}</td>
                        <td style={{ textAlign: 'right', backgroundColor: '#fcf6bd' }}>{(data.natoceno)?.toFixed(2)}</td>
                        <td style={{ textAlign: 'right', backgroundColor: '#fcf6bd' }}>{(data.prosek)?.toFixed(2)}</td>
                        <td style={{ textAlign: 'right', backgroundColor: '#ffdab9' }}>{data.gotova_roba_merkantilna}</td>
                        <td style={{ textAlign: 'right', backgroundColor: '#ffdab9' }}>{data.gotova_roba_semenska}</td>
                        <td style={{ textAlign: 'right', backgroundColor: '#ffdab9' }}>{(data.prosek_gotova_roba)?.toFixed(2)}</td>
                        <td>{data.registracija}</td>
                        <td>{data.masina}</td>
                        <td>{data.prikljucna_masina}</td>
                        <td>{data.prezime} {data.ime}</td>
                      </tr>
                      {data.repromaterijal_stavke?.length > 0 ?
                        <tr style={{ display: showDetailRef.current === data.id && showRepromaterijalDetail === true ? 'table-row' : 'none' }}>
                          <td colSpan='3' ></td>
                          <td colSpan='14' >
                            <RepromaterijalDetails repromaterijal_stavke={data.repromaterijal_stavke} />
                          </td>
                        </tr>
                        : null
                      }
                    </>
                  )
                })
                }
              </tbody>
              <tfoot style={{ backgroundColor: 'crimson', color: 'white', fontWeight: '500' }}>
                <tr>
                  <td style={{ textAlign: 'right' }} colSpan={'6'}>{'UKUPNO:'}</td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.povrsina_ukupno).toFixed(2)}</td>
                  <td style={{ textAlign: 'right' }} colSpan={'2'}></td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.gorivo_ukupno).toFixed(2)}</td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.gorivo_prosek).toFixed(2)}</td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.gotova_roba_merkantilna_ukupno)}</td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.gotova_roba_semenska_ukupno)}</td>
                  <td style={{ textAlign: 'right' }}>{(ukupno?.gotova_roba_prosek).toFixed(2)}</td>
                  <td style={{ textAlign: 'right' }} colSpan={'4'}></td>
                </tr>
              </tfoot>

            </table>
          </div>
          <div className='number-of-rows'>Broj redova: {radniNalog.length}</div>
        </div>
        <div className="radni-nalog-table-form-footer">
          <div className="radni-nalog-table-form-footer-left">
            <button onClick={handleCopy}>Kopiraj nalog</button>
            <div className='input-field'>
              <input type='date' value={datumKopiranja} onChange={(e) => setDatumKopiranja(e.target.value)} />
            </div>
          </div>
          <div className="radni-nalog-table-form-footer-right">
            {oj.authUser.pravo_id === 1 || oj.authUser.pravo_id === 2 ? <button className='radni-nalog-export-to-excel' onClick={handleExportToExcel}>Export u Excel</button> : null}
            <button onClick={handleAdd}>Kreiraj nalog</button>
            <button onClick={handleEdit}>Izmeni nalog</button>
            <button onClick={handleDelete}>Obriši nalog</button>
          </div>
        </div>
      </div >
    </div>
  )
}

export default RadniNalog;