import { useRef } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { execProcedure } from '../../services/functions/server_functions/functions';

import './PromeniStatusNaloga.css';

const PromeniStatusNaloga = () => {
  const brojNalogaRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const oj_id = useSelector((state) => state.user);

  const handlePromeniStatusNaloga = async () => {
    if (!brojNalogaRef.current.value) {
      dispatch(setMessage({ msgText: 'Nije unet broj naloga.', icon: 'warning', showHide: true })); return;
    }
    try {
      const response = await execProcedure('allUpdate', 'promeniStatusNaloga', { in_broj_naloga: (brojNalogaRef.current.value).trim(), oj_id: oj_id.authUser.oj_id });
      if (response.data[0][0].msg !== 'ok') {
        dispatch(setMessage({ msgText: `Radni nalog sa brojem ${brojNalogaRef.current.value} ne postoji.`, icon: 'warning', showHide: true }));
        return;
      }
      dispatch(setMessage({ msgText: 'Status naloga je promenjen.', icon: 'success', showHide: true }));
      navigate('/main/radninalog');
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className='promeni-status-naloga'>
      <SlClose className='promeni-status-naloga-close-button' onClick={() => navigate('/main/landingpage')} />
      <div className="promeni-status-naloga-top">
        <p>
          <span>Promeni status naloga</span>
        </p>
      </div>
      <div className="promeni-status-naloga-bottom">
        <div className="input-group">
          <div className='input-field'>
            <label htmlFor='password'>Broj radnog naloga</label>
            <input
              type="text"
              name='brojRadnogNaloga'
              autoComplete='off'
              ref={brojNalogaRef}
            />
          </div>
        </div>
      </div>
      <div className="register-group">
        <button onClick={handlePromeniStatusNaloga}>Primeni</button>
      </div>
    </div>
  )
}

export default PromeniStatusNaloga;