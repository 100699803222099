import './LandingPage.css';
import landing_page from '../../images/backgrounds/splash14.webp';

const LandingPage = () => {
  return (
    <div className='landing-page'>
      <img src={landing_page} alt="" />
    </div>
  )
}

export default LandingPage;