import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import './VerticalMenu.css';
import MenuGroup from './MenuGroup';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const VereticalMenu = () => {
  const [showVerticalMenu, setShowVerticalMenu] = useState(true);
  const user = useSelector((state) => state.user);
  const prava = user.authUser.pravo_id;

  return (
    <div className='vertical-menu-container' style={{ pointerEvents: 'auto', width: showVerticalMenu ? '100%' : '4.5rem' }}>
      <GiHamburgerMenu className='hamburger' onClick={() => setShowVerticalMenu(!showVerticalMenu)} />
      <div className="vertical-menu-wrapper" style={{ display: showVerticalMenu ? 'flex' : 'none' }}>
        {(prava === 1 || prava === 2) && <MenuGroup caption='RADNI NALOG'>
          <div className="vertical-menu-items">
            {/* <NavLink style={{ pointerEvents: 'none' }} to="/main/radninalog" */}
            <NavLink to="/main/radninalog"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Radni nalog</NavLink>
          </div>
        </MenuGroup>}
        {(prava === 1 || prava === 3) && <MenuGroup caption='OSNOVNI PODACI'>
          <div className="vertical-menu-items">
            {(prava === 1) && <NavLink to="/main/operacija"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Operacija</NavLink>}
            {(prava === 1) && <NavLink to="/main/parcela"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Parcela / Mesto troška</NavLink>}
            {(prava === 1) && <NavLink to="/main/masina"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Mašina</NavLink>}
            {(prava === 1) && <NavLink to="/main/prikljucnamasina"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Priključna mašina</NavLink>}
            {(prava === 1 || prava === 3) && <NavLink to="/main/repromaterijal"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Repromaterijal</NavLink>}
            {(prava === 1) && <NavLink to="/main/kultura"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Kultura</NavLink>}
            {(prava === 1) && <NavLink to="/main/radnik"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Radnik</NavLink>}
          </div>
        </MenuGroup>}
        {(prava === 1 || prava === 3) && <MenuGroup caption='VEZE'>
          <div className="vertical-menu-items">
            {(prava === 1) && <NavLink to="/main/operacijamasina"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Operacija / Mašina</NavLink>}
            {(prava === 1) && <NavLink to="/main/masinaprikljucnamasina"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Mašina / Priključna mašina</NavLink>}
            {(prava === 1) && <NavLink to="/main/operacijaprikljucnamasina"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Operacija / Priključna mašina</NavLink>}
            {(prava === 1) && <NavLink to="/main/radnikmasina"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Radnik / Mašina</NavLink>}
            {(prava === 1) && <NavLink to="/main/operacijarepromaterijal"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Operacija / Repromaterijal</NavLink>}
            {(prava === 1 || prava === 3) && <NavLink to="/main/kulturahemija"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Kultura / Hemija</NavLink>}
          </div>
        </MenuGroup>}
        {(prava === 1 || prava === 3) && <MenuGroup caption='IZVEŠTAJI'>
          <div className="vertical-menu-items">
            {(prava === 1) && <NavLink to="/main/exportusap"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Export u SAP</NavLink>}
            {(prava === 1) && <NavLink to="/main/rasknjizavanjerepromaterijala"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Rasknjižavanje repromaterijala</NavLink>}
            {(prava === 1) && <NavLink to="/main/rasknjizavanjegoriva"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Rasknjižavanje goriva</NavLink>}
            {(prava === 11 || prava === 31) && <NavLink to="/main/utrosakhemije"
              className={({ isActive }) =>
                isActive ? 'active' : 'inactive'
              }>Izvestaj Bilja</NavLink>}
          </div>
        </MenuGroup>}
      </div>
    </div>
  )
}

export default VereticalMenu;