import { useState } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { execProcedure } from '../../services/functions/server_functions/functions';
import './UtrosakGorivaIRepromaterijala.css';

const UtrosakGorivaIRepromaterijala = ({ setShowUtrosakGorivaIRepromaterijala }) => {
  const [utrosakFilter, setUtrosakFilter] = useState({ datumOd: (new Date().toISOString().substring(0, 10)), datumDo: (new Date().toISOString().substring(0, 10)), status: 0, operacija: 0, parcela: 0 });
  const [pregledUtrosaka, setPregledUtrosaka] = useState([]);
  const dispatch = useDispatch();
  const operacija = useSelector((state) => state.operacija);
  const parcela = useSelector((state) => state.parcela);
  const ojUser = useSelector((state) => state.user);

  const handleSumirajUtroske = async () => {
    try {
      dispatch(setWorking(true));
      const pregledUtrosakaData = await execProcedure('allGet', 'returnUtrosciGorivaIRepromaterijala',
        {
          datum_od: utrosakFilter.datumOd,
          datum_do: utrosakFilter.datumDo,
          oj_id: ojUser.authUser.oj_id,
          status: utrosakFilter.status,
          operacija: utrosakFilter.operacija,
          parcela: utrosakFilter.parcela
        });
      setPregledUtrosaka(pregledUtrosakaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  return (
    <div className='utrosak-goriva-i-repromaterijala-container'>
      <div className="utrosak-goriva-i-repromaterijala-header">
        <p>Utrošak goriva i repromaterijala</p>
        <SlClose className='utrosak-goriva-i-repromaterijala-close-button' onClick={() => {
          setShowUtrosakGorivaIRepromaterijala(false)
        }} />
      </div>
      <div className="utrosak-goriva-i-repromaterijala-filter">
        <div className="utrosak-goriva-i-repromaterijala-filter-row">
          <div className="input-field">
            <label htmlFor="">Od datuma</label>
            <input type="date"
              value={utrosakFilter.datumOd}
              onChange={(e) => { setUtrosakFilter({ ...utrosakFilter, datumOd: e.target.value }); }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="">Do datuma</label>
            <input type="date"
              value={utrosakFilter.datumDo}
              onChange={(e) => { setUtrosakFilter({ ...utrosakFilter, datumDo: e.target.value }); }}
            />
          </div>
          <div className="input-field">
            <label htmlFor="">Status</label>
            <select name="status" value={utrosakFilter.status} onChange={(e) => setUtrosakFilter({ ...utrosakFilter, status: e.target.value })}>
              <option key={0} value={0}>-----</option>
              <option key={1} value={1}>Otvoreni</option>
              <option key={2} value={2}>Redovno zatvoreni</option>
              <option key={3} value={3}>Prevremeno zatvoreni</option>
            </select>
          </div>
        </div>
        <div className="utrosak-goriva-i-repromaterijala-filter-row">
          <div className="input-field">
            <label htmlFor="">Operacija</label>
            <select name="status" value={utrosakFilter.operacija} onChange={(e) => setUtrosakFilter({ ...utrosakFilter, operacija: e.target.value })}>
              <option key={0} value={0}>-----</option>
              {operacija.map((o) => {
                return (
                  <option key={o.id} value={o.id}>{o.naziv}</option>
                )
              })}
            </select>
          </div>
          <div className="input-field">
            <label htmlFor="">Parcela</label>
            <select name="status" value={utrosakFilter.parcela} onChange={(e) => setUtrosakFilter({ ...utrosakFilter, parcela: e.target.value })}>
              <option key={0} value={0}>-----</option>
              {parcela.map((p) => {
                return (
                  <option key={p.id} value={p.id}>{p.naziv}</option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      <button onClick={handleSumirajUtroske}>Primeni</button>
      <div className="table-form-main">
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Naziv</th>
                <th>JM</th>
                <th>Ukupno</th>
              </tr>
            </thead>
            <tbody>
              {pregledUtrosaka?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{data.gorivo_repromaterijal_id}</td>
                    <td>{data.naziv}</td>
                    <td>{data.jm}</td>
                    <td style={{ textAlign: 'right' }}>{data.ukupno}</td>
                  </tr>
                )
              })
              }
            </tbody>
          </table>
        </div>
        <div className='number-of-rows'>Broj redova: {pregledUtrosaka.length}</div>
      </div>
    </div>
  )
}

export default UtrosakGorivaIRepromaterijala;