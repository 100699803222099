import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import './PopupWindow.css';

const PopupWindow = ({ setShowPopupMenu }) => {
  const dispatch = useDispatch();
  const showPopupMenuItem = useSelector((state) => state.user);

  return (
    <div className="popupWindow popupWindow__hover">
      <div className="popup-menu">
        <p><NavLink to="/main/promenisifru">Promeni password</NavLink></p>
        {showPopupMenuItem.authUser.pravo_id === 1 && <p onClick={() => { setShowPopupMenu(false) }}><NavLink to="/main/kreirajkorisnika">Kreiraj korisnika</NavLink></p>}
        {showPopupMenuItem.authUser.pravo_id === 1 && <p onClick={() => { setShowPopupMenu(false) }}><NavLink to="/main/promenistatusnaloga">Promeni status naloga</NavLink></p>}
        {showPopupMenuItem.authUser.pravo_id === 1 && <p onClick={() => { setShowPopupMenu(false) }}><NavLink to="/main/promenioj">Promeni OJ</NavLink></p>}
        {/* {showPopupMenuItem.authUser.pravo_id === 1 && <p onClick={izaberiOJ}>Izaberi OJ</p>} */}
        <p><NavLink to="/">Odjavi se</NavLink></p>
      </div>
    </div>
  )
}

export default PopupWindow;