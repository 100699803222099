import { useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import './MenuGroup.css';

const MenuGroup = ({ children, caption }) => {
  const [showMenuGroupItems, setShowMenuGroupItems] = useState(true);

  return (
    <div className='menu-group'>
      <div className="menu-group-item" onClick={() => setShowMenuGroupItems(!showMenuGroupItems)}>{caption} {!showMenuGroupItems ? <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowUp />}</div>
      {showMenuGroupItems && children}
    </div>
  )
}

export default MenuGroup;