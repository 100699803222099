import { useRef } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import './PromeniSifru.css';

const PromeniSifru = () => {
  const password1Ref = useRef();
  const password2Ref = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const handleUserRegister = async (e) => {
    e.preventDefault();

    if (!password1Ref.current.value || !password2Ref.current.value) {
      dispatch(setMessage({ msgText: 'Nisu popunjena sva obavezna polja.', icon: 'warning', showHide: true })); return;
    }
    if (password1Ref.current.value != password2Ref.current.value) {
      dispatch(setMessage({ msgText: 'Password i Ponovi password nisu isti.', icon: 'warning', showHide: true })); return;
    }

    try {
      const res = await fetch('https://rnalogapi.cs-dev.rs/changeUserPassword',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({ email: user.authUser.email, password: password1Ref.current.value })
        });
      // const data = await res.json();
      dispatch(setMessage({ msgText: 'Password je promenjen.', icon: 'success', showHide: true }));
      navigate('/main/landingpage');
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className='change-paassword'>
      <SlClose className='change-paassword-close-button' onClick={() => navigate('/main/landingpage')} />
      <div className="change-paassword-top">
        <p>
          <span>Promeni password</span>
        </p>
      </div>
      <div className="change-paassword-bottom">
        <div className="input-group">
          <div className='input-field'>
            <label htmlFor='password'><span className='asterisk'>*</span> Password</label>
            <input
              type="text"
              name='password'
              autoComplete='off'
              ref={password1Ref}
            />
          </div>
          <div className='input-field'>
            <label htmlFor='repeat-password'><span className='asterisk'>*</span> Ponovi password</label>
            <input
              type="text"
              name='repeat-password'
              autoComplete='off'
              ref={password2Ref}
            />
          </div>
        </div>
      </div>
      <div className="register-group">
        <button onClick={handleUserRegister}>Primeni</button>
      </div>
    </div>
  )
}

export default PromeniSifru;