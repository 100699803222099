import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const podvrstaRepromaterijalaSlice = createSlice({
  name: 'podvrstaRepromaterijalaSliceReducer',
  initialState: initState,
  reducers: {
    addPodvrstaRepromaterijala: (state, action) => {
      state = action.payload;
      return state;
    }
  }
});

export const { addPodvrstaRepromaterijala } = podvrstaRepromaterijalaSlice.actions;

export default podvrstaRepromaterijalaSlice.reducer;