import { createSlice } from "@reduxjs/toolkit";

const initState = [];
let prevState = [];

const masinaSlice = createSlice({
  name: 'masinaSliceReducer',
  initialState: initState,
  reducers: {
    addMasina: (state, action) => {
      state = action.payload;
      prevState = action.payload;
      return state;
    },
    getOperacijaMasina: (state, action) => {
      // state = prevState.filter((masina) => masina.id == action.payload);
      return state;
    }
  }
});

export const { addMasina, getOperacijaMasina } = masinaSlice.actions;

export default masinaSlice.reducer;