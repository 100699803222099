import { useEffect, useState } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { execProcedure, selectStatementData } from '../../services/functions/server_functions/functions';
import './KulturaHemija.css';

const KulturaHemija = () => {
  const [kultura, setKultura] = useState([]);
  const [resultDataHemija, setResultDataHemija] = useState([]);
  const [selectedKultura, setSelectedKultura] = useState({ kultura: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchKultura = async () => {
      try {
        dispatch(setWorking(true));
        const kulturaData = await selectStatementData(`SELECT k.id, k.naziv FROM kultura k`);
        setKultura(kulturaData.data);
        setSelectedKultura({ kultura: kulturaData.data ? kulturaData.data[0].id : 0 });
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      } finally {
        dispatch(setWorking(false));
      }
    }

    fetchKultura();
  }, [])

  useEffect(() => {
    handleKulturaChange();
  }, [selectedKultura])

  const handleKulturaChange = async () => {
    try {
      dispatch(setWorking(true));
      const hemijaData = await execProcedure('allGet', 'getKulturaHemija', { kultura_id: selectedKultura.kultura });
      setResultDataHemija(hemijaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleCheckedChange = (e) => {
    const editKulturaHemija = resultDataHemija.map((hemija) => {
      if (hemija.id === Number.parseInt(e.target.name))
        return { ...hemija, checked: !hemija.checked }
      else
        return hemija;
    })
    setResultDataHemija(editKulturaHemija);
  }

  const handleSaveChanges = async () => {
    try {
      let parametar = selectedKultura.kultura;
      let ostatak = '';
      resultDataHemija.forEach((hemija) => {
        if (hemija.checked) { ostatak += ','; ostatak += hemija.id; }
      })
      parametar += ostatak;

      await execProcedure('updateKulturaHemija', 'updateKulturaHemija', `${parametar}`);
      dispatch(setMessage({ msgText: 'Podaci kultura / hemija su snimljeni.', icon: 'success', showHide: true }));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className="kultura-hemija">
      <div className='table-form'>
        <div className="table-form-header">Kultura / Hemija<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="kultura-hemija-wrapper">
          <div className="kultura-hemija-left">
            <div className="table-form">
              <div className="table-form-main">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv kulture</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kultura.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="radio" name='kultura' checked={data.id === selectedKultura.kultura} onChange={() => { setSelectedKultura({ ...selectedKultura, kultura: data.id }); }} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {kultura.length}</div>
              </div>
            </div>
          </div>
          <div className="kultura-hemija-right">
            <div className="table-form">
              <div className="table-form-main">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv sredstva</th>
                        <th>JM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultDataHemija.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="checkbox" name={data.id} checked={data.checked} onChange={handleCheckedChange} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                            <td>{data.jm}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {resultDataHemija.length}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleSaveChanges}>Snimi</button>
        </div>
      </div>
    </div>
  )
}

export default KulturaHemija;