import { GridLoader, PuffLoader } from 'react-spinners';

const Working = () => {
  const override = {
    // display: 'flex',
    position: 'fixed',
    left: '50%',
    top: '13rem',
    transform: 'translate(-50%, -50%)',
    color: '#B73E3E'
  };

  return (
    <>
      {/* <GridLoader color={'#C21010'} cssOverride={override} /> */}
      <PuffLoader color={'#C21010'} cssOverride={override} />
    </>
  )
}

export default Working;