import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const kulturaSlice = createSlice({
  name: 'kulturaSliceReducer',
  initialState: initState,
  reducers: {
    addKultura: (state, action) => {
      state = action.payload;
      return state;
    }
  }
});

export const { addKultura } = kulturaSlice.actions;

export default kulturaSlice.reducer;