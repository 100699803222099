import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo1.jpg';
import user from '../images/user.png';
import './Header.css';
import PopupWindow from './popup_window/PopupWindow';

const Header = () => {
  const [showPopupMenu, setShowPopupMenu] = useState(false);
  const navigate = useNavigate();
  const userName = useSelector((state) => state.user);

  return (
    <header>
      <div className="header__left">
        <a href="http://www.uljaricebacka.com" target={'_blank'}><img src={logo} alt='logo' /></a>
      </div>
      <div className="header__middle">
        <h1>Radni nalog </h1><h2> ({userName.authUser.naziv_oj})</h2>
      </div>
      <div className="header__right">
        <div className="userLogin">
          <img src={user} alt='' onClick={() => setShowPopupMenu((prevValue) => (!prevValue))} />
          <p>{userName.authUser.prezime_i_ime}</p>
          {showPopupMenu && <PopupWindow setShowPopupMenu={setShowPopupMenu} />}
        </div>
      </div>
    </header>
  )
}

export default Header;