import { Fragment, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/inputForm.css';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { execInsertProcedureWithoutUrl, selectStatementData } from '../../services/functions/server_functions/functions';
import './OperacijaIf.css';

const OperacijaIF = ({ showForm, fetchOperacija, editData, addEditData }) => {
  const koristiRepromaterijalRef = useRef();
  const nemaHektareRef = useRef();
  const gotovaRobaRef = useRef();
  const [operacija, setOperacija] = useState({ id: editData.id, naziv: editData.naziv, koristi_repromaterijal: editData.koristi_repromaterijal, vrsta_repromaterijala_id: editData.vrsta_repromaterijala_id, nema_obradivu_povrsinu: editData.nema_obradivu_povrsinu, gotova_roba: editData.gotova_roba });
  const [vrstaRepromaterijala, setVrstaRepromaterijala] = useState([]);
  const [oldId] = useState(editData.id);
  const idRef = useRef();
  const nazivRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    fetchVrstaRepromaterijala();

    if (addEditData === 'add') {
      idRef.current.focus();
      koristiRepromaterijalRef.current = false;
    }
    else {
      nazivRef.current.focus();
      koristiRepromaterijalRef.current = editData.koristi_repromaterijal === 1 ? true : false;
    }
  }, [])

  const fetchVrstaRepromaterijala = async () => {
    try {
      dispatch(setWorking(true));
      const vrstaRepromaterijalaData = await selectStatementData(`SELECT vr.id, vr.naziv FROM vrsta_repromaterijala vr`);
      setVrstaRepromaterijala(vrstaRepromaterijalaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleSave = async () => {
    if (operacija.id === '' || operacija.naziv === '') { dispatch(setMessage({ msgText: 'Nisu popunjena sva obavezna polja.', icon: 'warning', showHide: true })); return };
    if (addEditData === 'edit') {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({
              sentence: `update operacija set id = '${operacija.id}', naziv = '${operacija.naziv}', koristi_repromaterijal = ${operacija.koristi_repromaterijal}, vrsta_repromaterijala_id = ${koristiRepromaterijalRef.current === false ? 0 : operacija.vrsta_repromaterijala_id}, nema_obradivu_povrsinu = ${nemaHektareRef.current.checked}, gotova_roba = ${gotovaRobaRef.current.checked} where id = ${oldId}`
            })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        await execInsertProcedureWithoutUrl('saveRadniNalogLog', {
          in_izvrsilac_aktivnosti: user.authUser.email,
          in_opis_aktivnosti: `update operacija set id = ${operacija.id}, naziv = ${operacija.naziv}, koristi_repromaterijal = ${operacija.koristi_repromaterijal}, vrsta_repromaterijala_id = ${koristiRepromaterijalRef.current === false ? 0 : operacija.vrsta_repromaterijala_id}, nema_obradivu_povrsinu = ${nemaHektareRef.current.checked}, gotova_roba = ${gotovaRobaRef.current.checked} where id = ${oldId}`
        });
        fetchOperacija();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    else {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `insert into operacija values(${operacija.id}, '${operacija.naziv}', ${operacija.koristi_repromaterijal}, ${koristiRepromaterijalRef.current === false ? 0 : operacija.vrsta_repromaterijala_id}, ${nemaHektareRef.current.checked},  ${gotovaRobaRef.current.checked})` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        await execInsertProcedureWithoutUrl('saveRadniNalogLog', {
          in_izvrsilac_aktivnosti: user.authUser.email,
          in_opis_aktivnosti: `insert into operacija values(${operacija.id}, ${operacija.naziv}, ${operacija.koristi_repromaterijal}, ${koristiRepromaterijalRef.current === false ? 0 : operacija.vrsta_repromaterijala_id}, ${nemaHektareRef.current.checked})`
        });
        fetchOperacija();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
  }

  const handleCancelSave = () => {
    fetchOperacija();
    showForm(false);
  }

  return (
    <div className='operacija-if'>
      <div className='input-form'>
        <div className="title-bar">Operacija</div>
        <div className="input-field">
          <label htmlFor="">ID</label>
          <input
            type="number" ref={idRef} value={operacija.id} onChange={(e) => setOperacija({ ...operacija, id: e.target.value })}
          />
        </div>
        <div className="input-field">
          <label htmlFor="">Naziv operacije</label>
          <input type="text" name="" ref={nazivRef} value={operacija.naziv} onChange={(e) => setOperacija({ ...operacija, naziv: e.target.value })} />
        </div>
        <div className="koristi-repromaterijal-wrapper">
          <div className="input-field">
            <label htmlFor="">Koristi repromaterijal <input ref={koristiRepromaterijalRef} type="checkbox" checked={operacija.koristi_repromaterijal} onChange={(e) => { setOperacija({ ...operacija, koristi_repromaterijal: e.target.checked }); koristiRepromaterijalRef.current = e.target.checked; }} /></label>
          </div>
          <div className="input-field" style={{ display: koristiRepromaterijalRef.current === true ? 'flex' : 'none' }}>
            <label htmlFor="">Vrsta repromaterijala</label>
            <select name="" value={operacija.vrsta_repromaterijala_id} onChange={(e) => setOperacija({ ...operacija, vrsta_repromaterijala_id: e.target.value })}>
              {vrstaRepromaterijala.map((vr) => {
                return (
                  <Fragment key={vr.id}>
                    <option value={vr.id}>{vr.naziv}</option>
                  </Fragment>
                )
              })}
            </select>
          </div>
        </div>
        <div className="input-field">
          <label htmlFor="">Nema obradive hektare <input ref={nemaHektareRef} type="checkbox" checked={operacija.nema_obradivu_povrsinu} onChange={(e) => setOperacija({ ...operacija, nema_obradivu_povrsinu: e.target.checked })} /></label>
        </div>
        <div className="input-field">
          <label htmlFor="">Gotova roba <input ref={gotovaRobaRef} type="checkbox" checked={operacija.gotova_roba} onChange={(e) => setOperacija({ ...operacija, gotova_roba: e.target.checked })} /></label>
        </div>
        <footer>
          <button onClick={handleSave}>Snimi</button>
          <button onClick={handleCancelSave}>Odustani</button>
        </footer>
      </div>
    </div>
  )
}

export default OperacijaIF;