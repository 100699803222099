import { useEffect, useState } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { execProcedure, selectStatementData } from '../../services/functions/server_functions/functions';
import './OperacijaPrikljucnaMasina.css';

const OperacijaPrikljucnaMasina = () => {
  const [operacija, setOperacija] = useState([]);
  const [resultDataPomocneMasine, setResultDataPomocneMasine] = useState([]);
  const [selectedOperacija, setSelectedOperacija] = useState({ operacija: 0, oj: 0 });
  const [oj, setOj] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    const fetchOperacija = async () => {
      try {
        dispatch(setWorking(true));
        const ojData = await selectStatementData(`SELECT oj.id, oj.naziv FROM organizaciona_jedinica oj`);
        setOj(ojData.data);
        const operacijeData = await selectStatementData(`SELECT o.id, o.naziv FROM operacija o`);
        setOperacija(operacijeData.data);
        setSelectedOperacija({ operacija: operacijeData.data ? operacijeData.data[0].id : 0, oj: ojUser.authUser.oj_id });
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      } finally {
        dispatch(setWorking(false));
      }
    }
    fetchOperacija();
  }, [])

  useEffect(() => {
    handleOperacijaChange();
  }, [selectedOperacija])

  const handleOperacijaChange = async () => {
    try {
      dispatch(setWorking(true));
      const pomocnaMasinaData = await execProcedure('getOperacijaPrikljucnaMasina', 'getOperacijaPrikljucnaMasina', { operacija_id: selectedOperacija.operacija, oj: selectedOperacija.oj, koristi_prikljucnu_masinu_oj_id: ojUser.authUser.oj_id });
      setResultDataPomocneMasine(pomocnaMasinaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleCheckedChange = (e) => {
    const editDataPomocneMasine = resultDataPomocneMasine.map((pomocnaMasina) => {
      if (pomocnaMasina.id === Number.parseInt(e.target.name))
        return { ...pomocnaMasina, checked: !pomocnaMasina.checked }
      else
        return pomocnaMasina;
    })
    setResultDataPomocneMasine(editDataPomocneMasine);
  }

  const handleSaveChanges = async () => {
    try {
      let parametar = selectedOperacija.operacija;
      let ostatak = '';
      resultDataPomocneMasine.forEach((masina) => {
        if (masina.checked) { ostatak += ','; ostatak += masina.id; }
      })
      parametar += ostatak;

      await execProcedure('updateOperacijaPrikljucnaMasina', 'updateOperacijaPrikljucnaMasina', { array_of_id: parametar, oj_id: selectedOperacija.oj, koristi_prikljucnu_masinu_oj_id: ojUser.authUser.oj_id });
      dispatch(setMessage({ msgText: 'Podaci operacija / mašina su snimljeni.', icon: 'success', showHide: true }));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className="operacija-prikljucna-masina">
      <div className='table-form'>
        <div className="table-form-header">Operacija / Priključna mašina<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="operacija-prikljucna-masina-wrapper">
          <div className="operacija-prikljucna-masina-left">
            <div className="table-form">
              <div className="table-form-main">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv operacije</th>
                      </tr>
                    </thead>
                    <tbody>
                      {operacija.map((data, index) => {
                        return (
                          <tr key={index}>
                            {/* <td><input type="radio" name='operacija' onChange={() => handleOperacijaChange(data.id)} /></td> */}
                            <td><input type="radio" name='operacija' checked={data.id === selectedOperacija.operacija} onChange={() => { setSelectedOperacija({ ...selectedOperacija, operacija: data.id }); }} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {operacija.length}</div>
              </div>
            </div>
          </div>
          <div className="operacija-prikljucna-masina-right">
            <div className="table-form">
              <div className="table-form-main">
                <div className="input-field">
                  <label htmlFor="">Organizaciona jedinica</label>
                  <select value={selectedOperacija.oj} onChange={(e) => { setSelectedOperacija({ ...selectedOperacija, oj: e.target.value }); }}>
                    <option value="0">-----</option>
                    {oj && oj.map((o) => {
                      return (
                        <option key={o.id} value={o.id}>{o.naziv}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv</th>
                        <th>OJ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultDataPomocneMasine.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="checkbox" name={data.id} checked={data.checked} onChange={handleCheckedChange} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                            <td>{data.naziv_oj}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {resultDataPomocneMasine.length}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleSaveChanges}>Snimi</button>
        </div>
      </div>
    </div>
  )
}

export default OperacijaPrikljucnaMasina;