import { createSlice, CreateSliceOptions } from "@reduxjs/toolkit";


const initState = {
  datumOd: null,
  datumDo: null,
  operacija: null,
  parcela: null,
  masina: null,
  prikljucnaMasina: null,
  repromaterijal: null,
  radnik: null,
  oj: null,
  status: 1
};

const filterRadniNalogCriteriaSlice = createSlice({
  name: 'filterRadniNalogCriteriaSliceReducer',
  initialState: initState,
  reducers: {
    getRadniNalogFilter: (state, action) => {
      return state;
    },
    setRadniNalogFilterInit: (state, action) => {
      state = initState;
      state = { ...state, [action.payload.name]: action.payload.value };
      return state;
    },
    setRadniNalogFilter: (state, action) => {
      state = { ...state, [action.payload.name]: action.payload.value };
      return state;
    },
  }
});

export const { getRadniNalogFilter, setRadniNalogFilterInit, setRadniNalogFilter } = filterRadniNalogCriteriaSlice.actions;

export default filterRadniNalogCriteriaSlice.reducer;