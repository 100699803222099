import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDots } from 'react-icons/bs';
import { GiFertilizerBag } from 'react-icons/gi';
import { useEffect, useRef, useState } from 'react';
import { usePromeniDatum } from '../../radni_nalog_hooks/usePromeniDatum';
import { execProcedure } from '../../services/functions/server_functions/functions';
import { addRadniNalog } from '../../redux/reducers/radniNalogSlice'
import { addMasina, getOperacijaMasina } from '../../redux/reducers/masinaSlice';
import { addPrikljucnaMasina } from '../../redux/reducers/prikljucnaMasinaSlice';
import { addRadnik } from '../../redux/reducers/radnikSlice';
import { setEditValues, updateValues } from '../../redux/reducers/addEditRadniNalog';
import { addInitialValues } from '../../redux/reducers/radniNalogRepromaterijalSlice';
import { getOperacijaKoristiRepromaterijal } from '../../redux/reducers/operacijaSlice';
import DropDownList from '../../components/DropDownList/DropDownList';
import UnosRepromaterijala from '../../components/UnosRepromaterijala/UnosRepromaterijala';

import './RadniNalogIF.css';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import useRadniNalogRefresh from '../../radni_nalog_hooks/useRadniNalogRefresh';
import { SlClose } from 'react-icons/sl';
import { addParcela } from '../../redux/reducers/parcelaSlice';
import { setWorking } from '../../redux/reducers/workingSlice';

const RadniNalogIF = ({ showForm, addEditData }) => {
  const [datumNaloga, setDatumNaloga] = useState(new Date().toISOString().slice(0, 10));
  const [addRepMaterijal, setAddRepMaterijal] = useState([])
  const [masina, setMasina] = useState([]);
  const [prikljucnaMasina, setPrikljucnaMasina] = useState([]);
  const [radnik, setRadnik] = useState([]);
  const [repromaterijal, setRepromaterijal] = useState([]);
  const [unosRepromaterijalaShow, setUnosRepromaterijalaShow] = useState(false);
  const [refresh] = useRadniNalogRefresh();
  const dispatch = useDispatch();

  const operacija = useSelector((state) => state.operacija);
  const parcela = useSelector((state) => state.parcela);
  const kultura = useSelector((state) => state.kultura);
  const oj_id = useSelector((state) => state.user);
  const dataForSave = useSelector((state) => state.addEditRadniNalog);
  const stavkeRepromaterijala = useSelector((state) => state.addEditRadniNalog);
  const [brojNaloga, promeniDatum] = usePromeniDatum();

  const uslugaUBRef = useRef();

  useEffect(() => {
    initialLoad();
    uslugaUBRef.current = false;
  }, [])

  const initialLoad = async () => {
    try {
      await promeniDatum(new Date().toISOString().slice(0, 10));

      const tmpAddRepMaterijal = stavkeRepromaterijala.repromaterijal_stavke?.map(({
        id: repromaterijal_id,
        naziv: repromaterijal_naziv,
        kolicina: repromaterijal_kolicina
      }) => ({
        repromaterijal_id,
        repromaterijal_naziv,
        repromaterijal_kolicina
      }));
      const brojRedova = tmpAddRepMaterijal.length;
      console.log(tmpAddRepMaterijal, brojRedova);
      for (let i = brojRedova; i < 7; i++) {
        tmpAddRepMaterijal.push({ repromaterijal_id: '', repromaterijal_kolicina: '', repromaterijal_naziv: '' });
      }
      setAddRepMaterijal(tmpAddRepMaterijal);

      if (addEditData === 'edit' || addEditData === 'preview') {
        await handleOperacijaChange(dataForSave.operacija);
        await handleParcelaChange(dataForSave.parcela);
        await handleMasinaChange(dataForSave.masina);
      } else {
        dispatch(addInitialValues({}))
      }
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  const handleOperacijaChange = async (e) => {
    dispatch(updateValues({ name: 'operacija', value: e }));
    const nop = operacija.filter((o) => o.id == e && o.nema_obradivu_povrsinu == 1).length;
    const gotova_roba = operacija.filter((o) => o.id == e && o.gotova_roba == 1).length;

    if (addEditData === 'add') {
      const tmpAddRepMaterijal = []
      for (let i = 0; i < 7; i++) {
        tmpAddRepMaterijal.push({ repromaterijal_id: '', repromaterijal_kolicina: '', repromaterijal_naziv: '' });
      }
      setAddRepMaterijal(tmpAddRepMaterijal);

      dispatch(addParcela([]));
      setMasina([]);
      setPrikljucnaMasina([]);
      setRadnik([]);
      setRepromaterijal([]);
      dispatch(updateValues({ name: 'registracija', value: 0 }));
      dispatch(updateValues({ name: 'kultura', value: '' }));
      dispatch(updateValues({ name: 'nazivKulture', value: '' }));
      dispatch(updateValues({ name: 'ukupnaPovrsina', value: '' }));
      dispatch(updateValues({ name: 'parcela', value: 0 }));
      dispatch(updateValues({ name: 'nazivMasine', value: '' }));
      dispatch(updateValues({ name: 'prikljucnaMasina', value: 0 }));
      dispatch(updateValues({ name: 'radnik', value: 0 }));
      dispatch(updateValues({ name: 'preostalo', value: '' }));
      dispatch(updateValues({ name: 'uradjeno', value: '' }));
      dispatch(updateValues({ name: 'natoceno', value: '' }));
      dispatch(updateValues({ name: 'gotova_roba_merkantilna', value: '' }));
      dispatch(updateValues({ name: 'gotova_roba_semenska', value: '' }));
      dispatch(updateValues({ name: 'gotova_roba', value: 0 }));
    }
    if (e == 0) {
      return;
    }
    try {
      dispatch(setWorking(true));
      dispatch(updateValues({ name: 'nema_obradivu_povrsinu', value: nop }));
      dispatch(updateValues({ name: 'gotova_roba', value: gotova_roba }));
      const masinaData = await execProcedure('returnMasinaDependOfOperacija', 'returnMasinaDependOfOperacija', { operacija: e, oj: oj_id.authUser.oj_id });
      setMasina(masinaData.data);
      const prikljucnaMasinaData = await execProcedure('allGet', 'returnPrikljucnaMasinaDependOfOperacijaAndMasina', { operacija: e, masina: dataForSave.masina });
      setPrikljucnaMasina(prikljucnaMasinaData.data);
      const parcelaData = await execProcedure('allGet', 'returnParcelaDependOfOperacija', { operacija: e, oj: oj_id.authUser.oj_id, usluga_ub: uslugaUBRef.current === true ? 1 : 0 });
      dispatch(addParcela(parcelaData.data));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleParcelaChange = async (e) => {
    if (addEditData === 'add') {
      dispatch(updateValues({ name: 'parcela', value: e }));
      dispatch(updateValues({ name: 'kultura', value: '' }));
      dispatch(updateValues({ name: 'nazivKulture', value: '' }));
      dispatch(updateValues({ name: 'ukupnaPovrsina', value: '' }));
      dispatch(updateValues({ name: 'preostalo', value: '' }));
      dispatch(updateValues({ name: 'uradjeno', value: '' }));
      dispatch(updateValues({ name: 'natoceno', value: '' }));
    }
    if (e == 0)
      return;

    const p = parcela.filter((p) => p.id === parseInt(e))
    dispatch(updateValues({ name: 'kultura', value: p[0].kultura_id }));
    dispatch(updateValues({ name: 'nazivKulture', value: p[0].naziv_kulture }));
    dispatch(updateValues({ name: 'ukupnaPovrsina', value: p[0].povrsina }));
    try {
      if (uslugaUBRef.current === false) {
        const preostaloData = await execProcedure('getPreostalo', 'getPreostalo', { operacija: dataForSave.operacija, parcela: e, oj: oj_id.authUser.oj_id });
        dispatch(updateValues({ name: 'preostalo', value: preostaloData.data[0].preostalo }));
        // alert('preostalo: ', preostaloData.data[0].preostalo)
      } else {
        dispatch(updateValues({ name: 'preostalo', value: 0 }));
      }
      const repromaterijalData = await execProcedure('allGet', 'returnRepromaterijalDependOfOperacijaAndKultura', { operacija: dataForSave.operacija, parcela: e });
      setRepromaterijal(repromaterijalData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  const handleMasinaChange = async (e) => {
    dispatch(updateValues({ name: 'masina', value: e }));
    if (addEditData === 'add') {
      dispatch(updateValues({ name: 'nazivMasine', value: '' }));
      dispatch(updateValues({ name: 'prikljucnaMasina', value: '' }));
      dispatch(updateValues({ name: 'radnik', value: '' }));
    }
    if (e == 0) {
      dispatch(updateValues({ name: 'nazivMasine', value: '' }));
      setPrikljucnaMasina([]);
      setRadnik([]);
      return;
    }

    const m = masina.filter((m) => m.id == e);
    dispatch(updateValues({ name: 'nazivMasine', value: m[0]?.naziv }));
    try {
      const radnikData = await execProcedure('returnRadnikDependOfMasina', 'returnRadnikDependOfMasina', { masina: e, koristi_masinu_oj_id: oj_id.authUser.oj_id });
      setRadnik(radnikData.data);
      const prikljucnaMasinaData = await execProcedure('allGet', 'returnPrikljucnaMasinaDependOfOperacijaAndMasina', { operacija: dataForSave.operacija, masina: e });
      setPrikljucnaMasina(prikljucnaMasinaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  const handleRestChange = (e) => {
    dispatch(updateValues({ name: e.target.name, value: e.target.value }))
  }

  const handleSaveAndCreate = async () => {
    try {
      const radniNalogForSave = {
        id: brojNaloga,
        oj: dataForSave.oj,
        operacija: dataForSave.operacija,
        parcela: dataForSave.parcela,
        masina: dataForSave.masina,
        prikljucna_masina: dataForSave.prikljucnaMasina,
        radnik: dataForSave.radnik,
        uradjeno: dataForSave.uradjeno || 0,
        natoceno: dataForSave.natoceno || 0,
        gotova_roba_merkantilna: dataForSave.gotova_roba_merkantilna || 0,
        gotova_roba_semenska: dataForSave.gotova_roba_semenska || 0,
        repromaterijal: await repromaterijalForSave('id') || '',
        repromaterijalKolicina: await repromaterijalForSave('kolicina') || '',
        komentar: dataForSave.komentar || '',
        status: dataForSave.status || 0,
        korisnik: oj_id.authUser.email,
        uslugaUB: uslugaUBRef.current,
        gotova_roba: dataForSave.gotova_roba
      };

      if (addEditData === 'add')
        await execProcedure('saveRadniNalog', 'saveRadniNalog', radniNalogForSave);
      else
        await execProcedure('updateRadniNalog', 'updateRadniNalog', radniNalogForSave);

      await promeniDatum(new Date().toISOString().slice(0, 10));
      setDatumNaloga(new Date().toISOString().slice(0, 10));
      const preostaloData = await execProcedure('getPreostalo', 'getPreostalo', { operacija: dataForSave.operacija, parcela: dataForSave.parcela, oj: oj_id.authUser.oj_id });
      dispatch(updateValues({ name: 'preostalo', value: preostaloData.data[0].preostalo }));
      dispatch(updateValues({ name: 'uradjeno', value: 0 }));
      dispatch(updateValues({ name: 'natoceno', value: 0 }));
      dispatch(updateValues({ name: 'gotova_roba_merkantilna', value: 0 }));
      dispatch(updateValues({ name: 'gotova_roba_semenska', value: 0 }));
      dispatch(setMessage({ msgText: 'Radni nalog je snimljen / kopiran.', icon: 'success', showHide: true }));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  const handleSave = async () => {
    if (addEditData === 'add') {
      if (dataForSave.preostalo < dataForSave.uradjeno) {
        // dispatch(setMessage({ msgText: 'Urađena površina parcele ne može biti veća od preostale površine.', icon: 'success', showHide: true }));
        // return;
      }
      try {
        const response = await execProcedure(
          'saveRadniNalog', 'saveRadniNalog',
          {
            id: brojNaloga,
            oj: dataForSave.oj,
            operacija: dataForSave.operacija,
            parcela: dataForSave.parcela,
            masina: dataForSave.masina,
            prikljucna_masina: dataForSave.prikljucnaMasina,
            radnik: dataForSave.radnik,
            uradjeno: dataForSave.uradjeno || 0,
            natoceno: dataForSave.natoceno || 0,
            gotova_roba_merkantilna: dataForSave.gotova_roba_merkantilna || 0,
            gotova_roba_semenska: dataForSave.gotova_roba_semenska || 0,
            repromaterijal: await repromaterijalForSave('id') || '',
            repromaterijalKolicina: await repromaterijalForSave('kolicina') || '',
            komentar: dataForSave.komentar || '',
            status: dataForSave.status || 0,
            korisnik: oj_id.authUser.email,
            uslugaUB: uslugaUBRef.current,
            gotova_roba: dataForSave.gotova_roba
          }
        );
        dispatch(setMessage({ msgText: 'Radni nalog je kreiran.', icon: 'success', showHide: true }));
        refresh();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    else {
      try {
        const response = await execProcedure(
          'updateRadniNalog', 'updateRadniNalog',
          {
            id: dataForSave.id,
            oj: dataForSave.oj,
            operacija: dataForSave.operacija,
            parcela: dataForSave.parcela,
            masina: dataForSave.masina,
            prikljucna_masina: dataForSave.prikljucnaMasina,
            radnik: dataForSave.radnik,
            uradjeno: dataForSave.uradjeno || 0,
            natoceno: dataForSave.natoceno || 0,
            gotova_roba_merkantilna: dataForSave.gotova_roba_merkantilna || 0,
            gotova_roba_semenska: dataForSave.gotova_roba_semenska || 0,
            repromaterijal: await repromaterijalForSave('id') || '',
            repromaterijalKolicina: await repromaterijalForSave('kolicina') || '',
            komentar: dataForSave.komentar || '',
            status: dataForSave.status || 0,
            korisnik: oj_id.authUser.email,
            uslugaUB: uslugaUBRef.current,
            gotova_roba: dataForSave.gotova_roba
          }
        );
        dispatch(setMessage({ msgText: 'Izmene radnog naloga su snimljene.', icon: 'success', showHide: true }));
        refresh();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
  }

  const handleCancelSave = () => {
    showForm(false)
  }

  const repromaterijalForSave = async (vrsta) => {
    let parametar = '';
    addRepMaterijal.forEach((element) => {
      if (element.repromaterijal_id != 0) {
        if (vrsta === 'id') { parametar += element.repromaterijal_id; parametar += ','; }
        else { parametar += element.repromaterijal_kolicina; parametar += ','; }
      }
    })
    return parametar.slice(0, -1);
  }

  return (
    <div className='radni-nalog-input-form'>
      <UnosRepromaterijala unosRepromaterijalaShow={unosRepromaterijalaShow} setUnosRepromaterijalaShow={setUnosRepromaterijalaShow} repromaterijal={repromaterijal} uradjeno={dataForSave.uradjeno} addRepMaterijal={addRepMaterijal} setAddRepMaterijal={setAddRepMaterijal} />
      <div className="radni-nalog-title-bar">Radni nalog <span>{addEditData === 'add' ? ' - kreiranje' : addEditData === 'edit' ? ' - izmena' : ' - pregled'}</span><SlClose style={{ display: addEditData === 'preview' ? 'flex' : 'none' }} className='close-button' onClick={() => showForm(false)} /></div>
      <div className="radni-nalog-broj-i-datum">
        <div className="input-field">
          <label htmlFor="">Broj naloga</label>
          {addEditData === 'add' ?
            <span style={{ color: brojNaloga === null ? 'red' : null }}> {brojNaloga || 'Datum nije ispravan'} </span> :
            <span>{dataForSave.id}</span>
          }
        </div>
        <div className="input-field" style={{ display: addEditData === 'add' ? 'flex' : 'none' }}>
          <label htmlFor="">Datum naloga</label>
          <span><input type='date' value={datumNaloga} onChange={(e) => { setDatumNaloga(e.target.value); promeniDatum(e.target.value) }} /></span>
        </div>
      </div>
      <div className='radni-nalog'>
        <div className="radni-nalog-left">
          <div className="radni-nalog-wizard">
            <div className="radni-nalog-wizard-left">1</div>
            <div className="radni-nalog-wizard-right">
              <DropDownList labelCaption='Operacija' name='operacija' value={dataForSave.operacija} onChange={(e) => handleOperacijaChange(e.target.value)} dataList={operacija} fieldToShow='naziv' disabledSelect={addEditData} />
              <div className='usluga-ub'>Usluga UB<input type="checkbox" disabled={addEditData === 'edit' ? true : false} ref={uslugaUBRef} onChange={(e) => {
                uslugaUBRef.current = e.target.checked;
                handleOperacijaChange(dataForSave.operacija);
              }} /></div>
            </div>
          </div>
          <div className="radni-nalog-wizard">
            <div className="radni-nalog-wizard-left">2</div>
            <div className="radni-nalog-wizard-right">
              <div className="input-field-content">
                <DropDownList className='kultura-select' labelCaption='Parcela / mesto troška' name='parcela' value={dataForSave.parcela} onChange={(e) => handleParcelaChange(e.currentTarget.value)} dataList={parcela} fieldToShow='naziv' disabledSelect={addEditData} />
                <div className="input-field rn-kultura">
                  <label htmlFor="">Kultura</label>
                  <div>{dataForSave.nazivKulture}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="radni-nalog-wizard">
            <div className="radni-nalog-wizard-left">3</div>
            <div className="radni-nalog-wizard-right">
              <div className="input-field-content">
                <DropDownList className='rn-masina' labelCaption='Registracija' name='masina' value={dataForSave.masina} onChange={(e) => handleMasinaChange(e.currentTarget.value)} dataList={masina} fieldToShow='registracija' disabledSelect={addEditData} />
                <div className="input-field rn-kultura rn-masina">
                  <label htmlFor="">Mašina</label>
                  <div>{dataForSave.nazivMasine}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="radni-nalog-wizard">
            <div className="radni-nalog-wizard-left">4</div>
            <div className="radni-nalog-wizard-right">
              <DropDownList labelCaption='Priključna mašina' name='prikljucnaMasina' value={dataForSave.prikljucnaMasina} onChange={handleRestChange} dataList={prikljucnaMasina} fieldToShow='naziv' disabledSelect={addEditData} />
            </div>
          </div>
          <div className="radni-nalog-wizard">
            <div className="radni-nalog-wizard-left">5</div>
            <div className="radni-nalog-wizard-right">
              <DropDownList labelCaption='Radnik' name='radnik' value={dataForSave.radnik} onChange={handleRestChange} dataList={radnik} fieldToShow='prezime_ime' disabledSelect={addEditData} />
            </div>
          </div>
        </div>
        <div className="razdelna-linija"></div>
        <div className="radni-nalog-right">
          <div className="radni-nalog-wizard">
            <div className="radni-nalog-wizard-left">6</div>
            <div className="radni-nalog-wizard-right">
              <div className='input-field-content'>
                <div className="input-field hektari" style={{ visibility: dataForSave.nema_obradivu_povrsinu || dataForSave.gotova_roba ? 'hidden' : 'visible' }}>
                  <label htmlFor="">Ukupno ha</label>
                  <input type="number" value={dataForSave.ukupnaPovrsina} disabled name="povrsina" onChange={handleRestChange} />
                </div>
                <div className="input-field hektari" style={{ visibility: dataForSave.nema_obradivu_povrsinu || dataForSave.gotova_roba ? 'hidden' : 'visible' }}>
                  <label htmlFor="">Preostalo ha</label>
                  <input type="number" value={dataForSave.preostalo} disabled name="preostalo" onChange={handleRestChange} />
                </div>
                <div className="input-field hektari" style={{ visibility: dataForSave.nema_obradivu_povrsinu || dataForSave.gotova_roba ? 'hidden' : 'visible' }}>
                  <label htmlFor="">Urađeno ha</label>
                  <input type="number" name="uradjeno" disabled={addEditData === 'preview' ? true : false} min={0} step={0.001} value={dataForSave.uradjeno} onChange={handleRestChange} />
                </div>
                <div className="input-field hektari">
                  <label htmlFor="">Natočeno l</label>
                  <input type="number" name="natoceno" disabled={addEditData === 'preview' ? true : false} min={0} step={0.001} value={dataForSave.natoceno} onChange={handleRestChange} />
                </div>
                <div className="input-field hektari" style={{ visibility: dataForSave.nema_obradivu_povrsinu || dataForSave.gotova_roba ? 'hidden' : 'visible' }}>
                  <label htmlFor="">l/ha</label>
                  <input type="number" disabled name="prosek" value={(dataForSave.natoceno / dataForSave.uradjeno)?.toFixed(2)} onChange={handleRestChange} />
                </div>
              </div>
            </div>
          </div>
          <div className="radni-nalog-wizard">
            <div className="radni-nalog-wizard-left">7</div>
            <div className="radni-nalog-wizard-right">
              <div className="outer-container">
                <div className="input-field">
                  {
                    (() => {
                      if (dataForSave.gotova_roba == 1) return (
                        <div className='gotova-roba'>
                          {/* <div className="gotova-roba-left">
                            <div className="input-field">
                              <label htmlFor="">Merkantilna roba</label>
                              <select name="" id="">
                                {
                                  kultura.map((k) => {
                                    return (
                                      <option ption value={k.id}>{k.naziv}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                            <div className="input-field">
                              <label htmlFor="">Semenska roba</label>
                              <select name="" id="">
                                {
                                  kultura.map((k) => {
                                    return (
                                      <option ption value={k.id}>{k.naziv}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div> */}
                          <div className="gotova-roba-right">
                            <div className="input-field">
                              <label htmlFor="">Merkantilna (Kg)</label>
                              <input type="number" name="gotova_roba_merkantilna" value={dataForSave.gotova_roba_merkantilna} onChange={handleRestChange} />
                            </div>
                            <div className="input-field">
                              <label htmlFor="">Semenska (Kg)</label>
                              <input type="number" name="gotova_roba_semenska" value={dataForSave.gotova_roba_semenska} onChange={handleRestChange} />
                            </div>
                            <div className="input-field">
                              <label htmlFor="">Ukupno (Kg)</label>
                              <input type="number" name="gotova_roba_ukupno" disabled value={parseFloat(dataForSave.gotova_roba_merkantilna || 0) + parseFloat(dataForSave.gotova_roba_semenska || 0)} onChange={handleRestChange} />
                            </div>
                          </div>
                        </div>
                      )
                      else return (
                        <>
                          <label htmlFor="">Repromaterijal</label>
                          <div className='open-repromaterijal-btn' onClick={() => {
                            if (repromaterijal?.length > 0) setUnosRepromaterijalaShow(true)
                          }}><GiFertilizerBag /><BsThreeDots style={{ color: repromaterijal?.length > 0 ? '#16aaff' : '#495057' }} /></div>
                        </>
                      )
                    }
                    )()
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="radni-nalog-wizard">
            <div className="radni-nalog-wizard-left">8</div>
            <div className="radni-nalog-wizard-right">
              <div className="input-field">
                <label htmlFor="">Komentar</label>
                <textarea name="komentar" value={dataForSave.komentar} disabled={addEditData === 'preview' ? true : false} onChange={handleRestChange} ></textarea>
              </div>
            </div>
          </div>
          <span className='zatvori-nalog'>Zatvori nalog za izabranu operaciju i parcelu <input type='checkbox' disabled={addEditData === 'preview' ? true : false} checked={dataForSave.status === 2 ? true : false} onChange={(e) => {
            if (e.target.checked) dispatch(updateValues({ name: 'status', value: 2 }));
            else dispatch(updateValues({ name: 'status', value: 0 }))
          }} /></span>
        </div>
      </div >
      {/* <footer display={addEditData === 'preview' ? 'none' : 'flex'}> */}
      <footer footer >
        <button className={addEditData === 'preview' ? 'radni-nalog-if-preview' : null} onClick={handleSaveAndCreate}>Snimi i kopiraj</button>
        <button className={addEditData === 'preview' ? 'radni-nalog-if-preview' : null} onClick={handleSave}>Snimi</button>
        <button className={addEditData === 'preview' ? 'radni-nalog-if-preview' : null} onClick={handleCancelSave}>Odustani</button>
      </footer >
    </div >
  )
}

export default RadniNalogIF;