import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../../assets/inputForm.css';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { getData } from '../../services/functions/server_functions/functions';
import './KulturaIf.css';

const KulturaIF = ({ showForm, fetchKultura, editData, addEditData }) => {
  const [kultura, setKultura] = useState({ id: editData.id, naziv: editData.naziv, podvrstaRepromaterijala: editData.podvrsta_repromaterijala_id, vrstaKulture: editData.vrsta_kulture });
  const [oldId] = useState(editData.id);
  const [podvrstaRepromaterijala, setPodvrstaRepromaterijala] = useState([]);
  const [vrstaKulture, setVrstaKulture] = useState([]);
  const idRef = useRef();
  const nazivRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (addEditData === 'add')
      idRef.current.focus();
    else
      nazivRef.current.focus();

    const fetchPodvrstaRepromaterijala = async () => {
      try {
        dispatch(setWorking(true));
        const podvrstaRepromaterijalaData = await getData({ tableName: 'podvrsta_repromaterijala', whereCondition: '1 = 1' });
        setPodvrstaRepromaterijala(podvrstaRepromaterijalaData.data);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      } finally {
        dispatch(setWorking(false));
      }
    }
    const fetchVrstaKulture = async () => {
      try {
        dispatch(setWorking(true));
        const vrstaKultureData = await getData({ tableName: 'vrsta_kulture', whereCondition: '1 = 1' });
        setVrstaKulture(vrstaKultureData.data);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      } finally {
        dispatch(setWorking(false));
      }
    }

    fetchPodvrstaRepromaterijala();
    fetchVrstaKulture();
  }, [])

  const handleSave = async () => {
    if (kultura.id === '' || kultura.naziv === '') { dispatch(setMessage({ msgText: 'Nisu popunjena sva obavezna polja.', icon: 'warning', showHide: true })); return };
    if (addEditData === 'edit') {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `update kultura set id = '${kultura.id}', naziv = '${kultura.naziv}', podvrsta_repromaterijala_id = ${kultura.podvrstaRepromaterijala}, vrsta_kulture_id = ${kultura.vrstaKulture} where id = ${oldId}` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchKultura();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    else {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `insert into kultura values(${kultura.id}, '${kultura.naziv}', ${kultura.podvrstaRepromaterijala}, ${kultura.vrstaKulture})` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchKultura();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
  }

  const handleCancelSave = () => {
    fetchKultura();
    showForm(false)
  }

  return (
    <div className='kultura-if'>
      <div className='input-form'>
        <div className="title-bar">Kultura</div>
        <div className="input-field">
          <label htmlFor="">ID</label>
          <input type="number" name="" ref={idRef} value={kultura.id} onChange={(e) => setKultura({ ...kultura, id: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Naziv</label>
          <input type="text" name="" ref={nazivRef} value={kultura.naziv} onChange={(e) => setKultura({ ...kultura, naziv: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Podvrsta repromaterijala koja se koristi</label>
          <select name="podvrstaRepromaterijala" value={kultura.podvrstaRepromaterijala} onChange={(e) => setKultura({ ...kultura, podvrstaRepromaterijala: e.target.value })} >
            <option key={0} value={0}>-----</option>
            {podvrstaRepromaterijala.map((vr) => {
              return (
                <option key={vr.id} value={vr.id}>{vr.naziv}</option>
              )
            })}
          </select>
        </div>
        <div className="input-field">
          <label htmlFor="">Vrsta kulture</label>
          <select name="vrstaKulture" value={kultura.vrstaKulture} onChange={(e) => setKultura({ ...kultura, vrstaKulture: e.target.value })} >
            {/* <option key={0} value={0}>-----</option> */}
            {vrstaKulture.map((vk) => {
              return (
                <option key={vk.id} value={vk.id}>{vk.naziv}</option>
              )
            })}
          </select>
        </div>
        <footer>
          <button onClick={handleSave}>Snimi</button>
          <button onClick={handleCancelSave}>Odustani</button>
        </footer>
      </div>
    </div>
  )
}

export default KulturaIF;