import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { getData, execProcedure } from '../../services/functions/server_functions/functions';
import './OperacijaRepromaterijal.css';

const OperacijaRepromaterijal = () => {
  const [operacija, setOperacija] = useState([]);
  const [resultDataOperacijaRepromaterijal, setResultDataOperacijaRepromaterijal] = useState([]);
  const [podvrstaRepromaterijala, setPodvrstaRepromaterijala] = useState([]);
  const [operacijaPodvrstaRepromaterijala, setOperacijaPodvrstaRepromaterijala] = useState({ operacija: null, podvrstaRepromaterijala: null });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectAllRef = useRef();

  useEffect(() => {
    const fetchOperacija = async () => {
      try {
        dispatch(setWorking(true));
        const operacijeData = await getData({ tableName: 'operacija', whereCondition: 'koristi_repromaterijal = 1' });
        setOperacija(operacijeData.data);
        const podvrstaRepromaterijalaData = await getData({ tableName: 'podvrsta_repromaterijala', whereCondition: '1 = 1' });
        setPodvrstaRepromaterijala(podvrstaRepromaterijalaData.data);
        setOperacijaPodvrstaRepromaterijala({ operacija: operacijeData.data ? operacijeData.data[0].id : 0, podvrstaRepromaterijala: podvrstaRepromaterijalaData.data ? podvrstaRepromaterijalaData.data[0].id : 0 });
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      } finally {
        dispatch(setWorking(false));
      }
    }
    fetchOperacija();
  }, []);

  useEffect(() => {
    handleOperacijaRepromaterijalChange();
  }, [operacijaPodvrstaRepromaterijala]);

  const handleOperacijaRepromaterijalChange = async () => {
    try {
      dispatch(setWorking(true));
      const masineData = await execProcedure('allGet', 'getOperacijaRepromaterijal', {
        in_operacija_id: operacijaPodvrstaRepromaterijala.operacija,
        in_podvrsta_repromaterijala_id: operacijaPodvrstaRepromaterijala.podvrstaRepromaterijala
      });
      setResultDataOperacijaRepromaterijal(masineData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleSelectAll = (checked) => {
    const selectAll = resultDataOperacijaRepromaterijal.map((operacijaRepromaterijal) => {
      return (
        {
          ...operacijaRepromaterijal, checked: checked
        }
      )
    });
    setResultDataOperacijaRepromaterijal(selectAll);
  }

  const handleCheckedChange = (e) => {
    const editDataOperacijaRepromaterijal = resultDataOperacijaRepromaterijal.map((operacijaRepromaterijal) => {
      if (operacijaRepromaterijal.id === parseInt(e.target.name))
        return { ...operacijaRepromaterijal, checked: !operacijaRepromaterijal.checked }
      else
        return operacijaRepromaterijal;
    })
    setResultDataOperacijaRepromaterijal(editDataOperacijaRepromaterijal);
  }

  const handleSaveChanges = async () => {
    try {
      let parametar = '';
      resultDataOperacijaRepromaterijal.forEach((operacijaRepromaterijal) => {
        if (operacijaRepromaterijal.checked) { parametar += operacijaRepromaterijal.id; parametar += ','; }
      })

      await execProcedure('allUpdate', 'updateOperacijaRepromaterijal', {
        in_operacija_id: operacijaPodvrstaRepromaterijala.operacija,
        in_podvrsta_repromaterijala_id: operacijaPodvrstaRepromaterijala.podvrstaRepromaterijala,
        array_of_id: parametar.slice(0, -1)
      });
      dispatch(setMessage({ msgText: 'Podaci operacija / repromaterijal su snimljeni.', icon: 'success', showHide: true }));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className="operacija-repromaterijal">
      <div className='table-form'>
        <div className="table-form-header">Operacija / Repromaterijal<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="operacija-repromaterijal-wrapper">
          <div className="operacija-repromaterijal-left">
            <div className="table-form">
              <div className="table-form-main">
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv operacije</th>
                      </tr>
                    </thead>
                    <tbody>
                      {operacija.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="radio" name='operacija' checked={data.id === operacijaPodvrstaRepromaterijala.operacija} onChange={() => {
                              selectAllRef.current.checked = false;
                              setOperacijaPodvrstaRepromaterijala({ ...operacijaPodvrstaRepromaterijala, operacija: data.id });
                            }} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {operacija.length}</div>
              </div>
            </div>
          </div>
          <div className="operacija-repromaterijal-right">
            <div className="table-form">
              <div className="table-form-main">
                <div className="input-field">
                  <label htmlFor="">Podvrsta repromaterijala</label>
                  <select name="podvrstaRepromaterijala" onChange={(e) => {
                    selectAllRef.current.checked = false;
                    setOperacijaPodvrstaRepromaterijala({ ...operacijaPodvrstaRepromaterijala, podvrstaRepromaterijala: parseInt(e.target.value) });
                  }}>
                    {podvrstaRepromaterijala.map((vr) => {
                      return (
                        <option key={vr.id} value={vr.id}>{vr.naziv}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th><input type='checkbox' ref={selectAllRef} onChange={(e) => handleSelectAll(e.target.checked)} /></th>
                        <th>ID</th>
                        <th>Naziv repromaterijala</th>
                        <th>JM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {operacijaPodvrstaRepromaterijala.operacija && resultDataOperacijaRepromaterijal.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="checkbox" name={data.id} checked={data.checked} onChange={handleCheckedChange} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                            <td>{data.jm}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {resultDataOperacijaRepromaterijal.length}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleSaveChanges}>Snimi</button>
        </div>
      </div>
    </div>
  )
}

export default OperacijaRepromaterijal;