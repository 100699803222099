import { Outlet } from 'react-router-dom';
import Header from '../../components/Header';
import VereticalMenu from '../../components/VereticalMenu';
import './MainAppPage.css';


const MainAppPage = () => {
  return (
    <div className='main-app-page' style={{ maxWidth: window.innerWidth, maxHeight: window.innerHeight }}>
      <Header />
      <div className="main-app-page-container" style={{ maxWidth: window.innerWidth, maxHeight: (window.innerHeight - 55) + 'px' }}>
        <VereticalMenu />
        <Outlet />
      </div>
    </div>
  )
}

export default MainAppPage;