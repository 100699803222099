import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const pravoSlice = createSlice({
  name: 'pravoSliceReducer',
  initialState: initState,
  reducers: {
    addPravo: (state, action) => {
      state = action.payload;
      return state;
    }
  }
});

export const { addPravo } = pravoSlice.actions;

export default pravoSlice.reducer;