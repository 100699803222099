import { useState } from 'react';
// import InputForm from '../components/InputForm';
// import TableForm1 from '../components/TableForm1';
import './VrstaMasine.css';

const VrstaMasine = () => {
  const [data, setData] = useState({ tableName: 'vrsta_masine', naziv: 'Mirko' });
  // const [showTableForm, setShowTableForm] = useState(true);

  // const handleInputData = (e) => {
  //   setData({ ...data, [e.target.name]: e.target.value })
  // }

  // const handleShowForm = () => {
  //   setShowTableForm(!showTableForm);
  // }

  // if (showTableForm)
  return (
    <div className='vrsta-masine'>
      {/* <TableForm caption='Pregled vrsta mašina' tableHeader={['ID', 'Vrsta mašine']} handleShowForm={handleShowForm}></TableForm> */}
      {/* <TableForm1 caption='Pregled vrsta mašina' tableHeader={['ID', 'Vrsta mašine']}></TableForm1> */}
    </div>
  )

  // return (
  //   <InputForm InputForm formWidth={'40rem'} formHeight={'50rem'} caption={'Vrsta mašine'} data={data} handleShowForm={handleShowForm} >
  //     <div className="input-field">
  //       <label htmlFor="">Naziv vrste mašine</label>
  //       <input
  //         type="text"
  //         name="naziv"
  //         autoComplete='off'
  //         value={data.naziv}
  //         onChange={handleInputData}
  //       />
  //     </div>
  //   </InputForm>
  // )
}

export default VrstaMasine;