import { createSlice } from "@reduxjs/toolkit";

const initState = { msgText: 'Pocetna poruka', icon: 'info', showHide: false };

const showMessageWindowSlice = createSlice({
  name: 'showMessageWindowSliceReducer',
  initialState: initState,
  reducers: {

    setMessage: (state, action) => {
      state = action.payload;
      return state;
    },
    toggleShowHide: (state, action) => {
      state.showHide = action.payload;
      return state;
    },
  }
});

export const { setMessage, toggleShowHide } = showMessageWindowSlice.actions;

export default showMessageWindowSlice.reducer;