import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authUser: {
    email: '',
    prezime_i_ime: '',
    pravo_id: 0,
    oj_id: 0,
    naziv_oj: ''
  },
  innerWidth: 1366,
  innerHeight: 728
}

const userSlice = createSlice({
  name: 'userSliceReducer',
  initialState: initialState,
  reducers: {
    setInitValue: (state, action) => {
      state = initialState;
      return state;
    },
    login(state, action) {
      state = action.payload;
      return state;
    },
    setUserOj: (state, action) => {
      state = { ...state, authUser: { ...state.authUser, oj_id: action.payload.oj_id, naziv_oj: action.payload.naziv_oj } };
      return state;
    }
  }
})

export const { setInitValue, login, setUserOj } = userSlice.actions;

export default userSlice.reducer;