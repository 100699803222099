import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/inputForm.css';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { selectStatementData } from '../../services/functions/server_functions/functions';
import './PrikljucnaMasinaIf.css';

const PrikljucnaMasinaIF = ({ showForm, fetchPrikljucnaMasina, editData, addEditData }) => {
  const [prikljucnaMasina, setPrikljucnaMasina] = useState({ id: editData.id, naziv: editData.naziv, oj: editData.oj_id });
  const [oj, setOj] = useState([]);
  const [oldId] = useState(editData.id);
  const idRef = useRef();
  const nazivRef = useRef();
  const dispatch = useDispatch();
  // const oj = useSelector((state) => state.oj);

  useEffect(() => {
    if (addEditData === 'add')
      idRef.current.focus();
    else
      nazivRef.current.focus();

    const fetchData = async () => {
      try {
        const ojData = await selectStatementData(`SELECT oj.id, oj.naziv from organizaciona_jedinica oj`);
        setOj(ojData.data);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    fetchData();
  }, [])

  const handleSave = async () => {
    if (prikljucnaMasina.id === '' || prikljucnaMasina.naziv === '' || prikljucnaMasina.oj === 0) { dispatch(setMessage({ msgText: 'Nisu popunjena sva obavezna polja.', icon: 'warning', showHide: true })); return };
    if (editData.id !== '') {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `update prikljucna_masina set id = '${prikljucnaMasina.id}', naziv = '${prikljucnaMasina.naziv}', oj_id = ${prikljucnaMasina.oj} where id = ${oldId}` })

          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchPrikljucnaMasina();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
    else {
      try {
        const result = await fetch('https://rnalogapi.cs-dev.rs/commandsOnTextBase',
          {
            method: 'POST',
            headers: { 'Content-type': 'application/json', },
            body: JSON.stringify({ sentence: `insert into prikljucna_masina values(${prikljucnaMasina.id}, '${prikljucnaMasina.naziv}', ${prikljucnaMasina.oj})` })
          })
        const res = await result.json();
        if (res.errno != 0) { dispatch(setMessage({ msgText: `Greška ${res.errno}: ${res.message}`, icon: 'warning', showHide: true })); return; }
        dispatch(setMessage({ msgText: `${res.message}`, icon: 'success', showHide: true }));
        fetchPrikljucnaMasina();
        showForm(false);
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      }
    }
  }

  const handleCancelSave = () => {
    fetchPrikljucnaMasina();
    showForm(false)
  }

  return (
    <div className='prikljucna-masina-if'>
      <div className='input-form'>
        <div className="title-bar">Priključna mašina</div>
        <div className="input-field">
          <label htmlFor="">ID</label>
          <input type="number" name="" ref={idRef} value={prikljucnaMasina.id} onChange={(e) => setPrikljucnaMasina({ ...prikljucnaMasina, id: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Naziv priključne mašine</label>
          <input type="text" name="" ref={nazivRef} value={prikljucnaMasina.naziv} onChange={(e) => setPrikljucnaMasina({ ...prikljucnaMasina, naziv: e.target.value })} />
        </div>
        <div className="input-field">
          <label htmlFor="">Organizaciona jedinica</label>
          <select name="" value={prikljucnaMasina.oj} onChange={(e) => setPrikljucnaMasina({ ...prikljucnaMasina, oj: e.target.value })}>
            <option value="0">-----</option>
            {oj.map((oj) => {
              return (
                <option key={oj.id} value={oj.id}>{oj.naziv}</option>
              )
            })}
          </select>
        </div>
        <footer>
          <button onClick={handleSave}>Snimi</button>
          <button onClick={handleCancelSave}>Odustani</button>
        </footer>
      </div>
    </div>
  )
}

export default PrikljucnaMasinaIF;