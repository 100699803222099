import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const repromaterijalSlice = createSlice({
  name: 'repromaterijalSliceReducer',
  initialState: initState,
  reducers: {
    addRepromaterijal: (state, action) => {
      state = action.payload;
      return state;
    },
  }
});

export const { addRepromaterijal } = repromaterijalSlice.actions;

export default repromaterijalSlice.reducer;