import { useEffect, useState } from "react";

const useHandleOnChangeCheckBox = () => {
  const [selectedRow, setSelectedRow] = useState([]);

  const handleOnChangeCheckBox = (e) => {
    if (selectedRow.length === 0) {
      e.target.checked = true;
      setSelectedRow([e.target.name]);
    }
    else {
      if (e.target.name === selectedRow[0]) {
        e.target.checked = false;
        setSelectedRow([]);
      }
      else {
        e.target.checked = false;
      }
      // return
    };
  }

  return [selectedRow, setSelectedRow, handleOnChangeCheckBox];
}

export default useHandleOnChangeCheckBox;