import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../redux/reducers/userSlice';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setRadniNalogFilter } from '../../redux/reducers/filterRadniNalogCriteriaSlice';
import logo from '../../images/logo1.jpg';
import './Login.css';
import { setAddValues, updateValues } from '../../redux/reducers/addEditRadniNalog';

const LogIn = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAddValues());
  }, [])

  const handleUserLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch('https://rnalogapi.cs-dev.rs/getUserByEmail',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({ email: emailRef.current.value, password: passwordRef.current.value })
        });
      const data = await res.json();
      if (!data.authUser) {
        dispatch(setMessage({ msgText: 'Email i/ili password nisu isparavni.', icon: 'warning', showHide: true }));
      }
      else {
        data.innerWidth = window.innerWidth;
        data.innerHeight = window.innerHeight;
        dispatch(login(data));
        dispatch(updateValues({ name: 'oj', value: data.authUser.oj_id }));
        dispatch(setRadniNalogFilter({ name: 'oj', value: data.authUser.oj_id }));
        navigate('/main');
      }
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className='login-page'>
      <div className="login-page-left"></div>
      <div className="login-page-right">
        <div className="login-page-right-top">
          <img className='logo' src={logo} alt="" />
          <p>
            <span>Dobrodošli nazad,</span>
          </p>
          <p>
            <span>Molim vas prijavite se na vaš nalog.</span>
          </p>
          {/* <p>Nemate nalog? <NavLink className='navlink' to={'/register'}>Kreirajte nalog</NavLink></p> */}
        </div>
        <div className="login-page-right-bottom">
          <div className='input-field'>
            <label htmlFor='email'>Email</label>
            <input
              type="email"
              name='email'
              // autoComplete='off'
              ref={emailRef}
            />
          </div>
          <div className='input-field'>
            <label htmlFor='password'>Password</label>
            <input
              type="password"
              name='password'
              autoComplete='off'
              ref={passwordRef}
            />
          </div>
        </div>
        <div className="login-group">
          {/* <p><NavLink className='navlink' to={'/forgotpassword'}>Zaboravili ste password?</NavLink></p> */}
          {/* <button onClick={() => { loggedIn(imeRef.current.value) }}>Prijavite se</button> */}
          <button className='login-button' onClick={handleUserLogin}>Prijavite se</button>
        </div>
      </div>
    </div>
  )
}

export default LogIn;