import { createSlice } from "@reduxjs/toolkit";

const initState = {
  id: '',
  oj: 0,
  operacija: 0,
  parcela: 0,
  masina: 0,
  prikljucnaMasina: 0,
  repromaterijal: 0,
  radnik: 0,
  uradjeno: 0,
  preostalo: 0,
  natoceno: 0,
  prosek: 0,
  natocenoHemija: 0,
  prosekHemija: 0,
  komentar: '',
  status: 0,
  nema_obradivu_povrsinu: 0,
  kultura: 0,
  nazivMasine: '',
  repromaterijal_stavke: [],
  gotova_roba_merkantilna: 0,
  gotova_roba_semenska: 0
}

const addEditRadniNalog = createSlice({
  name: 'addEditRadniNalogReducer',
  initialState: initState,
  reducers: {
    setAddValues: (state, action) => {
      state = initState;
      return state;
    },
    setEditValues: (state, action) => {
      const newState = {
        id: action.payload.id,
        oj: action.payload.oj_id,
        operacija: action.payload.operacija_id,
        parcela: action.payload.parcela_id,
        ukupnaPovrsina: action.payload.povrsina,
        nazivKulture: action.payload.naziv_kulture,
        masina: action.payload.masina_id,
        prikljucnaMasina: action.payload.prikljucna_masina_id,
        repromaterijal: action.payload.repromaterijal_id,
        radnik: action.payload.radnik_id,
        uradjeno: action.payload.uradjeno,
        preostalo: action.payload.preostalo,
        natoceno: action.payload.natoceno,
        prosek: action.payload.prosek,
        natocenoHemija: action.payload.natoceno_hemija,
        prosekHemija: action.payload.prosek_hemija,
        komentar: action.payload.komentar,
        status: action.payload.status,
        nema_obradivu_povrsinu: action.payload.nema_obradivu_povrsinu,
        kultura: action.payload.kultura,
        nazivMasine: action.payload.nazivMasine,
        repromaterijal_stavke: action.payload.repromaterijal_stavke,
        gotova_roba_merkantilna: action.payload.gotova_roba_merkantilna,
        gotova_roba_semenska: action.payload.gotova_roba_semenska,
        gotova_roba: action.payload.gotova_roba
      }
      state = newState;
      return state;
    },
    updateValues: (state, action) => {
      state = { ...state, [action.payload.name]: action.payload.value };
      return state;
    },
  }
})

export const { setAddValues, setEditValues, updateValues } = addEditRadniNalog.actions;

export default addEditRadniNalog.reducer;

