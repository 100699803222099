import { useEffect, useState } from 'react';
import { BsCheck2Square } from 'react-icons/bs';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { deleteData, execInsertProcedureWithoutUrl, execProcedureWithoutUrl, selectStatementData } from '../../services/functions/server_functions/functions';
import useHandleOnChangeCheckBox from '../../hooks/useHandleOnChangeCheckBox';
import OperacijaIF from './OperacijaIF';

import './Operacija.css';

const Operacija = () => {
  const [operacija, setOperacija] = useState([]);
  const [selectedRows, setSelectedRows, handleOnChangeCheckBox] = useHandleOnChangeCheckBox();
  const [editData, setEditData] = useState({ id: '', naziv: '' });
  const [addEditData, setAddEditData] = useState('');
  const [showInputForm, setShowInputForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    fetchOperacija();
  }, [])

  const fetchOperacija = async () => {
    try {
      dispatch(setWorking(true));
      setSelectedRows([]);
      const operacijaData = await selectStatementData(`SELECT o.id, o.naziv, o.koristi_repromaterijal, o.vrsta_repromaterijala_id, o.nema_obradivu_povrsinu, o.gotova_roba FROM operacija o`);
      setOperacija(operacijaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleAdd = () => {
    setAddEditData('add');
    setEditData({ id: '', naziv: '', koristi_repromaterijal: false, vrsta_repromaterijala_id: 1, nema_obradivu_povrsinu: false, gotova_roba: false });
    setShowInputForm(true);
  }

  const handleEdit = () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati operaciju za izmenu.', icon: 'warning', showHide: true })); return; }
    setAddEditData('edit');
    const id = parseInt(selectedRows[selectedRows.length - 1]);
    const naziv = operacija.filter((op) => (op.id == id))
    setEditData({ id: `${id}`, naziv: `${naziv[0].naziv}`, koristi_repromaterijal: naziv[0].koristi_repromaterijal, vrsta_repromaterijala_id: naziv[0].vrsta_repromaterijala_id || 1, nema_obradivu_povrsinu: naziv[0].nema_obradivu_povrsinu, gotova_roba: naziv[0].gotova_roba });
    setShowInputForm(true);
  }

  const handleDelete = async () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati operaciju za brisanje.', icon: 'warning', showHide: true })); return; }
    try {
      const returnedData = await deleteData({ tableName: 'operacija', whereCondition: `id in (${selectedRows.toString()})` });
      if (returnedData.errno === 0) {
        dispatch(setMessage({ msgText: 'Selektovana operacija je obrisana.', icon: 'success', showHide: true }));
        await execInsertProcedureWithoutUrl('saveRadniNalogLog', {
          in_izvrsilac_aktivnosti: user.authUser.email,
          in_opis_aktivnosti: `Delete (id = ${selectedRows.toString()})`
        });
      } else {
        dispatch(setMessage({ msgText: returnedData.message, icon: 'warning', showHide: true }));
        return;
      }
      await fetchOperacija();
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  if (showInputForm) return <OperacijaIF showForm={setShowInputForm} fetchOperacija={fetchOperacija} editData={editData} addEditData={addEditData} />;

  return (
    <div className='operacija'>
      <div className='table-form'>
        <div className="table-form-header">Pregled operacija<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="table-form-main">
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Operacija</th>
                  <th>Koristi repromaterijal</th>
                  <th>Nema obradivu površinu</th>
                  <th>Gotova roba</th>
                </tr>
              </thead>
              <tbody>
                {operacija && operacija.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td><input type="checkbox" name={data.id} onChange={handleOnChangeCheckBox} /></td>
                      <td>{data.id}</td>
                      <td>{data.naziv}</td>
                      <td style={{ textAlign: 'center' }}>{data.koristi_repromaterijal === 1 ? <BsCheck2Square /> : null}</td>
                      <td style={{ textAlign: 'center' }}>{data.nema_obradivu_povrsinu === 1 ? <BsCheck2Square /> : null}</td>
                      <td style={{ textAlign: 'center' }}>{data.gotova_roba === 1 ? <BsCheck2Square /> : null}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          </div>
          <div className='number-of-rows'>Broj redova: {operacija.length}</div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleAdd}>Dodaj</button>
          <button onClick={handleEdit}>Izmeni</button>
          <button onClick={handleDelete}>Obriši</button>
        </div>
      </div >
    </div >
  )
}

export default Operacija;