import { SlClose } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import './UnosRepromaterijalaPreview.css';

const UnosRepromaterijalaPreview = ({ setUnosRepromaterijalaPreviewShow, uradjeno }) => {
  const repromaterijal = useSelector((state) => state.addEditRadniNalog);

  return (
    <div className='unos-repromaterijala-container'>
      <div className="unos-repromaterijala-header">
        <p>Repromaterijal</p>
        <SlClose className='repromaterijal-close-button'
          onClick={() => setUnosRepromaterijalaPreviewShow(false)}
        />
      </div>
      <div className="input-field">
        <label htmlFor="">Urađeno ha</label>
        <div className='uradjeno'>{uradjeno}</div>
      </div>
      <div className="unos-repromaterijala-input">
        {repromaterijal.repromaterijal_stavke?.map((r, index) => {
          return (
            <div className="unos-repromaterijala-wrapper"><span>{index + 1}</span>
              <div className="naziv-repromaterijala-preview">
                {r.naziv}
              </div>
              <input type="number" disabled min={0} step={0.001} value={r?.kolicina} />
              <input type="number" disabled value={(r?.kolicina / uradjeno).toFixed(2)} />
            </div>
          )
        })}

      </div>
    </div>
  )
}

export default UnosRepromaterijalaPreview;