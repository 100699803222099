import { useState } from 'react';
import './RepromaterijalDetails.css';

const RepromaterijalDetails = ({ repromaterijal_stavke = [] }) => {

  return (
    <table className='repromaterial-details'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Naziv</th>
          <th>JM</th>
          <th>Utrošeno r.</th>
          <th>Prosek r.</th>
        </tr>
      </thead>
      <tbody>
        {repromaterijal_stavke.map((rs) => {
          return (
            <>
              <tr>
                <td>{rs.id}</td>
                <td>{rs.naziv}</td>
                <td style={{ textAlign: 'center', backgroundColor: '#d6ccc2' }}>{rs.jm}</td>
                <td style={{ textAlign: 'right', backgroundColor: '#d6ccc2' }}>{(rs.kolicina)?.toFixed(2)}</td>
                <td style={{ textAlign: 'right', backgroundColor: '#d6ccc2' }}>{(rs.prosek)?.toFixed(2)}</td>
              </tr>
            </>
          )
        })}
      </tbody>
    </table>
  )
}

export default RepromaterijalDetails;