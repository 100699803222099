export const customStyles = {
  escapeClearsValue: true,
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#b5bbc0',
    minHeight: '2.7rem',
    maxHeight: '2.7rem',
    boxShadow: state.isFocused ? null : null,
    borderColor: state.isFocused ? '#16aaff' : '#b5bbc0',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    width: '100%',
    height: '2.7rem',
    padding: '0 6px',
    fontSize: '1.4rem'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
    fontSize: '1.4rem'
  }),

  menu: (provided, state) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
    // maxHeight: '20rem',
    // overflow: 'auto',
    marginTop: '0',
    padding: '0',
    margin: '0',
    fontSize: '1.4rem',
    // color: 'red'
  }),

  formatOptionLabel: (provided, state) => ({
    ...provided,
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: '10px',
    color: 'blue'
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '2.7rem',
    color: 'red'
  }),
};