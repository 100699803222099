import { useEffect, useState } from 'react';
import { SlClose } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { setWorking } from '../../redux/reducers/workingSlice';
import { execProcedure, selectStatementData } from '../../services/functions/server_functions/functions';
import './MasinaPrikljucnaMasina.css';

const MasinaPrikljucnaMasina = () => {
  const [masina, setMasina] = useState([]);
  const [resultDataPrikljucnaMasina, setResultDataPrikljucnaMasine] = useState([]);
  const [selectedMasina, setSelectedMasina] = useState({ masina: 0, oj: 852 });
  const [selectedPrikljucnaMasina, setSelectedPrikljucnaMasina] = useState({ prikljucnaMasina: 0, oj: 0 });
  const [ojMasina, setOjMasina] = useState([]);
  const [ojPrikljucnaMasina, setOjPrikljucnaMasina] = useState([]);
  const [initialLoadMasine, setInitialLoadMasine] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ojUser = useSelector((state) => state.user);

  useEffect(() => {
    const fetchOj = async () => {
      try {
        dispatch(setWorking(true));
        const ojData = await selectStatementData(`SELECT oj.id, oj.naziv FROM organizaciona_jedinica oj`);
        setOjMasina(ojData.data);
        setOjPrikljucnaMasina(ojData.data);
        setSelectedMasina({ ...selectedMasina, oj: ojUser.authUser.oj_id });
        setSelectedPrikljucnaMasina({ ...selectedPrikljucnaMasina, oj: ojUser.authUser.oj_id });
      } catch (error) {
        dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
      } finally {
        dispatch(setWorking(false));
      }
    }
    fetchOj();
  }, [])

  useEffect(() => {
    handleMasinaChange();
  }, [selectedMasina])

  const handleMasinaChange = async () => {
    try {
      dispatch(setWorking(true));
      const masinaData = await selectStatementData(`SELECT m.id, m.oj_id, m.naziv, m.registracija, oj.naziv as naziv_oj FROM masina m JOIN organizaciona_jedinica oj ON m.oj_id = oj.id AND m.oj_id = ${selectedMasina.oj} order by m.naziv asc`);
      setMasina(masinaData.data);
      if (initialLoadMasine) {
        setSelectedMasina({ ...selectedMasina, masina: masinaData.data ? masinaData.data[0].id : 0 });
        setInitialLoadMasine(false);
      }
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  useEffect(() => {
    handlePrikljucnaMasinaChange();
  }, [selectedPrikljucnaMasina, selectedMasina])

  const handlePrikljucnaMasinaChange = async () => {
    try {
      dispatch(setWorking(true));
      const prikljucnaMasinaData = await execProcedure('getMasinaPrikljucnaMasina', 'getMasinaPrikljucnaMasina', { prikljucnaMasina: selectedMasina.masina, oj: selectedPrikljucnaMasina.oj, koristi_prikljucnu_masinu_oj_id: ojUser.authUser.oj_id });
      setResultDataPrikljucnaMasine(prikljucnaMasinaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleCheckedChange = (e) => {
    const editDataPrikljucnaMasina = resultDataPrikljucnaMasina.map((prikljucnaMasina) => {
      if (prikljucnaMasina.id === Number.parseInt(e.target.name))
        return { ...prikljucnaMasina, checked: !prikljucnaMasina.checked }
      else
        return prikljucnaMasina;
    })
    setResultDataPrikljucnaMasine(editDataPrikljucnaMasina);
  }

  const handleSaveChanges = async () => {
    console.log(selectedPrikljucnaMasina, resultDataPrikljucnaMasina)
    try {
      let parametar = selectedMasina.masina;
      let ostatak = '';
      resultDataPrikljucnaMasina.forEach((prikljucnaMasina) => {
        if (prikljucnaMasina.checked) { ostatak += ','; ostatak += prikljucnaMasina.id; }
      })
      parametar += ostatak;

      await execProcedure('updateMasinaPrikljucnaMasina', 'updateMasinaPrikljucnaMasina', { array_of_id: parametar, masina_oj_id: selectedMasina.oj, prikljucna_masina_oj_id: selectedPrikljucnaMasina.oj, koristi_prikljucnu_masinu_oj_id: ojUser.authUser.oj_id });
      dispatch(setMessage({ msgText: 'Podaci mašina / priključna mašina su snimljeni.', icon: 'success', showHide: true }));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    }
  }

  return (
    <div className="masina-prikljucna-masina">
      <div className='table-form'>
        <div className="table-form-header">Mašina / Priključna mašina<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="masina-prikljucna-masina-wrapper">
          <div className="masina-prikljucna-masina-left">
            <div className="table-form">
              <div className="table-form-main">
                <div className="input-field">
                  <label htmlFor="">Organizaciona jedinica</label>
                  <select value={selectedMasina.oj} onChange={(e) => { setSelectedMasina({ ...selectedMasina, oj: e.target.value }); setInitialLoadMasine(true) }}>
                    <option value="0">-----</option>
                    {ojMasina && ojMasina.map((o) => {
                      return (
                        <option key={o.id} value={o.id}>{o.naziv}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv</th>
                        <th>Registracija</th>
                        <th>OJ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {masina.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="radio" name='masina' checked={data.id === selectedMasina.masina} onChange={() => { setSelectedMasina({ ...selectedMasina, masina: data.id }); }} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                            <td>{data.registracija}</td>
                            <td>{data.naziv_oj}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {masina.length}</div>
              </div>
            </div>
          </div>
          <div className="masina-prikljucna-masina-right">
            <div className="table-form">
              <div className="table-form-main">
                <div className="input-field">
                  <label htmlFor="">Organizaciona jedinica</label>
                  <select value={selectedPrikljucnaMasina.oj} onChange={(e) => { setSelectedPrikljucnaMasina({ ...selectedPrikljucnaMasina, oj: e.target.value }); }}>
                    <option value="0">-----</option>
                    {ojPrikljucnaMasina && ojPrikljucnaMasina.map((o) => {
                      return (
                        <option key={o.id} value={o.id}>{o.naziv}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Naziv</th>
                        <th>OJ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultDataPrikljucnaMasina.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><input type="checkbox" name={data.id} checked={data.checked} onChange={handleCheckedChange} /></td>
                            <td>{data.id}</td>
                            <td>{data.naziv}</td>
                            <td>{data.naziv_oj}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='number-of-rows'>Broj redova: {resultDataPrikljucnaMasina.length}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleSaveChanges}>Snimi</button>
        </div>
      </div>
    </div>
  )
}

export default MasinaPrikljucnaMasina;