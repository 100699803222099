export const meseci = [
  { id: 1, naziv: 'Januar' },
  { id: 2, naziv: 'Februar' },
  { id: 3, naziv: 'Mart' },
  { id: 4, naziv: 'April' },
  { id: 5, naziv: 'Maj' },
  { id: 6, naziv: 'Juni' },
  { id: 7, naziv: 'Juli' },
  { id: 8, naziv: 'Avgust' },
  { id: 9, naziv: 'Septembar' },
  { id: 10, naziv: 'Oktobar' },
  { id: 11, naziv: 'Novembar' },
  { id: 12, naziv: 'Decembar' },
];