import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const operacijaMasinaSlice = createSlice({
  name: 'operacijaMasinaSliceReducer',
  initialState: initState,
  reducers: {
    addOperacijaMasina: (state, action) => {
      state = action.payload;
      return state;
    },
  }
});

export const { addOperacijaMasina } = operacijaMasinaSlice.actions;

export default operacijaMasinaSlice.reducer;