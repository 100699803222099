import { createSlice } from "@reduxjs/toolkit";

const initState = [];

const radniNalogRepromaterijalSlice = createSlice({
  name: 'radniNalogRepromaterijalSliceReducer',
  initialState: initState,
  reducers: {
    addInitialValues: (state, action) => {
      state = action.payload;
      return state;
    },
    getRepromaterijal: (state, action) => {
      return state;
    },
  }

})

export const { addInitialValues, getRepromaterijal } = radniNalogRepromaterijalSlice.actions;

export default radniNalogRepromaterijalSlice.reducer;