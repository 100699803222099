import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SlClose } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import useHandleOnChangeCheckBox from '../../hooks/useHandleOnChangeCheckBox';
import { setMessage } from '../../redux/reducers/showMessageWindowSlice';
import { deleteData, getData, selectStatementData } from '../../services/functions/server_functions/functions';
import KulturaIF from './KulturaIF';
import './Kultura.css';
import { setWorking } from '../../redux/reducers/workingSlice';

const Kultura = () => {
  const [kultura, setKultura] = useState([]);
  const [selectedRows, setSelectedRows, handleOnChangeCheckBox] = useHandleOnChangeCheckBox();
  const [editData, setEditData] = useState({ id: '', naziv: '', podvrsta_repromaterijala_id: 0, vrsta_kulture: 0 });
  const [addEditData, setAddEditData] = useState('');
  const [showInputForm, setShowInputForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchKultura();
  }, [])

  const fetchKultura = async () => {
    try {
      dispatch(setWorking(true));
      setSelectedRows([]);
      const kulturaData = await selectStatementData(`SELECT 	k.id, k.naziv, k.podvrsta_repromaterijala_id, k.vrsta_kulture_id, vk.naziv AS vrsta_kulture_naziv  FROM 	kultura k JOIN	vrsta_kulture vk ON k.vrsta_kulture_id = vk.id`);
      setKultura(kulturaData.data);
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  const handleAdd = () => {
    setAddEditData('add');
    setEditData({ id: '', naziv: '', podvrsta_repromaterijala_id: 0, vrsta_kulture: 0 });
    setShowInputForm(true);
  }

  const handleEdit = () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati kulturu za izmenu.', icon: 'warning', showHide: true })); return; }
    setAddEditData('edit');
    const id = parseInt(selectedRows[selectedRows.length - 1]);
    const naziv = kultura.filter((op) => (op.id == id))
    setEditData({ id: `${id}`, naziv: `${naziv[0].naziv}`, podvrsta_repromaterijala_id: `${naziv[0].podvrsta_repromaterijala_id}`, vrsta_kulture: `${naziv[0].vrsta_kulture}` })
    setShowInputForm(true);
  }

  const handleDelete = async () => {
    if (selectedRows.length === 0) { dispatch(setMessage({ msgText: 'Morate selektovati kulturu za brisanje.', icon: 'warning', showHide: true })); return; }
    try {
      dispatch(setWorking(true));
      const returnedData = await deleteData({ tableName: 'kultura', whereCondition: `id in (${selectedRows.toString()})` })
      dispatch(setMessage({ msgText: 'Selektovana kultura je obrisana.', icon: 'success', showHide: true }));
      fetchKultura();
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška', icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }

  if (showInputForm) return <KulturaIF showForm={setShowInputForm} fetchKultura={fetchKultura} editData={editData} addEditData={addEditData} />;

  return (
    <div className='kultura'>
      <div className='table-form'>
        <div className="table-form-header">Pregled kultura<SlClose className='close-button' onClick={() => navigate('/main/landingpage')} /></div>
        <div className="table-form-main">
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Naziv</th>
                  <th>Vrsta</th>
                </tr>
              </thead>
              <tbody>
                {kultura.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td><input type="checkbox" name={data.id} onChange={handleOnChangeCheckBox} /></td>
                      <td>{data.id}</td>
                      <td>{data.naziv}</td>
                      <td>{data.vrsta_kulture_naziv}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
          </div>
          <div className='number-of-rows'>Broj redova: {kultura.length}</div>
        </div>
        <div className="table-form-footer">
          <button onClick={handleAdd}>Dodaj</button>
          <button onClick={handleEdit}>Izmeni</button>
          <button onClick={handleDelete}>Obriši</button>
        </div>
      </div >
    </div >
  )
}

export default Kultura;