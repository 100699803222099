import { execProcedure, getData, selectStatementData } from '../services/functions/server_functions/functions';
import { useDispatch, useSelector } from "react-redux";

import { addOperacija } from "../redux/reducers/operacijaSlice";
import { addParcela } from '../redux/reducers/parcelaSlice';
import { addMasina } from '../redux/reducers/masinaSlice';
import { addPrikljucnaMasina } from '../redux/reducers/prikljucnaMasinaSlice';
import { addRadnik } from '../redux/reducers/radnikSlice';
import { addRepromaterijal } from '../redux/reducers/repromaterijalSlice';
import { addRadniNalog } from '../redux/reducers/radniNalogSlice';
import { addOperacijaMasina } from '../redux/reducers/operacijaMasinaSlice';
import { addOj } from '../redux/reducers/ojSlice';
import { addPravo } from '../redux/reducers/pravoSlice';
import { setWorking } from '../redux/reducers/workingSlice';
import { setRadniNalogFilterInit } from '../redux/reducers/filterRadniNalogCriteriaSlice';
import { addKultura } from '../redux/reducers/kulturaSlice';
import { addVrstaRepromaterijala, vrstaRepromaterijala } from '../redux/reducers/vrstaRepromaterijala';
import { addPodvrstaRepromaterijala, podvrstaRepromaterijala } from '../redux/reducers/podvrstaRepromaterijala';
import { setMessage } from '../redux/reducers/showMessageWindowSlice';
import { useEffect } from 'react';

export const useInitialLoad = () => {
  const dispatch = useDispatch();
  const filterRadniNalogCriteria = useSelector((state) => state.filterRadniNalogCriteria);
  const oj = useSelector((state) => state.user);

  useEffect(() => {
    // initialLoadFromHook()
  }, [])


  const initialLoadFromHook = async () => {
    try {
      dispatch(addRadniNalog([{}]));
      dispatch(setWorking(true));
      const radniNalogData = await execProcedure('filterRadniNalog', 'filterRadniNalog', filterRadniNalogCriteria);
      // const operacijaData = await getData({ tableName: 'operacija', whereCondition: '1 = 1' });
      const operacijaData = await selectStatementData(`SELECT * FROM operacija`);
      // const parcelaData = await selectStatementData(`SELECT p.id, p.oj_id, p.naziv, p.povrsina, p.kultura_id, k.naziv as naziv_kulture FROM parcela p JOIN kultura k ON p.kultura_id = k.id and p.oj_id = ${oj.authUser.oj_id}`);
      const parcelaData = await selectStatementData(`SELECT p.id, p.oj_id, p.naziv, p.povrsina, p.kultura_id, k.naziv as naziv_kulture FROM parcela p LEFT JOIN kultura k ON p.kultura_id = k.id WHERE p.ne_prikazivati_u_rn = 0 AND p.oj_id = ${oj.authUser.oj_id}`);
      // const masinaData = await selectStatementData(`SELECT m.id, m.oj_id, m.naziv, m.registracija, oj.naziv as naziv_oj FROM masina m JOIN organizaciona_jedinica oj ON m.oj_id = oj.id`);
      const masinaData = await selectStatementData(`SELECT 	m.id, m.naziv, m.registracija, m.oj_id FROM	masina m JOIN	operacija_masina om ON m.id = om.masina_id WHERE	om.koristi_masinu_oj_id = ${oj.authUser.oj_id} GROUP BY m.id, m.naziv, m.registracija;`);
      // const prikljucnaMasinaData = await getData({ tableName: 'prikljucna_masina', whereCondition: '1 = 1' });
      const prikljucnaMasinaData = await selectStatementData(`SELECT * FROM prikljucna_masina`);
      const radnikData = await selectStatementData(`SELECT r.id, r.oj_id, r.prezime, r.ime, concat(r.prezime, ' ', r.ime) as prezime_ime, oj.naziv as naziv_oj FROM radnik r JOIN organizaciona_jedinica oj ON r.oj_id = oj.id WHERE r.oj_id = ${oj.authUser.oj_id}`);
      const kulturaData = await selectStatementData(`SELECT k.id, k.naziv FROM kultura k`);
      const vrstaRepromaterijalaData = await selectStatementData(`SELECT vr.id, vr.naziv FROM vrsta_repromaterijala vr`);
      const podvrstaRepromaterijalaData = await selectStatementData(`SELECT pv.id, pv.naziv FROM podvrsta_repromaterijala pv`);
      // const repromaterijalData = await getData({ tableName: 'repromaterijal', whereCondition: '1 = 1' });
      const repromaterijalData = await selectStatementData(`SELECT * FROM repromaterijal`);
      // const pravoData = await getData({ tableName: 'pravo', whereCondition: '1 = 1' });
      const pravoData = await selectStatementData(`SELECT * FROM pravo`);
      // const ojData = await getData({ tableName: 'organizaciona_jedinica', whereCondition: '1 = 1' });
      const ojData = await selectStatementData(`SELECT * FROM organizaciona_jedinica`);

      dispatch(addRadniNalog(radniNalogData.data));
      dispatch(addOperacija(operacijaData.data));
      dispatch(addParcela(parcelaData.data));
      dispatch(addMasina(masinaData.data));
      dispatch(addPrikljucnaMasina(prikljucnaMasinaData.data));
      dispatch(addRadnik(radnikData.data));
      dispatch(addKultura(kulturaData.data));
      dispatch(addVrstaRepromaterijala(vrstaRepromaterijalaData.data));
      dispatch(addPodvrstaRepromaterijala(podvrstaRepromaterijalaData.data));
      dispatch(addRepromaterijal(repromaterijalData.data));
      dispatch(addOj(ojData.data));
      dispatch(addPravo(pravoData.data));
      dispatch(setRadniNalogFilterInit({ name: 'oj', value: oj.authUser.oj_id }));
    } catch (error) {
      dispatch(setMessage({ msgText: 'Desila se sistemska greška. ' + error.message, icon: 'warning', showHide: true }));
    } finally {
      dispatch(setWorking(false));
    }
  }
  return [initialLoadFromHook];
}